/**
 * Carrot Search FoamTree HTML5 (commercial variant)
 * v3.4.6, bugfix/3.4.x/b3d3e9ff, build FOAMTREE-SOFTWARE4-DIST-45, Dec 28, 2018
 *
 * Carrot Search confidential.
 * Copyright 2002-2018, Carrot Search s.c, All Rights Reserved.
 */
(function () {
    var v = (function () {
        var e = window.navigator.userAgent,
            m;
        try {
            window.localStorage.setItem('ftap5caavc', 'ftap5caavc'),
                window.localStorage.removeItem('ftap5caavc'),
                (m = !0);
        } catch (g) {
            m = !1;
        }
        return {
            qf: function () {
                return /webkit/i.test(e);
            },
            ni: function () {
                return /Mac/.test(e);
            },
            of: function () {
                return /iPad|iPod|iPhone/.test(e);
            },
            lf: function () {
                return /Android/.test(e);
            },
            ji: function () {
                return (
                    'ontouchstart' in window ||
                    (!!window.DocumentTouch &&
                        document instanceof window.DocumentTouch)
                );
            },
            ii: function () {
                return m;
            },
            hi: function () {
                var d = document.createElement('canvas');
                return !(!d.getContext || !d.getContext('2d'));
            },
            Gd: function (d, b) {
                return [].forEach && v.hi() ? d && d() : b && b();
            },
        };
    })();
    var aa = (function () {
        function e() {
            return (
                (window.performance &&
                    (window.performance.now ||
                        window.performance.mozNow ||
                        window.performance.msNow ||
                        window.performance.oNow ||
                        window.performance.webkitNow)) ||
                Date.now
            );
        }
        var m = e();
        return {
            create: function () {
                return {
                    now: (function () {
                        var g = e();
                        return function () {
                            return g.call(window.performance);
                        };
                    })(),
                };
            },
            now: function () {
                return m.call(window.performance);
            },
        };
    })();
    function da() {
        function e() {
            if (!f) throw 'AF0';
            var d = aa.now();
            0 !== k && (g.Nd = d - k);
            k = d;
            b = b.filter(function (a) {
                return null !== a;
            });
            g.frames++;
            for (var c = 0; c < b.length; c++) {
                var a = b[c];
                null !== a &&
                    (!0 === a.Be.call(a.Zg)
                        ? (b[c] = null)
                        : D.Uc(a.repeat) &&
                          ((a.repeat = a.repeat - 1),
                          0 >= a.repeat && (b[c] = null)));
            }
            b = b.filter(function (a) {
                return null !== a;
            });
            f = !1;
            m();
            d = aa.now() - d;
            0 !== d && (g.Md = d);
            g.totalTime += d;
            g.Re = (1e3 * g.frames) / g.totalTime;
            k = 0 === b.length ? 0 : aa.now();
        }
        function m() {
            0 < b.length && !f && ((f = !0), d(e));
        }
        var g = (this.sg = { frames: 0, totalTime: 0, Md: 0, Nd: 0, Re: 0 });
        fa = g;
        var d = (function () {
                return v.of()
                    ? function (b) {
                          window.setTimeout(b, 0);
                      }
                    : window.requestAnimationFrame ||
                          window.webkitRequestAnimationFrame ||
                          window.mozRequestAnimationFrame ||
                          window.oRequestAnimationFrame ||
                          window.msRequestAnimationFrame ||
                          (function () {
                              var b = aa.create();
                              return function (c) {
                                  var a = 0;
                                  window.setTimeout(
                                      function () {
                                          var f = b.now();
                                          c();
                                          a = b.now() - f;
                                      },
                                      16 > a ? 16 - a : 0,
                                  );
                              };
                          })();
            })(),
            b = [],
            f = !1,
            k = 0;
        this.repeat = function (f, c, a) {
            this.cancel(f);
            b.push({ Be: f, Zg: a, repeat: c });
            m();
        };
        this.d = function (b, c) {
            this.repeat(b, 1, c);
        };
        this.cancel = function (f) {
            for (var c = 0; c < b.length; c++) {
                var a = b[c];
                null !== a && a.Be === f && (b[c] = null);
            }
        };
        this.k = function () {
            b = [];
        };
    }
    var fa;
    var ga = v.Gd(function () {
        function e() {
            this.buffer = [];
            this.na = 0;
            this.Ic = D.extend({}, k);
        }
        function m(b) {
            return function () {
                var c,
                    a = this.buffer,
                    f = this.na;
                a[f++] = 'call';
                a[f++] = b;
                a[f++] = arguments.length;
                for (c = 0; c < arguments.length; c++) a[f++] = arguments[c];
                this.na = f;
            };
        }
        function g(f) {
            return function () {
                return b[f].apply(b, arguments);
            };
        }
        var d = document.createElement('canvas');
        d.width = 1;
        d.height = 1;
        var b = d.getContext('2d'),
            d = ['font'],
            f =
                'fillStyle globalAlpha globalCompositeOperation lineCap lineDashOffset lineJoin lineWidth miterLimit shadowBlur shadowColor shadowOffsetX shadowOffsetY strokeStyle textAlign textBaseline'.split(
                    ' ',
                ),
            k = {};
        f.concat(d).forEach(function (f) {
            k[f] = b[f];
        });
        e.prototype.clear = function () {
            this.na = 0;
        };
        e.prototype.Na = function () {
            return 0 === this.na;
        };
        e.prototype.Ua = function (b) {
            function c(a, b, c) {
                for (var f = 0, d = a.na, g = a.buffer; f < c; )
                    g[d++] = b[f++];
                a.na = d;
            }
            function a(a, b, c, f) {
                for (var d = 0; d < c; )
                    switch (b[d++]) {
                        case 'set':
                            a[b[d++]] = b[d++];
                            break;
                        case 'setGlobalAlpha':
                            a[b[d++]] = b[d++] * f;
                            break;
                        case 'call':
                            var g = b[d++];
                            switch (b[d++]) {
                                case 0:
                                    a[g]();
                                    break;
                                case 1:
                                    a[g](b[d++]);
                                    break;
                                case 2:
                                    a[g](b[d++], b[d++]);
                                    break;
                                case 3:
                                    a[g](b[d++], b[d++], b[d++]);
                                    break;
                                case 4:
                                    a[g](b[d++], b[d++], b[d++], b[d++]);
                                    break;
                                case 5:
                                    a[g](
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                    );
                                    break;
                                case 6:
                                    a[g](
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                    );
                                    break;
                                case 7:
                                    a[g](
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                    );
                                    break;
                                case 8:
                                    a[g](
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                    );
                                    break;
                                case 9:
                                    a[g](
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                        b[d++],
                                    );
                                    break;
                                default:
                                    throw 'CB0';
                            }
                    }
            }
            b instanceof ga
                ? c(b, this.buffer, this.na)
                : a(b, this.buffer, this.na, D.B(b.globalAlpha, 1));
        };
        e.prototype.replay = e.prototype.Ua;
        e.prototype.d = function () {
            return new e();
        };
        e.prototype.scratch = e.prototype.d;
        'arc arcTo beginPath bezierCurveTo clearRect clip closePath drawImage fill fillRect fillText lineTo moveTo putImageData quadraticCurveTo rect rotate scale setLineDash setTransform stroke strokeRect strokeText transform translate'
            .split(' ')
            .forEach(function (b) {
                e.prototype[b] = m(b);
            });
        [
            'measureText',
            'createLinearGradient',
            'createRadialGradient',
            'createPattern',
            'getLineDash',
        ].forEach(function (b) {
            e.prototype[b] = g(b);
        });
        ['save', 'restore'].forEach(function (b) {
            e.prototype[b] = (function (b, a) {
                return function () {
                    b.apply(this, arguments);
                    a.apply(this, arguments);
                };
            })(m(b), g(b));
        });
        d.forEach(function (f) {
            Object.defineProperty(e.prototype, f, {
                set: function (c) {
                    b[f] = c;
                    this.Ic[f] = c;
                    var a = this.buffer;
                    a[this.na++] = 'set';
                    a[this.na++] = f;
                    a[this.na++] = c;
                },
                get: function () {
                    return this.Ic[f];
                },
            });
        });
        f.forEach(function (b) {
            Object.defineProperty(e.prototype, b, {
                set: function (c) {
                    this.Ic[b] = c;
                    var a = this.buffer;
                    a[this.na++] =
                        'globalAlpha' === b ? 'setGlobalAlpha' : 'set';
                    a[this.na++] = b;
                    a[this.na++] = c;
                },
                get: function () {
                    return this.Ic[b];
                },
            });
        });
        e.prototype.roundRect = function (b, c, a, f, d) {
            this.beginPath();
            this.moveTo(b + d, c);
            this.lineTo(b + a - d, c);
            this.quadraticCurveTo(b + a, c, b + a, c + d);
            this.lineTo(b + a, c + f - d);
            this.quadraticCurveTo(b + a, c + f, b + a - d, c + f);
            this.lineTo(b + d, c + f);
            this.quadraticCurveTo(b, c + f, b, c + f - d);
            this.lineTo(b, c + d);
            this.quadraticCurveTo(b, c, b + d, c);
            this.closePath();
        };
        e.prototype.fillPolygonWithText = function (b, c, a, f, d) {
            d || (d = {});
            var g = {
                    tb: D.B(d.maxFontSize, G.Da.tb),
                    ad: D.B(d.minFontSize, G.Da.ad),
                    lineHeight: D.B(d.lineHeight, G.Da.lineHeight),
                    qb: D.B(d.horizontalPadding, G.Da.qb),
                    fb: D.B(d.verticalPadding, G.Da.fb),
                    ub: D.B(d.maxTotalTextHeight, G.Da.ub),
                    fontFamily: D.B(d.fontFamily, G.Da.fontFamily),
                    fontStyle: D.B(d.fontStyle, G.Da.fontStyle),
                    fontVariant: D.B(d.fontVariant, G.Da.fontVariant),
                    fontWeight: D.B(d.fontWeight, G.Da.fontWeight),
                    verticalAlign: D.B(d.verticalAlign, G.Da.verticalAlign),
                },
                k = d.cache;
            if (k && D.M(d, 'area')) {
                k.ld || (k.ld = new ga());
                var e = d.area,
                    s = D.B(d.cacheInvalidationThreshold, 0.05);
                b = G.Ae(
                    g,
                    this,
                    f,
                    b,
                    M.q(b, {}),
                    { x: c, y: a },
                    d.allowForcedSplit || !1,
                    d.allowEllipsis || !1,
                    k,
                    e,
                    s,
                    d.invalidateCache,
                );
            } else
                b = G.Oe(
                    g,
                    this,
                    f,
                    b,
                    M.q(b, {}),
                    { x: c, y: a },
                    d.allowForcedSplit || !1,
                    d.allowEllipsis || !1,
                );
            return b.la
                ? {
                      fit: !0,
                      lineCount: b.oc,
                      fontSize: b.fontSize,
                      box: { x: b.da.x, y: b.da.y, w: b.da.f, h: b.da.i },
                      ellipsis: b.gc,
                  }
                : { fit: !1 };
        };
        return e;
    });
    var ha = v.Gd(function () {
        function e(a) {
            this.P = a;
            this.d = [];
            this.Kb = [void 0];
            this.Pc = ['#SIZE#px sans-serif'];
            this.Od = [0];
            this.Pd = [1];
            this.le = [0];
            this.me = [0];
            this.ne = [0];
            this.Td = [10];
            this.jc = [10];
            this.Ub = [
                this.Kb,
                this.Pc,
                this.jc,
                this.Od,
                this.Pd,
                this.le,
                this.Td,
                this.me,
                this.ne,
            ];
            this.ga = [1, 0, 0, 1, 0, 0];
        }
        function m(a) {
            var b = a.P,
                c = a.Ub[0].length - 1;
            a.Kb[c] && (b.setLineDash(a.Kb[c]), (b.Rj = a.Od[c]));
            b.miterLimit = a.Td[c];
            b.lineWidth = a.Pd[c];
            b.shadowBlur = a.le[c];
            b.shadowOffsetX = a.me[c];
            b.shadowOffsetY = a.ne[c];
            b.font = a.Pc[c].replace('#SIZE#', a.jc[c].toString());
        }
        function g(a) {
            return function () {
                return this.P[a].apply(this.P, arguments);
            };
        }
        function d(a) {
            return function (b, c) {
                var d = this.ga;
                return this.P[a].call(this.P, f(b, c, d), k(b, c, d));
            };
        }
        function b(a) {
            return function (b, c, d, g) {
                var e = this.ga;
                return this.P[a].call(
                    this.P,
                    f(b, c, e),
                    k(b, c, e),
                    d * e[0],
                    g * e[3],
                );
            };
        }
        function f(a, b, c) {
            return a * c[0] + b * c[2] + c[4];
        }
        function k(a, b, c) {
            return a * c[1] + b * c[3] + c[5];
        }
        function l(a, b) {
            for (var c = 0; c < a.length; c++) a[c] *= b[0];
            return a;
        }
        e.prototype.save = function () {
            this.d.push(this.ga.slice(0));
            for (var a = 0; a < this.Ub.length; a++) {
                var b = this.Ub[a];
                b.push(b[b.length - 1]);
            }
            this.P.save();
        };
        e.prototype.restore = function () {
            this.ga = this.d.pop();
            for (var a = 0; a < this.Ub.length; a++) this.Ub[a].pop();
            this.P.restore();
            m(this);
        };
        e.prototype.scale = function (a, b) {
            var c = this.ga;
            c[0] *= a;
            c[1] *= a;
            c[2] *= b;
            c[3] *= b;
            var c = this.ga,
                f = this.Ub,
                d = f[0].length - 1,
                g = this.Kb[d];
            g && l(g, c);
            for (g = 2; g < f.length; g++) {
                var e = f[g];
                e[d] *= c[0];
            }
            m(this);
        };
        e.prototype.translate = function (a, b) {
            var c = this.ga;
            c[4] += c[0] * a + c[2] * b;
            c[5] += c[1] * a + c[3] * b;
        };
        ['moveTo', 'lineTo'].forEach(function (a) {
            e.prototype[a] = d(a);
        });
        ['clearRect', 'fillRect', 'strokeRect', 'rect'].forEach(function (a) {
            e.prototype[a] = b(a);
        });
        'fill stroke beginPath closePath clip createImageData createPattern getImageData putImageData getLineDash setLineDash'
            .split(' ')
            .forEach(function (a) {
                e.prototype[a] = g(a);
            });
        [
            {
                wb: 'lineDashOffset',
                Ab: function (a) {
                    return a.Od;
                },
            },
            {
                wb: 'lineWidth',
                Ab: function (a) {
                    return a.Pd;
                },
            },
            {
                wb: 'miterLimit',
                Ab: function (a) {
                    return a.Td;
                },
            },
            {
                wb: 'shadowBlur',
                Ab: function (a) {
                    return a.le;
                },
            },
            {
                wb: 'shadowOffsetX',
                Ab: function (a) {
                    return a.me;
                },
            },
            {
                wb: 'shadowOffsetY',
                Ab: function (a) {
                    return a.ne;
                },
            },
        ].forEach(function (a) {
            Object.defineProperty(e.prototype, a.wb, {
                set: function (b) {
                    var c = a.Ab(this);
                    b *= this.ga[0];
                    c[c.length - 1] = b;
                    this.P[a.wb] = b;
                },
            });
        });
        var c = /(\d+(?:\.\d+)?)px/;
        Object.defineProperty(e.prototype, 'font', {
            set: function (a) {
                var b = c.exec(a);
                if (1 < b.length) {
                    var f = this.jc.length - 1;
                    this.jc[f] = parseFloat(b[1]);
                    this.Pc[f] = a.replace(c, '#SIZE#px');
                    this.P.font = this.Pc[f].replace(
                        '#SIZE#',
                        (this.jc[f] * this.ga[0]).toString(),
                    );
                }
            },
        });
        'fillStyle globalAlpha globalCompositeOperation lineCap lineJoin shadowColor strokeStyle textAlign textBaseline'
            .split(' ')
            .forEach(function (a) {
                Object.defineProperty(e.prototype, a, {
                    set: function (b) {
                        this.P[a] = b;
                    },
                });
            });
        e.prototype.arc = function (a, b, c, d, g, e) {
            var s = this.ga;
            this.P.arc(f(a, b, s), k(a, b, s), c * s[0], d, g, e);
        };
        e.prototype.arcTo = function (a, b, c, d, g) {
            var e = this.ga;
            this.P.arc(
                f(a, b, e),
                k(a, b, e),
                f(c, d, e),
                k(c, d, e),
                g * e[0],
            );
        };
        e.prototype.bezierCurveTo = function (a, b, c, d, g, e) {
            var s = this.ga;
            this.P.bezierCurveTo(
                f(a, b, s),
                k(a, b, s),
                f(c, d, s),
                k(c, d, s),
                f(g, e, s),
                k(g, e, s),
            );
        };
        e.prototype.drawImage = function (a, b, c, d, g, e, s, l, m) {
            function y(b, c, d, g) {
                A.push(f(b, c, x));
                A.push(k(b, c, x));
                d = D.V(d) ? a.width : d;
                g = D.V(g) ? a.height : g;
                A.push(d * x[0]);
                A.push(g * x[3]);
            }
            var x = this.ga,
                A = [a];
            D.V(e) ? y(b, c, d, g) : y(e, s, l, m);
            this.P.drawImage.apply(this.P, A);
        };
        e.prototype.quadraticCurveTo = function (a, b, c, d) {
            var g = this.ga;
            this.P.quadraticCurveTo(
                f(a, b, g),
                k(a, b, g),
                f(c, d, g),
                k(c, d, g),
            );
        };
        e.prototype.fillText = function (a, b, c, d) {
            var g = this.ga;
            this.P.fillText(
                a,
                f(b, c, g),
                k(b, c, g),
                D.Uc(d) ? d * g[0] : 1e20,
            );
        };
        e.prototype.setLineDash = function (a) {
            a = l(a.slice(0), this.ga);
            this.Kb[this.Kb.length - 1] = a;
            this.P.setLineDash(a);
        };
        return e;
    });
    var ja = (function () {
        var e = !v.qf() || v.of() || v.lf() ? 1 : 7;
        return {
            fh: function () {
                function m(a) {
                    a.beginPath();
                    ia.oe(a, l);
                }
                var g = document.createElement('canvas');
                g.width = 800;
                g.height = 600;
                var d = g.getContext('2d'),
                    b = g.width,
                    g = g.height,
                    f,
                    k = 0,
                    l = [{ x: 0, y: 100 }];
                for (f = 1; 6 >= f; f++)
                    (k = (2 * f * Math.PI) / 6),
                        l.push({
                            x: 0 + 100 * Math.sin(k),
                            y: 0 + 100 * Math.cos(k),
                        });
                f = {
                    polygonPlainFill: [
                        m,
                        function (a) {
                            a.fillStyle = 'rgb(255, 0, 0)';
                            a.fill();
                        },
                    ],
                    polygonPlainStroke: [
                        m,
                        function (a) {
                            a.strokeStyle = 'rgb(128, 0, 0)';
                            a.lineWidth = 2;
                            a.closePath();
                            a.stroke();
                        },
                    ],
                    polygonGradientFill: [
                        m,
                        function (a) {
                            var b = a.createRadialGradient(0, 0, 10, 0, 0, 60);
                            b.addColorStop(0, 'rgb(255, 0, 0)');
                            b.addColorStop(1, 'rgb(255, 255, 0)');
                            a.fillStyle = b;
                            a.fill();
                        },
                    ],
                    polygonGradientStroke: [
                        m,
                        function (a) {
                            var b = a.createLinearGradient(
                                -100,
                                -100,
                                100,
                                100,
                            );
                            b.addColorStop(0, 'rgb(224, 0, 0)');
                            b.addColorStop(1, 'rgb(32, 0, 0)');
                            a.strokeStyle = b;
                            a.lineWidth = 2;
                            a.closePath();
                            a.stroke();
                        },
                    ],
                    polygonExposureShadow: [
                        m,
                        function (a) {
                            a.shadowBlur = 50;
                            a.shadowColor = 'rgba(0, 0, 0, 1)';
                            a.fillStyle = 'rgba(0, 0, 0, 1)';
                            a.globalCompositeOperation = 'source-over';
                            a.fill();
                            a.shadowBlur = 0;
                            a.shadowColor = 'transparent';
                            a.globalCompositeOperation = 'destination-out';
                            a.fill();
                        },
                    ],
                    labelPlainFill: [
                        function (a) {
                            a.fillStyle = '#000';
                            a.font = '24px sans-serif';
                            a.textAlign = 'center';
                        },
                        function (a) {
                            a.fillText('Some text', 0, -16);
                            a.fillText('for testing purposes', 0, 16);
                        },
                    ],
                };
                var k = 100 / Object.keys(f).length,
                    c = aa.now(),
                    a = {},
                    h;
                for (h in f) {
                    var n = f[h],
                        q = aa.now(),
                        p,
                        r = 0;
                    do {
                        d.save();
                        d.translate(Math.random() * b, Math.random() * g);
                        p = 3 * Math.random() + 0.5;
                        d.scale(p, p);
                        for (p = 0; p < n.length; p++) n[p](d);
                        d.restore();
                        r++;
                        p = aa.now();
                    } while (p - q < k);
                    a[h] = (e * (p - q)) / r;
                }
                a.total = aa.now() - c;
                return a;
            },
        };
    })();
    var ia = {
        oe: function (e, m) {
            var g = m[0];
            e.moveTo(g.x, g.y);
            for (var d = m.length - 1; 0 < d; d--)
                (g = m[d]), e.lineTo(g.x, g.y);
        },
        uj: function (e, m, g, d) {
            var b,
                f,
                k,
                l = [],
                c = 0,
                a = m.length;
            for (k = 0; k < a; k++)
                (b = m[k]),
                    (f = m[(k + 1) % a]),
                    (b = M.d(b, f)),
                    (b = Math.sqrt(b)),
                    l.push(b),
                    (c += b);
            g = d * (g + (0.5 * d * c) / a);
            var h, n;
            d = {};
            var c = {},
                q = {},
                p = 0;
            for (k = 0; k < a; k++)
                (b = m[k]),
                    (f = m[(k + 1) % a]),
                    (h = m[(k + 2) % a]),
                    (n = l[(k + 1) % a]),
                    (n = Math.min(0.5, g / n)),
                    M.za(1 - n, f, h, c),
                    M.za(n, f, h, q),
                    p++,
                    0 == k &&
                        ((h = Math.min(0.5, g / l[0])),
                        M.za(h, b, f, d),
                        p++,
                        e.moveTo(d.x, d.y)),
                    e.quadraticCurveTo(f.x, f.y, c.x, c.y),
                    e.lineTo(q.x, q.y);
            return !0;
        },
    };
    function ka(e) {
        function m(a) {
            h[a] && (h[a].style.opacity = q * n[a]);
        }
        function g(a) {
            a.width = Math.round(f * a.n);
            a.height = Math.round(k * a.n);
        }
        function d() {
            return /relative|absolute|fixed/.test(
                window.getComputedStyle(b, null).getPropertyValue('position'),
            );
        }
        var b,
            f,
            k,
            l,
            c,
            a = [],
            h = {},
            n = {},
            q = 0;
        this.H = function (a) {
            b = a;
            d() || (b.style.position = 'relative');
            (0 != b.clientWidth && 0 != b.clientHeight) ||
                la.Pa(
                    'element has zero dimensions: ' +
                        b.clientWidth +
                        ' x ' +
                        b.clientHeight +
                        '.',
                );
            b.innerHTML = '';
            f = b.clientWidth;
            k = b.clientHeight;
            l = 0 !== f ? f : void 0;
            c = 0 !== k ? k : void 0;
            'embedded' === b.getAttribute('data-foamtree') &&
                la.Pa('visualization already embedded in the element.');
            b.setAttribute('data-foamtree', 'embedded');
            e.c.p('stage:initialized', this, b, f, k);
        };
        this.mb = function () {
            b.removeAttribute('data-foamtree');
            a = [];
            h = {};
            e.c.p('stage:disposed', this, b);
        };
        this.k = function () {
            d() || (b.style.position = 'relative');
            f = b.clientWidth;
            k = b.clientHeight;
            if (0 !== f && 0 !== k && (f !== l || k !== c)) {
                for (var h = a.length - 1; 0 <= h; h--) g(a[h]);
                e.c.p('stage:resized', l, c, f, k);
                l = f;
                c = k;
            }
        };
        this.ij = function (a, b) {
            a.n = b;
            g(a);
        };
        this.qc = function (c, f, d) {
            var k = document.createElement('canvas');
            k.setAttribute(
                'style',
                'position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; -webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;',
            );
            k.n = f;
            g(k);
            a.push(k);
            h[c] = k;
            n[c] = 1;
            m(c);
            d || b.appendChild(k);
            e.c.p('stage:newLayer', c, k);
            return k;
        };
        this.mc = function (a, b) {
            D.V(b) || ((n[a] = b), m(a));
            return n[a];
        };
        this.d = function (a) {
            D.V(a) ||
                ((q = a),
                D.Fa(h, function (a, b) {
                    m(b);
                }));
            return q;
        };
    }
    function oa(e) {
        function m(a, c, d) {
            y = !0;
            q.x = 0;
            q.y = 0;
            p.x = 0;
            p.y = 0;
            b = h;
            f.x = n.x;
            f.y = n.y;
            c();
            k *= a;
            l = d ? k / b : a;
            l = Math.max(0.25 / b, l);
            return !0;
        }
        function g(a, b) {
            b.x = a.x / h + n.x;
            b.y = a.y / h + n.y;
            return b;
        }
        function d(a, b, c, f, d, g, e, h, k) {
            var s = (a - c) * (g - h) - (b - f) * (d - e);
            if (1e-5 > Math.abs(s)) return !1;
            k.x = ((a * f - b * c) * (d - e) - (a - c) * (d * h - g * e)) / s;
            k.y = ((a * f - b * c) * (g - h) - (b - f) * (d * h - g * e)) / s;
            return !0;
        }
        var b = 1,
            f = { x: 0, y: 0 },
            k = 1,
            l = 1,
            c = 1,
            a = { x: 0, y: 0 },
            h = 1,
            n = { x: 0, y: 0 },
            q = { x: 0, y: 0 },
            p = { x: 0, y: 0 },
            r,
            s,
            w = { x: 0, y: 0, f: 0, i: 0 },
            u = { x: 0, y: 0, f: 0, i: 0, scale: 1 },
            y = !0;
        e.c.j('stage:initialized', function (a, b, c, f) {
            r = c;
            s = f;
            w.x = 0;
            w.y = 0;
            w.f = c;
            w.i = f;
            u.x = 0;
            u.y = 0;
            u.f = c;
            u.i = f;
            u.scale = 1;
        });
        e.c.j('stage:resized', function (b, c, d, g) {
            function e(a) {
                a.x *= k;
                a.y *= l;
            }
            function h(a) {
                e(a);
                a.f *= k;
                a.i *= l;
            }
            r = d;
            s = g;
            var k = d / b,
                l = g / c;
            e(f);
            e(n);
            e(a);
            e(q);
            e(p);
            h(w);
            h(u);
        });
        this.$b = function (b, c) {
            return m(
                c,
                function () {
                    g(b, a);
                },
                !0,
            );
        };
        this.Y = function (b, c) {
            if (1 === Math.round(1e4 * c) / 1e4) {
                var f = w.x - n.x,
                    g = w.y - n.y;
                m(1, function () {}, !0);
                return this.d(-f, -g);
            }
            return m(
                c,
                function () {
                    for (var c = !1; !c; )
                        var c = Math.random(),
                            f = Math.random(),
                            g = Math.random(),
                            e = Math.random(),
                            c = d(
                                b.x + c * b.f,
                                b.y + f * b.i,
                                w.x + c * w.f,
                                w.y + f * w.i,
                                b.x + g * b.f,
                                b.y + e * b.i,
                                w.x + g * w.f,
                                w.y + e * w.i,
                                a,
                            );
                },
                !0,
            );
        };
        this.uc = function (b, c) {
            var f, g, e, k;
            f = b.f / b.i;
            g = r / s;
            f < g
                ? ((e = b.i * g),
                  (k = b.i),
                  (f = b.x - 0.5 * (e - b.f)),
                  (g = b.y))
                : f > g
                ? ((e = b.f),
                  (k = (b.f * s) / r),
                  (f = b.x),
                  (g = b.y - 0.5 * (k - b.i)))
                : ((f = b.x), (g = b.y), (e = b.f), (k = b.i));
            f -= e * c;
            g -= k * c;
            e *= 1 + 2 * c;
            if (d(f, g, n.x, n.y, f + e, g, n.x + r / h, n.y, a))
                return m(r / h / e, D.sa, !1);
            y = !1;
            return this.d(h * (n.x - f), h * (n.y - g));
        };
        this.d = function (a, b) {
            var c = Math.round(1e4 * a) / 1e4,
                f = Math.round(1e4 * b) / 1e4;
            p.x += c / h;
            p.y += f / h;
            return 0 !== c || 0 !== f;
        };
        this.reset = function (a) {
            a && this.content(0, 0, r, s);
            return this.Y(
                { x: w.x + n.x, y: w.y + n.y, f: w.f / h, i: w.i / h },
                c / k,
            );
        };
        this.Sb = function (a) {
            c = Math.min(1, Math.round(1e4 * (a || k)) / 1e4);
        };
        this.k = function () {
            return n.x < w.x
                ? (w.x - n.x) * h
                : n.x + r / h > w.x + w.f
                ? -(n.x + r / h - w.x - w.f) * h
                : 0;
        };
        this.A = function () {
            return n.y < w.y
                ? (w.y - n.y) * h
                : n.y + s / h > w.y + w.i
                ? -(n.y + s / h - w.y - w.i) * h
                : 0;
        };
        this.update = function (c) {
            var d = Math.abs(Math.log(l));
            6 > d ? (d = 2) : ((d /= 4), (d += 3 * d * (1 < l ? c : 1 - c)));
            d = 1 < l ? Math.pow(c, d) : 1 - Math.pow(1 - c, d);
            d = (y ? d : 1) * (l - 1) + 1;
            h = b * d;
            n.x = a.x - (a.x - f.x) / d;
            n.y = a.y - (a.y - f.y) / d;
            n.x -= q.x * (1 - c) + p.x * c;
            n.y -= q.y * (1 - c) + p.y * c;
            1 === c && ((q.x = p.x), (q.y = p.y));
            u.x = n.x;
            u.y = n.y;
            u.f = r / h;
            u.i = s / h;
            u.scale = h;
        };
        this.S = function (a) {
            a.x = u.x;
            a.y = u.y;
            a.scale = u.scale;
            return a;
        };
        this.absolute = function (a, b) {
            return g(a, b || {});
        };
        this.pd = function (a, b) {
            var c = b || {};
            c.x = (a.x - n.x) * h;
            c.y = (a.y - n.y) * h;
            return c;
        };
        this.Jc = function (a) {
            return this.scale() < c / a;
        };
        this.Ud = function () {
            return D.Id(h, 1);
        };
        this.scale = function () {
            return Math.round(1e4 * h) / 1e4;
        };
        this.content = function (a, b, c, f) {
            w.x = a;
            w.y = b;
            w.f = c;
            w.i = f;
        };
        this.Lc = function (a, b) {
            var c;
            for (c = a.length - 1; 0 <= c; c--) {
                var f = a[c];
                f.save();
                f.scale(h, h);
                f.translate(-n.x, -n.y);
            }
            b(u);
            for (c = a.length - 1; 0 <= c; c--) (f = a[c]), f.restore();
        };
    }
    var S = new (function () {
        function e(g) {
            if ('hsl' == g.model || 'hsla' == g.model) return g;
            var d = (g.r /= 255),
                b = (g.g /= 255),
                f = (g.b /= 255),
                e = Math.max(d, b, f),
                l = Math.min(d, b, f),
                c,
                a = (e + l) / 2;
            if (e == l) c = l = 0;
            else {
                var h = e - l,
                    l = 0.5 < a ? h / (2 - e - l) : h / (e + l);
                switch (e) {
                    case d:
                        c = (b - f) / h + (b < f ? 6 : 0);
                        break;
                    case b:
                        c = (f - d) / h + 2;
                        break;
                    case f:
                        c = (d - b) / h + 4;
                }
                c /= 6;
            }
            g.h = 360 * c;
            g.s = 100 * l;
            g.l = 100 * a;
            g.model = 'hsl';
            return g;
        }
        var m = { h: 0, s: 0, l: 0, a: 1, model: 'hsla' };
        this.Aa = function (g) {
            return D.Vc(g) ? e(S.Hg(g)) : D.lc(g) ? e(g) : m;
        };
        this.Hg = function (g) {
            var d;
            return (d =
                /rgba\(\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*\)/.exec(
                    g,
                )) && 5 == d.length
                ? {
                      r: parseFloat(d[1]),
                      g: parseFloat(d[2]),
                      b: parseFloat(d[3]),
                      a: parseFloat(d[4]),
                      model: 'rgba',
                  }
                : (d =
                      /hsla\(\s*([^,\s]+)\s*,\s*([^,%\s]+)%\s*,\s*([^,\s%]+)%\s*,\s*([^,\s]+)\s*\)/.exec(
                          g,
                      )) && 5 == d.length
                ? {
                      h: parseFloat(d[1]),
                      s: parseFloat(d[2]),
                      l: parseFloat(d[3]),
                      a: parseFloat(d[4]),
                      model: 'hsla',
                  }
                : (d =
                      /rgb\(\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*\)/.exec(
                          g,
                      )) && 4 == d.length
                ? {
                      r: parseFloat(d[1]),
                      g: parseFloat(d[2]),
                      b: parseFloat(d[3]),
                      a: 1,
                      model: 'rgb',
                  }
                : (d =
                      /hsl\(\s*([^,\s]+)\s*,\s*([^,\s%]+)%\s*,\s*([^,\s%]+)%\s*\)/.exec(
                          g,
                      )) && 4 == d.length
                ? {
                      h: parseFloat(d[1]),
                      s: parseFloat(d[2]),
                      l: parseFloat(d[3]),
                      a: 1,
                      model: 'hsl',
                  }
                : (d = /#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})/.exec(
                      g,
                  )) && 4 == d.length
                ? {
                      r: parseInt(d[1], 16),
                      g: parseInt(d[2], 16),
                      b: parseInt(d[3], 16),
                      a: 1,
                      model: 'rgb',
                  }
                : (d = /#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])/.exec(g)) &&
                  4 == d.length
                ? {
                      r: 17 * parseInt(d[1], 16),
                      g: 17 * parseInt(d[2], 16),
                      b: 17 * parseInt(d[3], 16),
                      a: 1,
                      model: 'rgb',
                  }
                : m;
        };
        this.Cg = function (g) {
            function d(b, a, f) {
                0 > f && (f += 1);
                1 < f && (f -= 1);
                return f < 1 / 6
                    ? b + 6 * (a - b) * f
                    : 0.5 > f
                    ? a
                    : f < 2 / 3
                    ? b + (a - b) * (2 / 3 - f) * 6
                    : b;
            }
            if ('rgb' == g.model || 'rgba' == g.model)
                return (
                    Math.sqrt(
                        g.r * g.r * 0.241 +
                            g.g * g.g * 0.691 +
                            g.b * g.b * 0.068,
                    ) / 255
                );
            var b, f;
            b = g.l / 100;
            var e = g.s / 100;
            f = g.h / 360;
            if (0 == g.Uj) b = g = f = b;
            else {
                var e = 0.5 > b ? b * (1 + e) : b + e - b * e,
                    l = 2 * b - e;
                b = d(l, e, f + 1 / 3);
                g = d(l, e, f);
                f = d(l, e, f - 1 / 3);
            }
            return (
                Math.sqrt(
                    65025 * b * b * 0.241 +
                        65025 * g * g * 0.691 +
                        65025 * f * f * 0.068,
                ) / 255
            );
        };
        this.Ng = function (g) {
            if (D.Vc(g)) return g;
            if (D.lc(g))
                switch (g.model) {
                    case 'hsla':
                        return S.Ig(g);
                    case 'hsl':
                        return S.Bc(g);
                    case 'rgba':
                        return S.Lg(g);
                    case 'rgb':
                        return S.Kg(g);
                    default:
                        return '#000';
                }
            else return '#000';
        };
        this.Lg = function (g) {
            return (
                'rgba(' +
                ((0.5 + g.r) | 0) +
                ',' +
                ((0.5 + g.g) | 0) +
                ',' +
                ((0.5 + g.b) | 0) +
                ',' +
                g.a +
                ')'
            );
        };
        this.Kg = function (g) {
            return (
                'rgba(' +
                ((0.5 + g.r) | 0) +
                ',' +
                ((0.5 + g.g) | 0) +
                ',' +
                ((0.5 + g.b) | 0) +
                ')'
            );
        };
        this.Ig = function (g) {
            return (
                'hsla(' +
                ((0.5 + g.h) | 0) +
                ',' +
                ((0.5 + g.s) | 0) +
                '%,' +
                ((0.5 + g.l) | 0) +
                '%,' +
                g.a +
                ')'
            );
        };
        this.Bc = function (g) {
            return (
                'hsl(' +
                ((0.5 + g.h) | 0) +
                ',' +
                ((0.5 + g.s) | 0) +
                '%,' +
                ((0.5 + g.l) | 0) +
                '%)'
            );
        };
        this.Y = function (g, d, b) {
            return (
                'hsl(' +
                ((0.5 + g) | 0) +
                ',' +
                ((0.5 + d) | 0) +
                '%,' +
                ((0.5 + b) | 0) +
                '%)'
            );
        };
    })();
    function V() {
        var e = !1,
            m,
            g = [],
            d = this,
            b = new (function () {
                this.O = function (b) {
                    b && (e ? b.apply(d, m) : g.push(b));
                    return this;
                };
                this.jh = function (b) {
                    d = b;
                    return { then: this.O };
                };
            })();
        this.J = function () {
            m = arguments;
            for (var b = 0; b < g.length; b++) g[b].apply(d, m);
            e = !0;
            return this;
        };
        this.L = function () {
            return b;
        };
    }
    function pa(e) {
        var m = new V(),
            g = e.length;
        if (0 < e.length)
            for (var d = e.length - 1; 0 <= d; d--)
                e[d].O(function () {
                    0 === --g && m.J();
                });
        else m.J();
        return m.L();
    }
    function qa(e) {
        var m = 0;
        this.d = function () {
            m++;
        };
        this.k = function () {
            m--;
            0 === m && e();
        };
        this.clear = function () {
            m = 0;
        };
        this.A = function () {
            return 0 === m;
        };
    }
    var ra = {
        Le: function (e, m, g, d) {
            d = d || {};
            var b;
            try {
                b = e.getBoundingClientRect();
            } catch (f) {
                if (!ra.li) {
                    ra.li = !0;
                    window.console.log('getBoundingClientRect() failed.');
                    for (
                        window.console.log('Element', e);
                        null !== e.parentElement;

                    )
                        e = e.parentElement;
                    window.console.log(
                        'Attached to DOM',
                        e === document.body.parentElement,
                    );
                }
                b = { left: 0, top: 0 };
            }
            d.x = m - b.left;
            d.y = g - b.top;
            return d;
        },
    };
    function sa() {
        var e = document,
            m = {};
        this.addEventListener = function (g, d) {
            var b = m[g];
            b || ((b = []), (m[g] = b));
            b.push(d);
            e.addEventListener(g, d);
        };
        this.d = function () {
            D.Fa(m, function (g, d) {
                for (var b = g.length - 1; 0 <= b; b--)
                    e.removeEventListener(d, g[b]);
            });
        };
    }
    function ta(e) {
        function m(a) {
            return function (b) {
                g(b) && a.apply(this, arguments);
            };
        }
        function g(a) {
            for (a = a.target; a; ) {
                if (a === e) return !0;
                a = a.parentElement;
            }
            return !1;
        }
        function d(a, c, f) {
            f = f || {};
            b(a, f);
            for (var d = 0; d < c.length; d++) c[d].call(a.target, f);
            ((void 0 === f.Ob && f.Ci) || 'prevent' === f.Ob) &&
                a.preventDefault();
            return f;
        }
        function b(a, b) {
            ra.Le(e, a.clientX, a.clientY, b);
            b.altKey = a.altKey;
            b.metaKey = a.metaKey;
            b.ctrlKey = a.ctrlKey;
            b.shiftKey = a.shiftKey;
            b.yb = 3 === a.which;
            return b;
        }
        var f = new sa(),
            k = [],
            l = [],
            c = [],
            a = [],
            h = [],
            n = [],
            q = [],
            p = [],
            r = [],
            s = [],
            w = [];
        this.d = function (a) {
            k.push(a);
        };
        this.k = function (a) {
            h.push(a);
        };
        this.xa = function (a) {
            l.push(a);
        };
        this.Aa = function (a) {
            c.push(a);
        };
        this.Pa = function (b) {
            a.push(b);
        };
        this.za = function (a) {
            w.push(a);
        };
        this.ya = function (a) {
            n.push(a);
        };
        this.Ia = function (a) {
            q.push(a);
        };
        this.Y = function (a) {
            p.push(a);
        };
        this.A = function (a) {
            r.push(a);
        };
        this.S = function (a) {
            s.push(a);
        };
        this.mb = function () {
            f.d();
        };
        var u,
            y,
            x,
            A,
            B = { x: 0, y: 0 },
            K = { x: 0, y: 0 },
            C = !1,
            H = !1;
        f.addEventListener(
            'mousedown',
            m(function (a) {
                if (a.target !== e) {
                    var b = d(a, c);
                    K.x = b.x;
                    K.y = b.y;
                    B.x = b.x;
                    B.y = b.y;
                    C = !0;
                    d(a, p);
                    y = !1;
                    u = window.setTimeout(function () {
                        100 > M.d(B, b) &&
                            (window.clearTimeout(A), d(a, l), (y = !0));
                    }, 400);
                }
            }),
        );
        f.addEventListener('mouseup', function (b) {
            function c(a) {
                var b = {};
                b.x = a.pageX;
                b.y = a.pageY;
                return b;
            }
            d(b, a);
            if (C) {
                H && d(b, s);
                window.clearTimeout(u);
                if (!y && !H && g(b)) {
                    var f = c(b);
                    x && 100 > M.d(f, x) ? d(b, h) : d(b, k);
                    x = f;
                    A = window.setTimeout(function () {
                        x = null;
                    }, 350);
                }
                H = C = !1;
            }
        });
        f.addEventListener('mousemove', function (a) {
            var c = b(a, {});
            g(a) && d(a, n, { type: 'move' });
            B.x = c.x;
            B.y = c.y;
            C && !H && 100 < M.d(K, B) && (H = !0);
            H && d(a, r, c);
        });
        f.addEventListener(
            'mouseout',
            m(function (a) {
                d(a, q, { type: 'out' });
            }),
        );
        f.addEventListener(
            'wheel',
            m(
                (function () {
                    return function (a) {
                        var b;
                        'deltaY' in a
                            ? (b = a.deltaY)
                            : ((b = 0),
                              'detail' in a && (b = a.detail),
                              'wheelDelta' in a && (b = -a.wheelDelta / 120),
                              'wheelDeltaY' in a && (b = -a.wheelDeltaY / 120),
                              'axis' in a &&
                                  a.axis === a.HORIZONTAL_AXIS &&
                                  (b = 0),
                              (b *= 10));
                        b &&
                            a.deltaMode &&
                            (b = 1 === a.deltaMode ? 67 * b : 800 * b);
                        d(a, w, { zd: -b / 200, Ci: !0 });
                    };
                })(),
            ),
        );
        f.addEventListener(
            'contextmenu',
            m(function (a) {
                a.preventDefault();
            }),
        );
    }
    var X = (function () {
        function e(b) {
            return function (f) {
                return Math.pow(f, b);
            };
        }
        function m(b) {
            return function (f) {
                return 1 - Math.pow(1 - f, b);
            };
        }
        function g(b) {
            return function (f) {
                return 1 > (f *= 2)
                    ? 0.5 * Math.pow(f, b)
                    : 1 - 0.5 * Math.abs(Math.pow(2 - f, b));
            };
        }
        function d(b) {
            return function (f) {
                for (var d = 0; d < b.length; d++) f = (0, b[d])(f);
                return f;
            };
        }
        return {
            oa: function (b) {
                switch (b) {
                    case 'linear':
                        return X.Lb;
                    case 'bounce':
                        return X.Wg;
                    case 'squareIn':
                        return X.pg;
                    case 'squareOut':
                        return X.Tb;
                    case 'squareInOut':
                        return X.qg;
                    case 'cubicIn':
                        return X.$g;
                    case 'cubicOut':
                        return X.Ce;
                    case 'cubicInOut':
                        return X.ah;
                    case 'quadIn':
                        return X.Ui;
                    case 'quadOut':
                        return X.Wi;
                    case 'quadInOut':
                        return X.Vi;
                    default:
                        return X.Lb;
                }
            },
            Lb: function (b) {
                return b;
            },
            Wg: d([
                g(2),
                function (b) {
                    return 0 === b
                        ? 0
                        : 1 === b
                        ? 1
                        : b *
                          (b *
                              (b * (b * (25.9425 * b - 85.88) + 105.78) -
                                  58.69) +
                              13.8475);
                },
            ]),
            pg: e(2),
            Tb: m(2),
            qg: g(2),
            $g: e(3),
            Ce: m(3),
            ah: g(3),
            Ui: e(2),
            Wi: m(2),
            Vi: g(2),
            d: d,
        };
    })();
    var D = {
        V: function (e) {
            return void 0 === e;
        },
        pf: function (e) {
            return null === e;
        },
        Uc: function (e) {
            return '[object Number]' === Object.prototype.toString.call(e);
        },
        Vc: function (e) {
            return '[object String]' === Object.prototype.toString.call(e);
        },
        Jd: function (e) {
            return 'function' === typeof e;
        },
        lc: function (e) {
            return e === Object(e);
        },
        Id: function (e, m) {
            return 1e-6 > e - m && -1e-6 < e - m;
        },
        mf: function (e) {
            return D.V(e) || D.pf(e) || (D.Vc(e) && !/\S/.test(e));
        },
        M: function (e, m) {
            return e && e.hasOwnProperty(m);
        },
        pb: function (e, m) {
            if (e)
                for (var g = m.length - 1; 0 <= g; g--)
                    if (e.hasOwnProperty(m[g])) return !0;
            return !1;
        },
        extend: function (e) {
            D.eh(Array.prototype.slice.call(arguments, 1), function (m) {
                if (m) for (var g in m) m.hasOwnProperty(g) && (e[g] = m[g]);
            });
            return e;
        },
        A: function (e, m) {
            return e.map(function (g) {
                return g[m];
            }, []);
        },
        eh: function (e, m, g) {
            null != e && (e.forEach ? e.forEach(m, g) : D.Fa(e, m, g));
        },
        Fa: function (e, m, g) {
            for (var d in e)
                if (e.hasOwnProperty(d) && !1 === m.call(g, e[d], d, e)) break;
        },
        B: function () {
            for (var e = 0; e < arguments.length; e++) {
                var m = arguments[e];
                if (!(D.V(m) || (D.Uc(m) && isNaN(m)) || (D.Vc(m) && D.mf(m))))
                    return m;
            }
        },
        eg: function (e, m) {
            var g = e.indexOf(m);
            0 <= g && e.splice(g, 1);
        },
        bh: function (e, m, g) {
            var d;
            return function () {
                var b = this,
                    f = arguments,
                    k = g && !d;
                clearTimeout(d);
                d = setTimeout(function () {
                    d = null;
                    g || e.apply(b, f);
                }, m);
                k && e.apply(b, f);
            };
        },
        defer: function (e) {
            setTimeout(e, 1);
        },
        k: function (e) {
            return e;
        },
        sa: function () {},
    };
    var ua = {
        ki: function (e, m, g) {
            return v.ii()
                ? function () {
                      var d = m + ':' + JSON.stringify(arguments),
                          b = window.localStorage.getItem(d);
                      b && (b = JSON.parse(b));
                      if (b && Date.now() - b.t < g) return b.v;
                      b = e.apply(this, arguments);
                      window.localStorage.setItem(
                          d,
                          JSON.stringify({ v: b, t: Date.now() }),
                      );
                      return b;
                  }
                : e;
        },
    };
    var va = {
        m: function (e, m) {
            function g() {
                var d = [];
                if (Array.isArray(e))
                    for (var b = 0; b < e.length; b++) {
                        var f = e[b];
                        f && d.push(f.apply(m, arguments));
                    }
                else e && d.push(e.apply(m, arguments));
                return d;
            }
            g.empty = function () {
                return 0 === e.length && !D.Jd(e);
            };
            return g;
        },
    };
    function wa() {
        var e = {};
        this.j = function (m, g) {
            var d = e[m];
            d || ((d = []), (e[m] = d));
            d.push(g);
        };
        this.p = function (m, g) {
            var d = e[m];
            if (d)
                for (
                    var b = Array.prototype.slice.call(arguments, 1), f = 0;
                    f < d.length;
                    f++
                )
                    d[f].apply(this, b);
        };
    }
    function xa(e) {
        function m(a, b, g) {
            var q = this,
                p,
                r = 0;
            this.id = k++;
            this.name = g ? g : '{unnamed on ' + a + '}';
            this.target = function () {
                return a;
            };
            this.Ib = function () {
                return -1 != c.indexOf(q);
            };
            this.start = function () {
                if (!q.Ib()) {
                    if (-1 == c.indexOf(q)) {
                        var a = l.now();
                        !0 === q.zf(a) && ((c = c.slice()), c.push(q));
                    }
                    0 < c.length && e.repeat(d);
                }
                return this;
            };
            this.stop = function () {
                for (f(q); p < b.length; p++) {
                    var a = b[p];
                    a.kb && a.Za.call();
                }
                return this;
            };
            this.gg = function () {
                p = void 0;
            };
            this.zf = function (a) {
                r++;
                if (0 !== b.length) {
                    var c;
                    D.V(p)
                        ? ((p = 0), (c = b[p]), c.W && c.W.call(c, a, r, q))
                        : (c = b[p]);
                    for (; p < b.length; ) {
                        if (c.Za && c.Za.call(c, a, r, q)) return !0;
                        c.Ca && c.Ca.call(c, a, r, q);
                        D.V(p) && (p = -1);
                        ++p < b.length &&
                            ((c = b[p]), c.W && c.W.call(c, a, r, q));
                    }
                }
                return !1;
            };
        }
        function g(a) {
            return D.V(a)
                ? c.slice()
                : c.filter(function (b) {
                      return b.target() === a;
                  });
        }
        function d() {
            b();
            0 == c.length && e.cancel(d);
        }
        function b() {
            var a = l.now();
            c.forEach(function (b) {
                !0 !== b.zf(a) && f(b);
            });
        }
        function f(a) {
            c = c.filter(function (b) {
                return b !== a;
            });
        }
        var k = 0,
            l = aa.create(),
            c = [];
        this.d = function () {
            for (var a = c.length - 1; 0 <= a; a--) c[a].stop();
            c = [];
        };
        this.D = (function () {
            function a() {}
            function b(a) {
                var c = a.target,
                    f = a.duration,
                    d = a.ca,
                    g,
                    e;
                this.W = function () {
                    g = {};
                    for (var b in a.G)
                        c.hasOwnProperty(b) &&
                            (g[b] = {
                                start: D.V(a.G[b].start)
                                    ? c[b]
                                    : D.Jd(a.G[b].start)
                                    ? a.G[b].start.call(void 0)
                                    : a.G[b].start,
                                end: D.V(a.G[b].end)
                                    ? c[b]
                                    : D.Jd(a.G[b].end)
                                    ? a.G[b].end.call(void 0)
                                    : a.G[b].end,
                                Q: D.V(a.G[b].Q) ? X.Lb : a.G[b].Q,
                            });
                    e = l.now();
                };
                this.Za = function () {
                    var a = l.now() - e,
                        a = 0 === f ? 1 : Math.min(f, a) / f,
                        b;
                    for (b in g) {
                        var k = g[b];
                        c[b] = k.start + (k.end - k.start) * k.Q(a);
                    }
                    d && d.call(c, a);
                    return 1 > a;
                };
            }
            function c(a, b, f) {
                this.kb = f;
                this.Za = function () {
                    a.call(b);
                    return !1;
                };
            }
            function f(a) {
                var b;
                this.W = function (c, f) {
                    b = f + a;
                };
                this.Za = function (a, c) {
                    return c < b;
                };
            }
            function d(a) {
                var b;
                this.W = function (c) {
                    b = c + a;
                };
                this.Za = function (a) {
                    return a < b;
                };
            }
            function e(a) {
                this.W = function () {
                    a.forEach(function (a) {
                        a.start();
                    });
                };
                this.Za = function () {
                    for (var b = 0; b < a.length; b++) if (a[b].Ib()) return !0;
                    return !1;
                };
            }
            a.m = function (a, g) {
                return new (function () {
                    function k(b, f, d, g) {
                        return f
                            ? (D.V(d) && (d = a), b.Cb(new c(f, d, g)))
                            : b;
                    }
                    var l = [];
                    this.Cb = function (a) {
                        l.push(a);
                        return this;
                    };
                    this.gb = function (a) {
                        return this.Cb(new d(a));
                    };
                    this.re = function (a) {
                        return this.Cb(new f(a || 1));
                    };
                    this.call = function (a, b) {
                        return k(this, a, b, !1);
                    };
                    this.kb = function (a, b) {
                        return k(this, a, b, !0);
                    };
                    this.ia = function (c) {
                        D.V(c.target) && (c.target = a);
                        return this.Cb(new b(c));
                    };
                    this.$a = function (a) {
                        return this.Cb(new e(a));
                    };
                    this.gg = function () {
                        return this.Cb({
                            Za: function (a, b) {
                                b.gg();
                                return !0;
                            },
                        });
                    };
                    this.wa = function () {
                        return new m(a, l, g);
                    };
                    this.start = function () {
                        return this.wa().start();
                    };
                    this.Gg = function () {
                        var a = new V();
                        this.re().call(a.J).wa();
                        return a.L();
                    };
                    this.cb = function () {
                        var a = this.Gg();
                        this.start();
                        return a;
                    };
                })();
            };
            a.vc = function (b) {
                g(b).forEach(function (a) {
                    a.stop();
                });
                return a.m(b, void 0);
            };
            return a;
        })();
    }
    var $ = (function () {
        var e = {
            Ke: function (e, g) {
                if (e.e) for (var d = e.e, b = 0; b < d.length; b++) g(d[b], b);
            },
            Mc: function (m, g) {
                if (m.e)
                    for (var d = m.e, b = 0; b < d.length; b++)
                        if (!1 === e.Mc(d[b], g) || !1 === g(d[b], b))
                            return !1;
            },
        };
        e.F = e.Mc;
        e.Nc = function (m, g) {
            if (m.e)
                for (var d = m.e, b = 0; b < d.length; b++)
                    if (!1 === g(d[b], b) || !1 === e.Nc(d[b], g)) return !1;
        };
        e.Ea = function (m, g) {
            if (m.e)
                for (var d = m.e, b = 0; b < d.length; b++)
                    if (!1 === e.Ea(d[b], g)) return !1;
            return g(m);
        };
        e.Lj = e.Ea;
        e.Ad = function (m, g) {
            !1 !== g(m) && e.Nc(m, g);
        };
        e.Oc = function (m, g) {
            var d = [];
            e.Nc(m, function (b) {
                d.push(b);
            });
            return g ? d.filter(g) : d;
        };
        e.Je = function (e, g) {
            for (var d = e.parent; d && !1 !== g(d); ) d = d.parent;
        };
        e.mi = function (e, g) {
            for (var d = e.parent; d && d !== g; ) d = d.parent;
            return !!d;
        };
        return e;
    })();
    var M = new (function () {
        function e(g, d) {
            var b = g.x - d.x,
                f = g.y - d.y;
            return b * b + f * f;
        }
        function m(g, d, b) {
            for (var f = 0; f < g.length; f++) {
                var e = M.ya(g[f], g[f + 1] || g[0], d, b, !0);
                if (e) return e;
            }
        }
        this.ya = function (g, d, b, f, e) {
            var l = g.x;
            g = g.y;
            var c = d.x - l;
            d = d.y - g;
            var a = b.x,
                h = b.y;
            b = f.x - a;
            var n = f.y - h;
            f = c * n - b * d;
            if (
                !(1e-12 >= f && -1e-12 <= f) &&
                ((a = a - l),
                (h = h - g),
                (b = (a * n - b * h) / f),
                (f = (a * d - c * h) / f),
                0 <= f && (e || 1 >= f) && 0 <= b && 1 >= b)
            )
                return { x: l + c * b, y: g + d * b };
        };
        this.Jg = function (g, d, b, f) {
            var e = g.x;
            g = g.y;
            var l = d.x - e;
            d = d.y - g;
            var c = b.x;
            b = b.y;
            var a = f.x - c;
            f = f.y - b;
            var h = l * f - a * d;
            if (
                !(1e-12 >= h && -1e-12 <= h) &&
                ((f = ((c - e) * f - a * (b - g)) / h), 0 <= f && 1 >= f)
            )
                return { x: e + l * f, y: g + d * f };
        };
        this.Cc = function (g, d, b) {
            for (
                var f = M.k(d, {}),
                    e = M.k(b, {}),
                    l,
                    c = e.x - f.x,
                    a = e.y - f.y,
                    h = [],
                    e = 0;
                e < b.length;
                e++
            )
                (l = b[e]), h.push({ x: l.x - c, y: l.y - a });
            b = [];
            l = [];
            for (e = 0; e < g.length; e++) {
                var n = g[e],
                    q = m(d, f, n);
                q
                    ? (b.push(q), l.push(m(h, f, n)))
                    : (b.push(null), l.push(null));
            }
            for (e = 0; e < g.length; e++)
                if (((q = b[e]), (n = l[e]), q && n)) {
                    d = g[e];
                    var h = f,
                        p = q.x - f.x,
                        q = q.y - f.y,
                        q = Math.sqrt(p * p + q * q);
                    if (1e-12 < q) {
                        var p = d.x - f.x,
                            r = d.y - f.y,
                            q = Math.sqrt(p * p + r * r) / q;
                        d.x = h.x + q * (n.x - h.x);
                        d.y = h.y + q * (n.y - h.y);
                    } else (d.x = h.x), (d.y = h.y);
                }
            for (e = 0; e < g.length; e++) (l = g[e]), (l.x += c), (l.y += a);
        };
        this.q = function (g, d) {
            if (0 !== g.length) {
                var b, f, e, l;
                b = f = g[0].x;
                e = l = g[0].y;
                for (var c = g.length; 0 < --c; )
                    (b = Math.min(b, g[c].x)),
                        (f = Math.max(f, g[c].x)),
                        (e = Math.min(e, g[c].y)),
                        (l = Math.max(l, g[c].y));
                d.x = b;
                d.y = e;
                d.f = f - b;
                d.i = l - e;
                return d;
            }
        };
        this.A = function (g) {
            return [
                { x: g.x, y: g.y },
                { x: g.x + g.f, y: g.y },
                { x: g.x + g.f, y: g.y + g.i },
                { x: g.x, y: g.y + g.i },
            ];
        };
        this.k = function (g, d) {
            for (
                var b = 0, f = 0, e = g.length, l = g[0], c = 0, a = 1;
                a < e - 1;
                a++
            )
                var h = g[a],
                    n = g[a + 1],
                    q = l.y + h.y + n.y,
                    p = (h.x - l.x) * (n.y - l.y) - (n.x - l.x) * (h.y - l.y),
                    b = b + p * (l.x + h.x + n.x),
                    f = f + p * q,
                    c = c + p;
            d.x = b / (3 * c);
            d.y = f / (3 * c);
            d.ja = c / 2;
            return d;
        };
        this.te = function (e, d) {
            this.k(e, d);
            d.Qb = Math.sqrt(d.ja / Math.PI);
        };
        this.Wa = function (e, d) {
            for (var b = 0; b < e.length; b++) {
                var f = e[b],
                    k = e[b + 1] || e[0];
                if (0 > (d.y - f.y) * (k.x - f.x) - (d.x - f.x) * (k.y - f.y))
                    return !1;
            }
            return !0;
        };
        this.Mg = function (e, d, b) {
            var f = e.x,
                k = d.x;
            e.x > d.x && ((f = d.x), (k = e.x));
            k > b.x + b.f && (k = b.x + b.f);
            f < b.x && (f = b.x);
            if (f > k) return !1;
            var l = e.y,
                c = d.y,
                a = d.x - e.x;
            1e-7 < Math.abs(a) &&
                ((c = (d.y - e.y) / a),
                (e = e.y - c * e.x),
                (l = c * f + e),
                (c = c * k + e));
            l > c && ((f = c), (c = l), (l = f));
            c > b.y + b.i && (c = b.y + b.i);
            l < b.y && (l = b.y);
            return l <= c;
        };
        this.ue = function (g, d, b, f, k) {
            var l, c;
            function a(a, b, f) {
                if (d.x === n.x && d.y === n.y) return f;
                var k = m(g, d, n),
                    p = Math.sqrt(e(k, d) / (a * a + b * b));
                return p < h
                    ? ((h = p),
                      (l = k.x),
                      (c = k.y),
                      0 !== b
                          ? Math.abs(c - d.y) / Math.abs(b)
                          : Math.abs(l - d.x) / Math.abs(a))
                    : f;
            }
            f = D.B(f, 0.5);
            k = D.B(k, 0.5);
            b = D.B(b, 1);
            var h = Number.MAX_VALUE;
            c = l = 0;
            var n = { x: 0, y: 0 },
                q,
                p = f * b;
            b = (1 - f) * b;
            f = 1 - k;
            n.x = d.x - p;
            n.y = d.y - k;
            q = a(p, k, q);
            n.x = d.x + b;
            n.y = d.y - k;
            q = a(b, k, q);
            n.x = d.x - p;
            n.y = d.y + f;
            q = a(p, f, q);
            n.x = d.x + b;
            n.y = d.y + f;
            return (q = a(b, f, q));
        };
        this.Fg = function (e, d) {
            function b(a, b, c) {
                var f = b.x,
                    d = c.x;
                b = b.y;
                c = c.y;
                var e = d - f,
                    g = c - b;
                return (
                    Math.abs(g * a.x - e * a.y - f * c + d * b) /
                    Math.sqrt(e * e + g * g)
                );
            }
            for (
                var f = e.length, k = b(d, e[f - 1], e[0]), l = 0;
                l < f - 1;
                l++
            ) {
                var c = b(d, e[l], e[l + 1]);
                c < k && (k = c);
            }
            return k;
        };
        this.Yb = function (e, d, b) {
            var f;
            b = { x: d.x + Math.cos(b), y: d.y - Math.sin(b) };
            var k = [],
                l = [],
                c = e.length;
            for (f = 0; f < c; f++) {
                var a = M.Jg(e[f], e[(f + 1) % c], d, b);
                if (a && (k.push(a), 2 == l.push(f))) break;
            }
            if (2 == k.length) {
                var a = k[0],
                    k = k[1],
                    h = l[0],
                    l = l[1],
                    n = [k, a];
                for (f = h + 1; f <= l; f++) n.push(e[f]);
                for (f = [a, k]; l != h; ) (l = (l + 1) % c), f.push(e[l]);
                e = [n, f];
                c = b.x - d.x;
                f = k.x - a.x;
                0 === c && ((c = b.y - d.y), (f = k.y - a.y));
                (0 > c ? -1 : 0 < c ? 1 : 0) !== (0 > f ? -1 : 0 < f ? 1 : 0) &&
                    e.reverse();
                return e;
            }
        };
        this.za = function (e, d, b, f) {
            f.x = e * (d.x - b.x) + b.x;
            f.y = e * (d.y - b.y) + b.y;
            return f;
        };
        this.d = e;
        this.Dc = function (e, d, b) {
            if (D.Uc(d)) d = (2 * Math.PI * d) / 360;
            else {
                var f = M.q(e, {});
                switch (d) {
                    case 'random':
                        d = Math.random() * Math.PI * 2;
                        break;
                    case 'top':
                        d = Math.atan2(-f.i, 0);
                        break;
                    case 'bottom':
                        d = Math.atan2(f.i, 0);
                        break;
                    case 'topleft':
                        d = Math.atan2(-f.i, -f.f);
                        break;
                    default:
                        d = Math.atan2(f.i, f.f);
                }
            }
            f = M.k(e, {});
            return M.za(
                b,
                m(e, f, { x: f.x + Math.cos(d), y: f.y + Math.sin(d) }),
                f,
                {},
            );
        };
        return this;
    })();
    var ya = new (function () {
        function e(b, d) {
            this.face = b;
            this.md = d;
            this.rc = this.fd = null;
        }
        function m(b, d, e) {
            this.ma = [b, d, e];
            this.C = Array(3);
            var c = d.y - b.y,
                a = e.z - b.z,
                g = d.x - b.x;
            d = d.z - b.z;
            var n = e.x - b.x;
            b = e.y - b.y;
            this.Oa = { x: c * a - d * b, y: d * n - g * a, z: g * b - c * n };
            this.lb = [];
            this.wd = this.visible = !1;
        }
        this.S = function (f) {
            function d(a, b, f) {
                var g,
                    h,
                    k = a.ma[0],
                    l = a.Oa,
                    s = l.x,
                    r = l.y,
                    l = l.z,
                    p = Array(n);
                b = b.lb;
                g = b.length;
                for (c = 0; c < g; c++)
                    (h = b[c].md),
                        (p[h.index] = !0),
                        0 >
                            s * (h.x - k.x) +
                                r * (h.y - k.y) +
                                l * (h.z - k.z) && e.d(a, h);
                b = f.lb;
                g = b.length;
                for (c = 0; c < g; c++)
                    (h = b[c].md),
                        !0 !== p[h.index] &&
                            0 >
                                s * (h.x - k.x) +
                                    r * (h.y - k.y) +
                                    l * (h.z - k.z) &&
                            e.d(a, h);
            }
            var l,
                c,
                a,
                h,
                n = f.length;
            for (l = 0; l < n; l++) (f[l].index = l), (f[l].bc = null);
            var q = [],
                p;
            if (
                0 <
                (p = (function () {
                    function a(b, c, f, d) {
                        var e =
                                (c.y - b.y) * (f.z - b.z) -
                                (c.z - b.z) * (f.y - b.y),
                            g =
                                (c.z - b.z) * (f.x - b.x) -
                                (c.x - b.x) * (f.z - b.z),
                            h =
                                (c.x - b.x) * (f.y - b.y) -
                                (c.y - b.y) * (f.x - b.x);
                        return e * d.x + g * d.y + h * d.z >
                            e * b.x + g * b.y + h * b.z
                            ? new m(b, c, f)
                            : new m(f, c, b);
                    }
                    function b(a, c, f, d) {
                        function e(a, b, c) {
                            a = a.ma;
                            b = a[0] == b ? 0 : a[1] == b ? 1 : 2;
                            return a[(b + 1) % 3] != c ? (b + 2) % 3 : b;
                        }
                        c.C[e(c, f, d)] = a;
                        a.C[e(a, d, f)] = c;
                    }
                    if (4 > n) return 0;
                    var c = f[0],
                        d = f[1],
                        g = f[2],
                        h = f[3],
                        k = a(c, d, g, h),
                        l = a(c, g, h, d),
                        s = a(c, d, h, g),
                        r = a(d, g, h, c);
                    b(k, l, g, c);
                    b(k, s, c, d);
                    b(k, r, d, g);
                    b(l, s, h, c);
                    b(l, r, g, h);
                    b(s, r, h, d);
                    q.push(k, l, s, r);
                    for (c = 4; c < n; c++)
                        for (d = f[c], g = 0; 4 > g; g++)
                            (h = q[g]),
                                (k = h.ma[0]),
                                (l = h.Oa),
                                0 >
                                    l.x * (d.x - k.x) +
                                        l.y * (d.y - k.y) +
                                        l.z * (d.z - k.z) && e.d(h, d);
                    return 4;
                })())
            ) {
                for (; p < n; ) {
                    a = f[p];
                    if (a.bc) {
                        for (l = a.bc; null !== l; )
                            (l.face.visible = !0), (l = l.rc);
                        var r, s;
                        l = 0;
                        a: for (; l < q.length; l++)
                            if (((h = q[l]), !1 === h.visible)) {
                                var w = h.C;
                                for (c = 0; 3 > c; c++)
                                    if (!0 === w[c].visible) {
                                        r = h;
                                        s = c;
                                        break a;
                                    }
                            }
                        h = [];
                        var w = [],
                            u = r,
                            y = s;
                        do
                            if (
                                (h.push(u),
                                w.push(y),
                                (y = (y + 1) % 3),
                                !1 === u.C[y].visible)
                            ) {
                                do
                                    for (
                                        l = u.ma[y], u = u.C[y], c = 0;
                                        3 > c;
                                        c++
                                    )
                                        u.ma[c] == l && (y = c);
                                while (
                                    !1 === u.C[y].visible &&
                                    (u !== r || y !== s)
                                );
                            }
                        while (u !== r || y !== s);
                        var x = null,
                            A = null;
                        for (l = 0; l < h.length; l++) {
                            var u = h[l],
                                y = w[l],
                                B = u.C[y],
                                K = u.ma[(y + 1) % 3],
                                C = u.ma[y],
                                H = K.y - a.y,
                                Q = C.z - a.z,
                                O = K.x - a.x,
                                P = K.z - a.z,
                                F = C.x - a.x,
                                T = C.y - a.y,
                                N;
                            0 < b.length
                                ? ((N = b.pop()),
                                  (N.ma[0] = a),
                                  (N.ma[1] = K),
                                  (N.ma[2] = C),
                                  (N.Oa.x = H * Q - P * T),
                                  (N.Oa.y = P * F - O * Q),
                                  (N.Oa.z = O * T - H * F),
                                  (N.lb.length = 0),
                                  (N.visible = !1),
                                  (N.wd = !0))
                                : (N = {
                                      ma: [a, K, C],
                                      C: Array(3),
                                      Oa: {
                                          x: H * Q - P * T,
                                          y: P * F - O * Q,
                                          z: O * T - H * F,
                                      },
                                      lb: [],
                                      visible: !1,
                                  });
                            q.push(N);
                            u.C[y] = N;
                            N.C[1] = u;
                            null !== A && ((A.C[0] = N), (N.C[2] = A));
                            A = N;
                            null === x && (x = N);
                            d(N, u, B);
                        }
                        A.C[0] = x;
                        x.C[2] = A;
                        l = [];
                        for (c = 0; c < q.length; c++)
                            if (((h = q[c]), !0 === h.visible)) {
                                w = h.lb;
                                u = w.length;
                                for (a = 0; a < u; a++)
                                    (y = w[a]),
                                        (x = y.fd),
                                        (A = y.rc),
                                        null !== x && (x.rc = A),
                                        null !== A && (A.fd = x),
                                        null === x && (y.md.bc = A),
                                        g.push(y);
                                h.wd && b.push(h);
                            } else l.push(h);
                        q = l;
                    }
                    p++;
                }
                for (l = 0; l < q.length; l++) (h = q[l]), h.wd && b.push(h);
            }
            return { Me: q };
        };
        e.d = function (b, d) {
            var l;
            0 < g.length
                ? ((l = g.pop()),
                  (l.face = b),
                  (l.md = d),
                  (l.rc = null),
                  (l.fd = null))
                : (l = new e(b, d));
            b.lb.push(l);
            var c = d.bc;
            null !== c && (c.fd = l);
            l.rc = c;
            d.bc = l;
        };
        for (var g = Array(2e3), d = 0; d < g.length; d++)
            g[d] = new e(null, null);
        for (var b = Array(1e3), d = 0; d < b.length; d++)
            b[d] = {
                ma: Array(3),
                C: Array(3),
                Oa: { x: 0, y: 0, z: 0 },
                lb: [],
                visible: !1,
            };
    })();
    var za = new (function () {
        function e(e, d, b, f, k, l, c, a) {
            var h = (e - b) * (l - a) - (d - f) * (k - c);
            return Math.abs(h) < m
                ? void 0
                : {
                      x:
                          ((e * f - d * b) * (k - c) -
                              (e - b) * (k * a - l * c)) /
                          h,
                      y:
                          ((e * f - d * b) * (l - a) -
                              (d - f) * (k * a - l * c)) /
                          h,
                  };
        }
        var m = 1e-12;
        this.eb = function (g, d) {
            for (
                var b = g[0],
                    f = b.x,
                    k = b.y,
                    l = b.x,
                    c = b.y,
                    a = g.length - 1;
                0 < a;
                a--
            )
                (b = g[a]),
                    (f = Math.min(f, b.x)),
                    (k = Math.min(k, b.y)),
                    (l = Math.max(l, b.x)),
                    (c = Math.max(c, b.y));
            if (l - f < 3 * d || c - k < 3 * d) b = void 0;
            else {
                a: {
                    b = !0;
                    void 0 == b && (b = !1);
                    f = [];
                    k = g.length;
                    for (l = 0; l <= k; l++) {
                        var c = g[l % k],
                            a = g[(l + 1) % k],
                            h = g[(l + 2) % k],
                            n,
                            q,
                            p;
                        n = a.x - c.x;
                        q = a.y - c.y;
                        p = Math.sqrt(n * n + q * q);
                        var r = (d * n) / p,
                            s = (d * q) / p;
                        n = h.x - a.x;
                        q = h.y - a.y;
                        p = Math.sqrt(n * n + q * q);
                        n = (d * n) / p;
                        q = (d * q) / p;
                        if (
                            (c = e(
                                c.x - s,
                                c.y + r,
                                a.x - s,
                                a.y + r,
                                a.x - q,
                                a.y + n,
                                h.x - q,
                                h.y + n,
                            ))
                        )
                            if (
                                (f.push(c),
                                (h = f.length),
                                b &&
                                    3 <= h &&
                                    ((c = f[h - 3]),
                                    (a = f[h - 2]),
                                    (h = f[h - 1]),
                                    0 >
                                        (a.x - c.x) * (h.y - c.y) -
                                            (h.x - c.x) * (a.y - c.y)))
                            ) {
                                b = void 0;
                                break a;
                            }
                    }
                    f.shift();
                    b = 3 > f.length ? void 0 : f;
                }
                if (!b)
                    a: {
                        f = g.slice(0);
                        for (b = 0; b < g.length; b++) {
                            l = g[b % g.length];
                            a = g[(b + 1) % g.length];
                            h = a.x - l.x;
                            k = a.y - l.y;
                            c = Math.sqrt(h * h + k * k);
                            h = (d * h) / c;
                            c = (d * k) / c;
                            k = l.x - c;
                            l = l.y + h;
                            c = a.x - c;
                            a = a.y + h;
                            if (0 != f.length) {
                                s = k - c;
                                q = l - a;
                                h = [];
                                n = p = !0;
                                r = void 0;
                                for (r = 0; r < f.length; r++) {
                                    var w = s * (l - f[r].y) - (k - f[r].x) * q;
                                    w <= m && w >= -m && (w = 0);
                                    h.push(w);
                                    0 < w && (p = !1);
                                    0 > w && (n = !1);
                                }
                                if (p) f = [];
                                else if (!n) {
                                    s = [];
                                    for (r = 0; r < f.length; r++)
                                        (q = (r + 1) % f.length),
                                            (p = h[r]),
                                            (n = h[q]),
                                            0 <= p && s.push(f[r]),
                                            ((0 < p && 0 > n) ||
                                                (0 > p && 0 < n)) &&
                                                s.push(
                                                    e(
                                                        f[r].x,
                                                        f[r].y,
                                                        f[q].x,
                                                        f[q].y,
                                                        k,
                                                        l,
                                                        c,
                                                        a,
                                                    ),
                                                );
                                    f = s;
                                }
                            }
                            if (3 > f.length) {
                                b = void 0;
                                break a;
                            }
                        }
                        b = f;
                    }
            }
            return b;
        };
        return this;
    })();
    var Aa = new (function () {
        function e(e) {
            for (
                var d = e[0].x, b = e[0].y, f = d, k = b, l = 1;
                l < e.length;
                l++
            )
                var c = e[l],
                    d = Math.min(d, c.x),
                    b = Math.min(b, c.y),
                    f = Math.max(f, c.x),
                    k = Math.max(k, c.y);
            e = f - d;
            k = k - b;
            return [
                { x: d + 2 * e, y: b + 2 * k, f: 0 },
                { x: d + 2 * e, y: b - 2 * k, f: 0 },
                { x: d - 2 * e, y: b + 2 * k, f: 0 },
            ];
        }
        var m = 1e-12;
        this.S = function (g, d) {
            function b() {
                for (a = 0; a < p.length; a++) {
                    var b = p[a],
                        c = b.ma,
                        f = c[0],
                        d = c[1],
                        e = c[2],
                        c = f.x,
                        g = f.y,
                        f = f.z,
                        h = d.x,
                        k = d.y,
                        d = d.z,
                        l = e.x,
                        n = e.y,
                        e = e.z,
                        s = c * (k - n) + h * (n - g) + l * (g - k);
                    b.ha = {
                        x: -(g * (d - e) + k * (e - f) + n * (f - d)) / s / 2,
                        y: -(f * (h - l) + d * (l - c) + e * (c - h)) / s / 2,
                    };
                }
            }
            function f(b) {
                for (a = 0; a < p.length; a++) {
                    var c = p[a];
                    c.vb = !M.Wa(b, c.ha);
                }
            }
            function k(a, b) {
                var c = Array(b.length),
                    f;
                for (f = 0; f < c.length; f++) c[f] = [];
                for (f = 0; f < a.length; f++) {
                    var d = a[f];
                    if (!(0 > d.Oa.z))
                        for (var e = d.C, g = 0; g < e.length; g++) {
                            var h = e[g];
                            if (!(0 > h.Oa.z)) {
                                var k = d.ma,
                                    l = k[(g + 1) % 3].index,
                                    k = k[g].index;
                                2 < l &&
                                    c[l - 3].push([
                                        d,
                                        h,
                                        2 < k ? b[k - 3] : null,
                                    ]);
                            }
                        }
                }
                return c;
            }
            function l(a) {
                var b = [a[0]],
                    c = a[0][0],
                    f = a[0][1],
                    d = a.length,
                    e = 1;
                a: for (; e < d; e++)
                    for (var g = 1; g < d; g++) {
                        var h = a[g];
                        if (null !== h) {
                            if (h[1] === c)
                                if (
                                    (b.unshift(h),
                                    (c = h[0]),
                                    (a[g] = null),
                                    b.length === d)
                                )
                                    break a;
                                else continue;
                            if (
                                h[0] === f &&
                                (b.push(h),
                                (f = h[1]),
                                (a[g] = null),
                                b.length === d)
                            )
                                break a;
                        }
                    }
                b[0][0] != b[d - 1][1] && b.push([b[d - 1][1], b[0][0]]);
                return b;
            }
            function c(a, b, c, f) {
                var d = [],
                    e = [],
                    g = c.length,
                    h,
                    k = b.length,
                    l = 0,
                    n = -1,
                    s = -1,
                    r = -1,
                    p = null,
                    q = f;
                for (f = 0; f < g; f++) {
                    var w = (q + 1) % g,
                        u = c[q][0],
                        E = c[w][0];
                    if (M.d(u.ha, E.ha) > m)
                        if (u.vb && E.vb) {
                            var I = [],
                                J = [];
                            for (h = 0; h < k; h++) {
                                n = (l + 1) % k;
                                if ((p = M.ya(b[l], b[n], u.ha, E.ha, !1)))
                                    if ((J.push(l), 2 === I.push(p))) break;
                                l = n;
                            }
                            if (2 === I.length) {
                                n = I[1];
                                p = M.d(u.ha, I[0]);
                                n = M.d(u.ha, n);
                                u = p < n ? 0 : 1;
                                p = p < n ? 1 : 0;
                                n = J[u];
                                -1 === s && (s = n);
                                if (-1 !== r)
                                    for (; n != r; )
                                        (r = (r + 1) % k),
                                            d.push(b[r]),
                                            e.push(null);
                                d.push(I[u], I[p]);
                                e.push(c[q][2], null);
                                r = J[p];
                            }
                        } else if (u.vb && !E.vb)
                            for (h = 0; h < k; h++) {
                                n = (l + 1) % k;
                                if ((p = M.ya(b[l], b[n], u.ha, E.ha, !1))) {
                                    if (-1 !== r)
                                        for (I = r; l != I; )
                                            (I = (I + 1) % k),
                                                d.push(b[I]),
                                                e.push(null);
                                    d.push(p);
                                    e.push(c[q][2]);
                                    -1 === s && (s = l);
                                    break;
                                }
                                l = n;
                            }
                        else if (!u.vb && E.vb)
                            for (h = 0; h < k; h++) {
                                n = (l + 1) % k;
                                if ((p = M.ya(b[l], b[n], u.ha, E.ha, !1))) {
                                    d.push(u.ha, p);
                                    e.push(c[q][2], null);
                                    r = l;
                                    break;
                                }
                                l = n;
                            }
                        else d.push(u.ha), e.push(c[q][2]);
                    q = w;
                }
                if (0 == d.length) e = d = null;
                else if (-1 !== r)
                    for (; s != r; )
                        (r = (r + 1) % k), d.push(b[r]), e.push(null);
                a.o = d;
                a.C = e;
            }
            if (1 === g.length) (g[0].o = d.slice(0)), (g[0].C = []);
            else {
                var a, h;
                h = e(d);
                var n = [],
                    q;
                for (a = 0; a < h.length; a++)
                    (q = h[a]),
                        n.push({
                            x: q.x,
                            y: q.y,
                            z: q.x * q.x + q.y * q.y - q.f,
                        });
                for (a = 0; a < g.length; a++)
                    (q = g[a]),
                        (q.o = null),
                        n.push({
                            x: q.x,
                            y: q.y,
                            z: q.x * q.x + q.y * q.y - q.f,
                        });
                var p = ya.S(n).Me;
                b();
                f(d);
                n = k(p, g);
                for (a = 0; a < g.length; a++)
                    if (((q = n[a]), 0 !== q.length)) {
                        var r = g[a];
                        q = l(q);
                        var s = q.length,
                            w = -1;
                        for (h = 0; h < s; h++) q[h][0].vb && (w = h);
                        if (0 <= w) c(r, d, q, w);
                        else {
                            var w = [],
                                u = [];
                            for (h = 0; h < s; h++)
                                M.d(q[h][0].ha, q[(h + 1) % s][0].ha) > m &&
                                    (w.push(q[h][0].ha), u.push(q[h][2]));
                            r.o = w;
                            r.C = u;
                        }
                        r.o && 3 > r.o.length && ((r.o = null), (r.C = null));
                    }
            }
        };
        this.Ac = function (g, d) {
            var b,
                f,
                k = !1,
                l = g.length;
            for (f = 0; f < l; f++)
                (b = g[f]), null === b.o && (k = !0), (b.se = b.f);
            if (k) {
                var k = e(d),
                    c = [],
                    a,
                    h;
                f = g.length;
                for (b = 0; b < k.length; b++)
                    (a = k[b]),
                        c.push({ x: a.x, y: a.y, z: a.x * a.x + a.y * a.y });
                for (b = 0; b < f; b++)
                    (a = g[b]),
                        c.push({ x: a.x, y: a.y, z: a.x * a.x + a.y * a.y });
                a = ya.S(c).Me;
                k = Array(f);
                for (b = 0; b < f; b++) k[b] = {};
                c = a.length;
                for (b = 0; b < c; b++)
                    if (((h = a[b]), 0 < h.Oa.z)) {
                        var n = h.ma,
                            q = n.length;
                        for (h = 0; h < q - 1; h++) {
                            var p = n[h].index - 3,
                                r = n[h + 1].index - 3;
                            0 <= p &&
                                0 <= r &&
                                ((k[p][r] = !0), (k[r][p] = !0));
                        }
                        h = n[0].index - 3;
                        0 <= r && 0 <= h && ((k[r][h] = !0), (k[h][r] = !0));
                    }
                for (b = 0; b < f; b++) {
                    h = k[b];
                    a = g[b];
                    var r = Number.MAX_VALUE,
                        c = null,
                        s;
                    for (s in h)
                        (h = g[s]),
                            (n = M.d(a, h)),
                            r > n && ((r = n), (c = h));
                    a.Sj = c;
                    a.xf = Math.sqrt(r);
                }
                for (f = 0; f < l; f++)
                    (b = g[f]),
                        (s = Math.min(Math.sqrt(b.f), 0.95 * b.xf)),
                        (b.f = s * s);
                this.S(g, d);
                for (f = 0; f < l; f++)
                    (b = g[f]),
                        b.se !== b.f &&
                            0 < b.wc &&
                            ((s = Math.min(b.wc, b.se - b.f)),
                            (b.f += s),
                            (b.wc -= s));
            }
        };
    })();
    var Ba = new (function () {
        this.Eg = function (e) {
            e = e.e;
            for (var m = 0, g = e.length, d = 0; d < g; d++) {
                var b = e[d];
                if (b.o) {
                    var f = b.x,
                        k = b.y;
                    M.k(b.o, b);
                    f = f - b.x;
                    b = k - b.y;
                    b = (0 < f ? f : -f) + (0 < b ? b : -b);
                    m < b && (m = b);
                }
            }
            return m;
        };
        this.xa = function (e, m) {
            var g = e.e,
                d,
                b,
                f,
                k;
            switch (m) {
                case 'random':
                    return e.e[Math.floor(g.length * Math.random())];
                case 'topleft':
                    d = g[0];
                    var l = d.x + d.y;
                    for (k = 1; k < g.length; k++)
                        (b = g[k]),
                            (f = b.x + b.y),
                            f < l && ((l = f), (d = b));
                    return d;
                case 'bottomright':
                    d = g[0];
                    l = d.x + d.y;
                    for (k = 1; k < g.length; k++)
                        (b = g[k]),
                            (f = b.x + b.y),
                            f > l && ((l = f), (d = b));
                    return d;
                default:
                    d = g[0];
                    f = b = M.d(e, d);
                    for (k = g.length - 1; 1 <= k; k--)
                        (l = g[k]),
                            (b = M.d(e, l)),
                            b < f && ((f = b), (d = l));
                    return d;
            }
        };
        this.Ia = function (e, m, g) {
            var d = e.e;
            if (d[0].C) {
                var b = d.length;
                for (e = 0; e < b; e++) (d[e].nd = !1), (d[e].kc = 0);
                var b = [],
                    f,
                    k;
                k = f = 0;
                b[f++] = m || d[0];
                for (m = m.kc = 0; k < f; )
                    if (((d = b[k++]), !d.nd && d.C)) {
                        g(d, m++, d.kc);
                        d.nd = !0;
                        var l = d.C,
                            c = l.length;
                        for (e = 0; e < c; e++) {
                            var a = l[e];
                            a &&
                                !0 !== a.nd &&
                                (0 === a.kc && (a.kc = d.kc + 1), (b[f++] = a));
                        }
                    }
            } else for (e = 0; e < d.length; e++) g(d[e], e, 1);
        };
    })();
    var G = (function () {
        function e(c, e, h, r, s, p, w, P) {
            var F = D.extend({}, l, c);
            1 > c.lineHeight && (c.lineHeight = 1);
            c = F.fontFamily;
            var T = F.fontStyle + ' ' + F.fontVariant + ' ' + F.fontWeight,
                N = F.tb,
                U = F.ad,
                t = T + ' ' + c;
            F.Qe = t;
            var z = { la: !1, oc: 0, fontSize: 0 };
            e.save();
            e.font = T + ' ' + x + 'px ' + c;
            e.textBaseline = 'middle';
            e.textAlign = 'center';
            m(e, F);
            h = h.trim();
            u.text = h;
            b(r, s, p, y);
            if (
                /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(
                    h,
                )
            )
                d(u), g(e, u, t), f(F, u, y, U, N, !0, z);
            else if (
                (g(e, u, t),
                f(F, u, y, U, N, !1, z),
                !z.la && (w && (d(u), g(e, u, t)), P || w))
            )
                P && (z.gc = !0), f(F, u, y, U, U, !0, z);
            if (z.la) {
                var L = '',
                    E = 0,
                    I = Number.MAX_VALUE,
                    J = Number.MIN_VALUE;
                k(
                    F,
                    u,
                    z.oc,
                    z.fontSize,
                    y,
                    z.gc,
                    function (b, c) {
                        0 < L.length && c === a && (L += a);
                        L += b;
                    },
                    function (a, b, c, d, f) {
                        d === q && (L += n);
                        e.save();
                        e.translate(p.x, b);
                        a = z.fontSize / x;
                        e.scale(a, a);
                        e.fillText(L, 0, 0);
                        e.restore();
                        L = c;
                        E < f && (E = f);
                        I > b && (I = b);
                        J < b && (J = b);
                    },
                );
                z.da = {
                    x: p.x - E / 2,
                    y: I - z.fontSize / 2,
                    f: E,
                    i: J - I + z.fontSize,
                };
                e.restore();
            } else e.clear && e.clear();
            return z;
        }
        function m(b, d) {
            var f = d.Qe,
                e = c[f];
            void 0 === e && ((e = {}), (c[f] = e));
            e[a] = b.measureText(a).width;
            e[h] = b.measureText(h).width;
        }
        function g(a, b, d) {
            var f,
                e = b.text.split(
                    /(\n|[ \f\r\t\v\u2028\u2029]+|\u00ad+|\u200b+)/,
                ),
                g = [],
                h = [],
                k = e.length >>> 1;
            for (f = 0; f < k; f++) g.push(e[2 * f]), h.push(e[2 * f + 1]);
            2 * f < e.length && (g.push(e[2 * f]), h.push(void 0));
            d = c[d];
            for (f = 0; f < g.length; f++)
                (e = g[f]),
                    (k = d[e]),
                    void 0 === k && ((k = a.measureText(e).width), (d[e] = k));
            b.od = g;
            b.mg = h;
        }
        function d(b) {
            for (
                var c = b.text.split(/\s+/),
                    f = [],
                    d = {
                        '.': !0,
                        ',': !0,
                        ';': !0,
                        '?': !0,
                        '!': !0,
                        ':': !0,
                        '\u3002': !0,
                    },
                    e = 0;
                e < c.length;
                e++
            ) {
                var g = c[e];
                if (3 < g.length) {
                    for (
                        var h = '',
                            h = h + g.charAt(0),
                            h = h + g.charAt(1),
                            k = 2;
                        k < g.length - 2;
                        k++
                    ) {
                        var l = g.charAt(k);
                        d[l] || (h += p);
                        h += l;
                    }
                    h += p;
                    h += g.charAt(g.length - 2);
                    h += g.charAt(g.length - 1);
                    f.push(h);
                } else f.push(g);
            }
            b.text = f.join(a);
        }
        function b(a, b, c, f) {
            for (var d, e, g = 0; g < a.length; g++)
                a[g].y === b.y && (void 0 === d ? (d = g) : (e = g));
            void 0 === e && (e = d);
            d !== e && a[e].x < a[d].x && ((g = d), (d = e), (e = g));
            f.o = a;
            f.q = b;
            f.yd = c;
            f.vf = d;
            f.wf = e;
        }
        function f(a, b, c, f, d, e, g) {
            var h = a.lineHeight,
                l = Math.max(a.fb, 0.001),
                n = a.ub,
                r = b.od,
                s = c.yd,
                p = c.q,
                q = void 0,
                m = void 0;
            switch (a.verticalAlign) {
                case 'top':
                    s = p.y + p.i - s.y;
                    break;
                case 'bottom':
                    s = s.y - p.y;
                    break;
                default:
                    s = 2 * Math.min(s.y - p.y, p.y + p.i - s.y);
            }
            n = Math.min(s, n * c.q.i);
            if (0 >= n) g.la = !1;
            else {
                s = f;
                d = Math.min(d, n);
                p = Math.min(1, n / Math.max(20, b.od.length));
                do {
                    var w = (s + d) / 2,
                        u = Math.min(
                            r.length,
                            Math.floor((n + w * (h - 1 - 2 * l)) / (w * h)),
                        ),
                        y = void 0;
                    if (0 < u) {
                        var x = 1,
                            Y = u;
                        do {
                            var Z = Math.floor((x + Y) / 2);
                            if (
                                k(
                                    a,
                                    b,
                                    Z,
                                    w,
                                    c,
                                    e && w === f && Z === u,
                                    null,
                                    null,
                                )
                            ) {
                                if (((Y = q = y = Z), x === Y)) break;
                            } else if (((x = Z + 1), x > Y)) break;
                        } while (1);
                    }
                    void 0 !== y ? (s = m = w) : (d = w);
                } while (d - s > p);
                void 0 === m
                    ? ((g.la = !1), (g.fontSize = 0))
                    : ((g.la = !0),
                      (g.fontSize = m),
                      (g.oc = q),
                      (g.gc = e && w === s));
            }
        }
        function k(b, f, d, e, g, k, l, n) {
            var p = b.qb,
                q = e * (b.lineHeight - 1),
                m = b.verticalAlign,
                u = Math.max(b.fb, 0.001);
            b = c[b.Qe];
            var y = f.od;
            f = f.mg;
            var z = g.o,
                L = g.yd,
                E = g.vf,
                I = g.wf,
                J;
            switch (m) {
                case 'top':
                    g = L.y + e / 2 + e * u;
                    J = 1;
                    break;
                case 'bottom':
                    g = L.y - (e * d + q * (d - 1)) + e / 2 - e * u;
                    J = -1;
                    break;
                default:
                    (g = L.y - ((e * (d - 1)) / 2 + (q * (d - 1)) / 2)),
                        (J = 1);
            }
            m = g;
            for (u = 0; u < d; u++)
                (r[2 * u] = g - e / 2),
                    (r[2 * u + 1] = g + e / 2),
                    (g += J * e),
                    (g += J * q);
            for (; s.length < r.length; ) s.push(Array(2));
            u = r;
            g = 2 * d;
            J = s;
            for (
                var R = z.length,
                    Y = E,
                    E = (E - 1 + R) % R,
                    Z = I,
                    I = (I + 1) % R,
                    W = 0;
                W < g;

            ) {
                for (var ea = u[W], ba = z[E]; ba.y < ea; )
                    (Y = E), (E = (E - 1 + R) % R), (ba = z[E]);
                for (var ca = z[I]; ca.y < ea; )
                    (Z = I), (I = (I + 1) % R), (ca = z[I]);
                var ma = z[Y],
                    na = z[Z],
                    ca = na.x + ((ca.x - na.x) * (ea - na.y)) / (ca.y - na.y);
                J[W][0] = ma.x + ((ba.x - ma.x) * (ea - ma.y)) / (ba.y - ma.y);
                J[W][1] = ca;
                W++;
            }
            for (u = 0; u < d; u++)
                (z = 2 * u),
                    (g = L.x),
                    (J = g - s[z][0]),
                    (R = s[z][1] - g),
                    (J = J < R ? J : R),
                    (R = g - s[z + 1][0]),
                    (z = s[z + 1][1] - g),
                    (z = R < z ? R : z),
                    (w[u] = 2 * (J < z ? J : z) - p * e);
            Y = (b[a] * e) / x;
            J = (b[h] * e) / x;
            p = 0;
            E = w[p];
            L = 0;
            z = void 0;
            for (u = 0; u < y.length; u++) {
                g = y[u];
                Z = f[u];
                R = (b[g] * e) / x;
                if (L + R < E && y.length - u >= d - p && '\n' != z)
                    (L += R), ' ' === Z && (L += Y), l && l(g, z);
                else {
                    if (R > E && (p !== d - 1 || !k)) return !1;
                    if (p + 1 >= d) {
                        if (k) {
                            d = E - L - J;
                            if (d > J || R > J)
                                (d = Math.floor((g.length * d) / R)),
                                    0 < d && l && l(g.substring(0, d), z);
                            l && l(h, void 0);
                            n && n(p, m, g, z, L);
                            return !0;
                        }
                        return !1;
                    }
                    p++;
                    n && n(p, m, g, z, L);
                    m += e;
                    m += q;
                    E = w[p];
                    L = R;
                    ' ' === Z && (L += Y);
                    if (R > E && (p !== d || !k)) return !1;
                }
                z = Z;
            }
            n && n(p, m, void 0, void 0, L);
            return !0;
        }
        var l = {
                tb: 72,
                ad: 0,
                lineHeight: 1.05,
                qb: 1,
                fb: 0.5,
                ub: 0.9,
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontVariant: 'normal',
                verticalAlign: 'center',
            },
            c = {},
            a = ' ',
            h = '\u2026',
            n = '\u2010',
            q = '\u00ad',
            p = '\u200b',
            r = [],
            s = [],
            w = [],
            u = { text: '', od: void 0, mg: void 0 },
            y = { o: void 0, q: void 0, yd: void 0, vf: 0, wf: 0 },
            x = 100;
        return {
            Oe: e,
            Ae: function (a, b, c, d, f, g, h, k, l, n, s, r) {
                var p,
                    q = 0,
                    m = 0;
                c = c.toString().trim();
                !r &&
                    l.result &&
                    c === l.tg &&
                    Math.abs(n - l.we) / n <= s &&
                    ((p = l.result),
                    p.la &&
                        ((q = g.x - l.Ag),
                        (m = g.y - l.Bg),
                        (s = l.ld),
                        b.save(),
                        b.translate(q, m),
                        s.Ua(b),
                        b.restore()));
                p ||
                    ((s = l.ld),
                    s.clear(),
                    (p = e(a, s, c, d, f, g, h, k)),
                    p.la && s.Ua(b),
                    (l.we = n),
                    (l.Ag = g.x),
                    (l.Bg = g.y),
                    (l.result = p),
                    (l.tg = c));
                return p.la
                    ? {
                          la: !0,
                          oc: p.oc,
                          fontSize: p.fontSize,
                          da: {
                              x: p.da.x + q,
                              y: p.da.y + m,
                              f: p.da.f,
                              i: p.da.i,
                          },
                          gc: p.gc,
                      }
                    : { la: !1 };
            },
            Bi: function () {
                return {
                    we: 0,
                    Ag: 0,
                    Bg: 0,
                    result: void 0,
                    ld: new ga(),
                    tg: void 0,
                };
            },
            Da: l,
        };
    })();
    var Ca = new (function () {
        function e(d, b) {
            return function (f, e, l, c) {
                function a(c, d, f, e, k) {
                    if (0 != c.length) {
                        var n = c.shift(),
                            s = g(n),
                            p,
                            r,
                            q,
                            m;
                        if (b(e, k)) {
                            p = d;
                            q = s / e;
                            do {
                                s = n.shift();
                                r = s.xc;
                                m = r / q;
                                r = s;
                                var P = f,
                                    F = q;
                                r.x = p + m / 2;
                                r.y = P + F / 2;
                                l && h(s, p, f, m, q);
                                p += m;
                            } while (0 < n.length);
                            return a(c, d, f + q, e, k - q);
                        }
                        p = f;
                        m = s / k;
                        do
                            (s = n.shift()),
                                (r = s.xc),
                                (q = r / m),
                                (r = s),
                                (P = p),
                                (F = q),
                                (r.x = d + m / 2),
                                (r.y = P + F / 2),
                                l && h(s, d, p, m, q),
                                (p += q);
                        while (0 < n.length);
                        return a(c, d + m, f, e - m, k);
                    }
                }
                function h(a, b, c, d, f) {
                    a.o = [
                        { x: b, y: c },
                        { x: b + d, y: c },
                        { x: b + d, y: c + f },
                        { x: b, y: c + f },
                    ];
                }
                var n = e.x,
                    q = e.y,
                    p = e.f;
                e = e.i;
                if (0 != f.length)
                    if (1 == f.length)
                        (f[0].x = n + p / 2),
                            (f[0].y = q + e / 2),
                            (f[0].Hd = 0),
                            l && h(f[0], n, q, p, e);
                    else {
                        f = f.slice(0);
                        for (var r = 0, s = 0; s < f.length; s++) r += f[s].T;
                        r = (p * e) / r;
                        for (s = 0; s < f.length; s++) f[s].xc = f[s].T * r;
                        c = d(f, p, e, [[f.shift()]], c);
                        a(c, n, q, p, e);
                    }
            };
        }
        function m(d, b, f, e) {
            function l(b) {
                return Math.max(
                    Math.pow((h * b) / a, f),
                    Math.pow(a / (h * b), e),
                );
            }
            var c = g(d),
                a = c * c,
                h = b * b;
            b = l(d[0].xc);
            for (c = 1; c < d.length; c++) b = Math.max(b, l(d[c].xc));
            return b;
        }
        function g(d) {
            for (var b = 0, f = 0; f < d.length; f++) b += d[f].xc;
            return b;
        }
        this.ve = e(
            function (d, b, f, e, l) {
                l = Math.pow(2, l);
                for (var c = 1 / l, a = b < f; 0 < d.length; ) {
                    var h = e[e.length - 1],
                        n = d.shift(),
                        q = a ? b : f,
                        p = a ? l : c,
                        r = a ? c : l,
                        s = m(h, q, p, r);
                    h.push(n);
                    q = m(h, q, p, r);
                    s < q &&
                        (h.pop(),
                        e.push([n]),
                        a ? (f -= g(h) / b) : (b -= g(h) / f),
                        (a = b < f));
                }
                return e;
            },
            function (d, b) {
                return d < b;
            },
        );
        this.Zb = e(
            function (d, b, f, e, g) {
                function c(c) {
                    if (1 < e.length) {
                        for (
                            var d = e[e.length - 1],
                                f = e[e.length - 2].slice(0),
                                g = 0;
                            g < d.length;
                            g++
                        )
                            f.push(d[g]);
                        m(f, b, a, h) < c && e.splice(-2, 2, f);
                    }
                }
                for (var a = Math.pow(2, g), h = 1 / a; 0 < d.length; ) {
                    f = e[e.length - 1];
                    g = m(f, b, a, h);
                    if (0 == d.length) return;
                    var n = d.shift();
                    f.push(n);
                    var q = m(f, b, a, h);
                    g < q && (f.pop(), c(g), e.push([n]));
                }
                c(m(e[e.length - 1], b, a, h));
                return e;
            },
            function () {
                return !0;
            },
        );
    })();
    function Da(e) {
        var m = {},
            g = e.Xd,
            d;
        e.c.j('model:loaded', function (b) {
            d = b;
        });
        this.H = function () {
            e.c.p('api:initialized', this);
        };
        this.Fc = function (b, d, e, l) {
            this.rd(m, d);
            this.sd(m, d);
            this.qd(m, d, !1);
            l && l(m);
            b(g, m, e);
        };
        this.xd = function (b, f, e, g, c, a, h) {
            if (b) {
                for (b = f.length - 1; 0 <= b; b--) {
                    var n = f[b],
                        q = D.extend({ group: n.group }, c);
                    q[e] = g(n);
                    a(q);
                }
                0 < f.length &&
                    h(
                        D.extend(
                            {
                                groups: $.Oc(d, g).map(function (a) {
                                    return a.group;
                                }),
                            },
                            c,
                        ),
                    );
            }
        };
        this.sd = function (b, d) {
            b.selected = d.selected;
            b.hovered = d.Gb;
            b.open = d.open;
            b.openness = d.Nb;
            b.exposed = d.U;
            b.exposure = d.ka;
            b.transitionProgress = d.ta;
            b.revealed = !d.ba.Na();
            b.browseable = d.Qa ? d.N : void 0;
            b.visible = d.ea;
            b.labelDrawn = d.qa && d.qa.la;
            return b;
        };
        this.rd = function (b, d) {
            var e = d.parent;
            b.group = d.group;
            b.parent = e && e.group;
            b.weightNormalized = d.yg;
            b.level = d.R - 1;
            b.siblingCount = e && e.e.length;
            b.hasChildren = !d.empty();
            b.index = d.index;
            b.indexByWeight = d.Hd;
            b.description = d.description;
            b.attribution = d.Ra;
            return b;
        };
        this.qd = function (b, d, e) {
            b.polygonCenterX = d.K.x;
            b.polygonCenterY = d.K.y;
            b.polygonArea = d.K.ja;
            b.boxLeft = d.q.x;
            b.boxTop = d.q.y;
            b.boxWidth = d.q.f;
            b.boxHeight = d.q.i;
            if (d.qa && d.qa.la) {
                var g = d.qa.da;
                b.labelBoxLeft = g.x;
                b.labelBoxTop = g.y;
                b.labelBoxWidth = g.f;
                b.labelBoxHeight = g.i;
                b.labelFontSize = d.qa.fontSize;
            }
            e &&
                d.aa &&
                ((b.polygon = d.aa.map(function (b) {
                    return { x: b.x, y: b.y };
                })),
                (b.neighbors =
                    d.C &&
                    d.C.map(function (b) {
                        return b && b.group;
                    })));
            return b;
        };
    }
    var la = new (function () {
        var e = window.console;
        this.Pa = function (e) {
            throw 'FoamTree: ' + e;
        };
        this.info = function (m) {
            e.info('FoamTree: ' + m);
        };
        this.warn = function (m) {
            e.warn('FoamTree: ' + m);
        };
    })();
    function Ea(e) {
        function m(a, c) {
            a.e = [];
            a.Ka = !0;
            var d = b(c),
                f = 0;
            if ('flattened' == e.Va && 0 < c.length && 0 < a.R) {
                var h = c.reduce(function (a, b) {
                        return a + D.B(b.weight, 1);
                    }, 0),
                    k = g(a.group, !1);
                k.description = !0;
                k.T = h * e.ec;
                k.index = f++;
                k.parent = a;
                k.R = a.R + 1;
                k.id = k.id + '_d';
                a.e.push(k);
            }
            for (h = 0; h < c.length; h++) {
                var l = c[h],
                    k = D.B(l.weight, 1);
                if (0 >= k)
                    if (e.xj) k = 0.9 * d;
                    else continue;
                l = g(l, !0);
                l.T = k;
                l.index = f;
                l.parent = a;
                l.R = a.R + 1;
                a.e.push(l);
                f++;
            }
        }
        function g(a, b) {
            var c = new Fa();
            d(a);
            c.id = a.__id;
            c.group = a;
            b && (n[a.__id] = c);
            return c;
        }
        function d(a) {
            D.M(a, '__id') ||
                (Object.defineProperty(a, '__id', {
                    enumerable: !1,
                    configurable: !1,
                    writable: !1,
                    value: h,
                }),
                h++);
        }
        function b(a) {
            for (var b = Number.MAX_VALUE, c = 0; c < a.length; c++) {
                var d = a[c].weight;
                0 < d && b > d && (b = d);
            }
            b === Number.MAX_VALUE && (b = 1);
            return b;
        }
        function f(a) {
            if (!a.empty()) {
                a = a.e;
                var b = 0,
                    c;
                for (c = a.length - 1; 0 <= c; c--) {
                    var d = a[c].T;
                    b < d && (b = d);
                }
                for (c = a.length - 1; 0 <= c; c--)
                    (d = a[c]), (d.yg = d.T / b);
            }
        }
        function k(a) {
            if (!a.empty()) {
                a = a.e.slice(0).sort(function (a, b) {
                    return a.T < b.T ? 1 : a.T > b.T ? -1 : a.index - b.index;
                });
                for (var b = 0; b < a.length; b++) a[b].Hd = b;
            }
        }
        function l() {
            for (
                var b = a.e.reduce(function (a, b) {
                        return a + b.T;
                    }, 0),
                    c = 0;
                c < a.e.length;
                c++
            ) {
                var d = a.e[c];
                d.Ra && (d.T = Math.max(0, e.Vg) * b);
            }
        }
        var c = this,
            a = new Fa(),
            h,
            n,
            q,
            p,
            r;
        this.H = function () {
            return a;
        };
        this.S = function (a) {
            var b = a.group.groups,
                c = e.ti;
            return !a.e &&
                !a.description &&
                b &&
                0 < b.length &&
                r + b.length <= c
                ? ((r += b.length), m(a, b), f(a), k(a), !0)
                : !1;
        };
        this.Y = function (b) {
            function c(a) {
                var b = a.groups;
                if (b)
                    for (var e = 0; e < b.length; e++) {
                        var f = b[e];
                        d(f);
                        var g = f.__id;
                        n[g] = null;
                        p[g] = a;
                        g = f.id;
                        D.V(g) || (q[g] = f);
                        c(f);
                    }
            }
            function u(a, b) {
                if (!a) return b;
                var c = Math.max(b, a.__id || 0),
                    d = a.groups;
                if (d && 0 < d.length)
                    for (var e = d.length - 1; 0 <= e; e--) c = u(d[e], c);
                return c;
            }
            a.group = b;
            a.Ba = !1;
            a.N = !1;
            a.Qa = !1;
            a.open = !0;
            a.Nb = 1;
            h = u(b, 0) + 1;
            n = {};
            q = {};
            p = {};
            r = 0;
            b && (d(b), (n[b.__id] = a), D.V(b.id) || (q[b.id] = b), c(b));
            m(a, (b && b.groups) || []);
            (function (a) {
                if (!a.empty() && (e.ye || e.Db)) {
                    var b = g({ attribution: !0 });
                    b.index = a.e.length;
                    e.Db || (b.group.label = e.ye);
                    b.parent = a;
                    b.R = a.R + 1;
                    b.Ra = !0;
                    a.e.push(b);
                }
            })(a);
            f(a);
            l();
            k(a);
        };
        this.update = function () {
            $.Ea(a, function (a) {
                if (!a.empty()) {
                    a = a.e;
                    for (
                        var c = b(
                                a.map(function (a) {
                                    return a.group;
                                }),
                            ),
                            d = 0;
                        d < a.length;
                        d++
                    ) {
                        var e = a[d];
                        e.T = 0 < e.group.weight ? e.group.weight : 0.9 * c;
                    }
                }
            });
            f(a);
            l();
            k(a);
        };
        this.k = function (b) {
            return (function () {
                if (D.V(b) || D.pf(b)) return [];
                if (Array.isArray(b)) return b.map(c.d, c);
                if (D.lc(b)) {
                    if (D.M(b, '__id')) return [c.d(b)];
                    if (D.M(b, 'all')) {
                        var d = [];
                        $.F(a, function (a) {
                            d.push(a);
                        });
                        return d;
                    }
                    if (D.M(b, 'groups')) return c.k(b.groups);
                }
                return [c.d(b)];
            })().filter(function (a) {
                return void 0 !== a;
            });
        };
        this.d = function (a) {
            if (D.lc(a) && D.M(a, '__id')) {
                if (((a = a.__id), D.M(n, a))) {
                    if (null === n[a]) {
                        for (var b = p[a], c = []; b; ) {
                            b = b.__id;
                            c.push(b);
                            if (n[b]) break;
                            b = p[b];
                        }
                        for (b = c.length - 1; 0 <= b; b--) this.S(n[c[b]]);
                    }
                    return n[a];
                }
            } else if (D.M(q, a)) return this.d(q[a]);
        };
        this.A = function (a, b, d) {
            return {
                e: c.k(a),
                Ha: D.B(a && a[b], !0),
                Ga: D.B(a && a.keepPrevious, d),
            };
        };
    }
    function Ga(e, m, g) {
        var d = {};
        m.Ga &&
            $.F(e, function (b) {
                g(b) && (d[b.id] = b);
            });
        e = m.e;
        m = m.Ha;
        for (var b = e.length - 1; 0 <= b; b--) {
            var f = e[b];
            d[f.id] = m ? f : void 0;
        }
        var k = [];
        D.Fa(d, function (b) {
            void 0 !== b && k.push(b);
        });
        return k;
    }
    function Ha(e) {
        function m(a, b) {
            var c = a.ka;
            b.opacity = 1;
            b.Ja = 1;
            b.ua = 0 > c ? 1 - (p.fi / 100) * c : 1;
            b.va = 0 > c ? 1 - (p.gi / 100) * c : 1;
            b.fa = 0 > c ? 1 + 0.5 * c : 1;
        }
        function g(a) {
            a = a.ka;
            return Math.max(0.001, 0 === a ? 1 : 1 + a * (p.Ya - 1));
        }
        function d(a, b) {
            for (
                var c = a.reduce(function (a, b) {
                        a[b.id] = b;
                        return a;
                    }, {}),
                    d = a.length - 1;
                0 <= d;
                d--
            )
                $.F(a[d], function (a) {
                    c[a.id] = void 0;
                });
            var e = [];
            D.Fa(c, function (a) {
                a &&
                    $.Je(a, function (a) {
                        a.open || e.push(a);
                    });
            });
            var f = [];
            D.Fa(c, function (a) {
                a && a.open && f.push(a);
            });
            d = [];
            0 !== e.length && d.push(x.Mb({ e: e, Ha: !0, Ga: !0 }, b, !0));
            return pa(d);
        }
        function b(b, d, g, n) {
            var q = l();
            if (0 === b.length && !q) return new V().J().L();
            var m = b.reduce(function (a, b) {
                    a[b.id] = !0;
                    return a;
                }, {}),
                s = [];
            b = [];
            if (
                A.reduce(function (a, b) {
                    return (
                        a ||
                        (m[b.id] && (!b.U || 1 !== b.ka)) ||
                        (!m[b.id] && !b.parent.U && (b.U || -1 !== b.ka))
                    );
                }, !1)
            ) {
                var x = [],
                    C = {};
                A.forEach(function (b) {
                    m[b.id] &&
                        (b.U || s.push(b),
                        (b.U = !0),
                        $.Ea(b, function (b) {
                            x.push(a(b, 1));
                            C[b.id] = !0;
                        }));
                });
                0 < x.length
                    ? ($.F(r, function (b) {
                          m[b.id] || (b.U && s.push(b), (b.U = !1));
                          C[b.id] || x.push(a(b, -1));
                      }),
                      b.push(y.D.m({}).$a(x).call(h).cb()),
                      f(m),
                      b.push(k(q)),
                      g && (u.uc(B, p.Sc, p.Xa, X.oa(p.ic)), u.Sb()))
                    : (b.push(c(g)),
                      d &&
                          $.F(r, function (a) {
                              a.U && s.push(a);
                          }));
            }
            return pa(b).O(function () {
                w.xd(
                    d,
                    s,
                    'exposed',
                    function (a) {
                        return a.U;
                    },
                    { indirect: n },
                    e.options.Gf,
                    e.options.Ff,
                );
            });
        }
        function f(a) {
            A.reduce(
                n(!0, void 0, function (b) {
                    return b.U || a[b.id];
                }),
                q(B),
            );
            B.x -= (B.f * (p.Ya - 1)) / 2;
            B.y -= (B.i * (p.Ya - 1)) / 2;
            B.f *= p.Ya;
            B.i *= p.Ya;
        }
        function k(a) {
            if (a || !u.Ud())
                return y.D.m(s)
                    .ia({
                        duration: 0.7 * p.Xa,
                        G: {
                            x: { end: B.x + B.f / 2, Q: X.oa(p.ic) },
                            y: { end: B.y + B.i / 2, Q: X.oa(p.ic) },
                        },
                        ca: function () {
                            e.c.p('foamtree:dirty', !0);
                        },
                    })
                    .cb();
            s.x = B.x + B.f / 2;
            s.y = B.y + B.i / 2;
            return new V().J().L();
        }
        function l() {
            return (
                !!A &&
                A.reduce(function (a, b) {
                    return a || 0 !== b.ka;
                }, !1)
            );
        }
        function c(b) {
            var c = [],
                d = [];
            $.F(r, function (b) {
                0 !== b.ka &&
                    d.push(
                        a(b, 0, function () {
                            this.U = !1;
                        }),
                    );
            });
            c.push(y.D.m({}).$a(d).cb());
            u.content(0, 0, K, C);
            b && (c.push(u.reset(p.Xa, X.oa(p.ic))), u.Sb());
            return pa(c);
        }
        function a(a, b, c) {
            var d = y.D.m(a);
            0 === a.ka &&
                0 !== b &&
                d.call(function () {
                    this.Ec(H);
                    this.Bb(m);
                });
            d.ia({
                duration: p.Xa,
                G: { ka: { end: b, Q: X.oa(p.ic) } },
                ca: function () {
                    r.I = !0;
                    r.Ma = !0;
                    e.c.p('foamtree:dirty', !0);
                },
            });
            0 === b &&
                d.call(function () {
                    this.Qd();
                    this.pc();
                    this.hd(H);
                    this.gd(m);
                });
            return d.call(c).wa();
        }
        function h() {
            var a = r.e.reduce(n(!1, H.Wb, void 0), q({})).da,
                b = p.Sc,
                c = Math.min(a.x, B.x - B.f * b),
                d = Math.max(a.x + a.f, B.x + B.f * (1 + b)),
                e = Math.min(a.y, B.y - B.i * b),
                a = Math.max(a.y + a.i, B.y + B.i * (1 + b));
            u.content(c, e, d - c, a - e);
        }
        function n(a, b, c) {
            var d = {};
            return function (e, f) {
                if (!c || c(f)) {
                    for (
                        var g = a ? f.aa || f.o : f.o, h, k = g.length - 1;
                        0 <= k;
                        k--
                    )
                        (h = void 0 !== b ? b(f, g[k], d) : g[k]),
                            (e.bd = Math.min(e.bd, h.x)),
                            (e.Rd = Math.max(e.Rd, h.x)),
                            (e.cd = Math.min(e.cd, h.y)),
                            (e.Sd = Math.max(e.Sd, h.y));
                    e.da.x = e.bd;
                    e.da.y = e.cd;
                    e.da.f = e.Rd - e.bd;
                    e.da.i = e.Sd - e.cd;
                }
                return e;
            };
        }
        function q(a) {
            return {
                bd: Number.MAX_VALUE,
                Rd: Number.MIN_VALUE,
                cd: Number.MAX_VALUE,
                Sd: Number.MIN_VALUE,
                da: a,
            };
        }
        var p = e.options,
            r,
            s,
            w,
            u,
            y,
            x,
            A,
            B,
            K,
            C,
            H = {
                tf: function (a, b) {
                    b.scale = g(a);
                    return !1;
                },
                Vb: function (a, b) {
                    var c = g(a),
                        d = s.x,
                        e = s.y;
                    b.translate(d, e);
                    b.scale(c, c);
                    b.translate(-d, -e);
                },
                Xb: function (a, b, c) {
                    a = g(a);
                    var d = s.x,
                        e = s.y;
                    c.x = (b.x - d) / a + d;
                    c.y = (b.y - e) / a + e;
                },
                Wb: function (a, b, c) {
                    a = g(a);
                    var d = s.x,
                        e = s.y;
                    c.x = (b.x - d) * a + d;
                    c.y = (b.y - e) * a + e;
                    return c;
                },
            };
        e.c.j('stage:initialized', function (a, b, c, d) {
            s = { x: c / 2, y: d / 2 };
            K = c;
            C = d;
            B = { x: 0, y: 0, f: K, i: C };
        });
        e.c.j('stage:resized', function (a, b, c, d) {
            s.x *= c / a;
            s.y *= d / b;
            K = c;
            C = d;
        });
        e.c.j('api:initialized', function (a) {
            w = a;
        });
        e.c.j('zoom:initialized', function (a) {
            u = a;
        });
        e.c.j('model:loaded', function (a, b) {
            r = a;
            A = b;
        });
        e.c.j('model:childrenAttached', function (a) {
            A = a;
        });
        e.c.j('timeline:initialized', function (a) {
            y = a;
        });
        e.c.j('openclose:initialized', function (a) {
            x = a;
        });
        var Q = [
            'groupExposureScale',
            'groupUnexposureScale',
            'groupExposureZoomMargin',
        ];
        e.c.j('options:changed', function (a) {
            D.pb(a, Q) && l() && (f({}), u.Ej(B, p.Sc), u.Sb());
        });
        this.H = function () {
            e.c.p('expose:initialized', this);
        };
        this.hc = function (a, c, e, f) {
            var g = a.e.reduce(function (a, b) {
                    for (var c = b; (c = c.parent); ) a[c.id] = !0;
                    return a;
                }, {}),
                h = Ga(r, a, function (a) {
                    return a.U && !a.open && !g[a.id];
                }),
                k = new V();
            d(h, c).O(function () {
                b(
                    h.filter(function (a) {
                        return a.o && a.aa;
                    }),
                    c,
                    e,
                    f,
                ).O(k.J);
            });
            return k.L();
        };
    }
    function Ia(e) {
        function m(b) {
            function a(a, b) {
                var c = Math.min(1, Math.max(0, a.ta));
                b.opacity = c;
                b.ua = 1;
                b.va = c;
                b.Ja = c;
                b.fa = a.Jb;
            }
            var g = e.options,
                n = g.sj,
                q = g.tj,
                p = g.pj,
                r = g.qj,
                m = g.rj,
                w = g.ie,
                u = n + q + p + r + m,
                y = 0 < u ? w / u : 0,
                x = [];
            l.ib(g.jg, g.ig, g.kg, g.lg, g.hg);
            if (0 === y && b.e && b.N) {
                w = b.e;
                for (u = 0; u < w.length; u++) {
                    var A = w[u];
                    A.ta = 1;
                    A.Jb = 1;
                    A.Bb(a);
                    A.pc();
                    A.gd(a);
                }
                b.I = !0;
                e.c.p('foamtree:dirty', 0 < y);
                return new V().J().L();
            }
            if (b.e && b.N) {
                Ba.Ia(b, Ba.xa(b, e.options.ke), function (b, c, f) {
                    b.Ec(l);
                    b.Bb(a);
                    f = 'groups' === e.options.je ? f : c;
                    c = d.D.m(b)
                        .gb(f * y * n)
                        .ia({
                            duration: y * q,
                            G: { ta: { end: 1, Q: X.oa(g.oj) } },
                            ca: function () {
                                this.I = !0;
                                e.c.p('foamtree:dirty', 0 < y);
                            },
                        })
                        .wa();
                    f = d.D.m(b)
                        .gb(k ? y * (p + f * r) : 0)
                        .ia({
                            duration: k ? y * m : 0,
                            G: { Jb: { end: 1, Q: X.Lb } },
                            ca: function () {
                                this.I = !0;
                                e.c.p('foamtree:dirty', 0 < y);
                            },
                        })
                        .wa();
                    b = d.D.m(b)
                        .$a([c, f])
                        .re()
                        .kb(function () {
                            this.Qd();
                            this.pc();
                            this.hd(l);
                            this.gd(a);
                        })
                        .wa();
                    x.push(b);
                });
                f.d();
                var B = new V();
                d.D.m({})
                    .$a(x)
                    .call(function () {
                        f.k();
                        B.J();
                    })
                    .start();
                return B.L();
            }
            return new V().J().L();
        }
        var g,
            d,
            b = [],
            f = new qa(D.sa);
        e.c.j('stage:initialized', function () {});
        e.c.j('stage:resized', function () {});
        e.c.j('stage:newLayer', function (c, a) {
            b.push(a);
        });
        e.c.j('model:loaded', function (b) {
            g = b;
            f.clear();
        });
        e.c.j('zoom:initialized', function () {});
        e.c.j('timeline:initialized', function (b) {
            d = b;
        });
        var k = !1;
        e.c.j('render:renderers:resolved', function (b) {
            k = b.labelPlainFill || !1;
        });
        var l = new (function () {
            var b = 0,
                a = 0,
                d = 0,
                e = 0,
                f = 0,
                g = 0;
            this.ib = function (k, l, m, u, y) {
                b = 1 + l;
                a = 1 - b;
                d = m;
                e = u;
                f = y;
                g = k;
            };
            this.tf = function (g, k) {
                k.scale = b + a * g.ta;
                return 0 !== f || 0 !== d || 0 !== e;
            };
            this.Vb = function (k, l) {
                var m = b + a * k.ta,
                    u = k.parent,
                    y = g * k.x + (1 - g) * u.x,
                    x = g * k.y + (1 - g) * u.y;
                l.translate(y, x);
                l.scale(m, m);
                m = 1 - k.ta;
                l.rotate(f * Math.PI * m);
                l.translate(-y, -x);
                l.translate(u.q.f * d * m, u.q.i * e * m);
            };
            this.Xb = function (f, k, l) {
                var m = b + a * f.ta,
                    q = g * f.x + (1 - g) * f.parent.x,
                    x = g * f.y + (1 - g) * f.parent.y,
                    A = 1 - f.ta;
                f = f.parent;
                l.x = (k.x - q) / m + q - f.q.f * d * A;
                l.y = (k.y - x) / m + x - f.q.i * e * A;
            };
            this.Wb = function (f, k, l) {
                var m = b + a * f.ta,
                    q = g * f.x + (1 - g) * f.parent.x,
                    x = g * f.y + (1 - g) * f.parent.y,
                    A = 1 - f.ta;
                f = f.parent;
                l.x = (k.x - q) * m + q - f.q.f * d * A;
                l.y = (k.y - x) * m + x - f.q.i * e * A;
            };
        })();
        this.H = function () {};
        this.k = function () {
            function b(a, c) {
                var d = Math.min(1, Math.max(0, a.ta));
                c.opacity = d;
                c.ua = 1;
                c.va = d;
                c.Ja = d;
                c.fa = a.Jb;
            }
            function a(a, b) {
                var c = Math.min(1, Math.max(0, a.be));
                b.opacity = c;
                b.Ja = c;
                b.ua = 1;
                b.va = 1;
                b.fa = a.Jb;
            }
            var h = e.options,
                n = h.ae,
                m = h.Li,
                p = h.Mi,
                r = h.Ni,
                s = h.Hi,
                w = h.Ii,
                u = h.Ji,
                y = h.Di,
                x = h.Ei,
                A = h.Fi,
                B = s + w + u + y + x + A + m + p + r,
                K = 0 < B ? n / B : 0,
                C = [];
            f.A()
                ? l.ib(h.Ri, h.Pi, h.Si, h.Ti, h.Oi)
                : l.ib(h.jg, h.ig, h.kg, h.lg, h.hg);
            Ba.Ia(g, Ba.xa(g, e.options.Qi), function (f, g, n) {
                var B = 'groups' === e.options.Ki ? n : g;
                C.push(
                    d.D.m(f)
                        .call(function () {
                            this.Bb(b);
                        })
                        .gb(k ? K * (s + B * w) : 0)
                        .ia({
                            duration: k ? K * u : 0,
                            G: { Jb: { end: 0, Q: X.Lb } },
                            ca: function () {
                                this.I = !0;
                                e.c.p('foamtree:dirty', !0);
                            },
                        })
                        .wa(),
                );
                $.F(f, function (b) {
                    C.push(
                        d.D.m(b)
                            .call(function () {
                                this.Ec(l);
                                this.Bb(a);
                            })
                            .gb(K * (y + x * B))
                            .ia({
                                duration: K * A,
                                G: { be: { end: 0, Q: X.Lb } },
                                ca: function () {
                                    this.I = !0;
                                    e.c.p('foamtree:dirty', !0);
                                },
                            })
                            .kb(function () {
                                this.selected = !1;
                                this.hd(l);
                            })
                            .wa(),
                    );
                });
                C.push(
                    d.D.m(f)
                        .call(function () {
                            this.Ec(l);
                        })
                        .gb(K * (m + p * B))
                        .ia({
                            duration: K * r,
                            G: { ta: { end: 0, Q: X.oa(h.Gi) } },
                            ca: function () {
                                this.I = !0;
                                e.c.p('foamtree:dirty', !0);
                            },
                        })
                        .kb(function () {
                            this.selected = !1;
                            this.hd(l);
                        })
                        .wa(),
                );
            });
            return d.D.m({}).$a(C).cb();
        };
        this.d = function (b) {
            return m(b);
        };
    }
    function Ja(e) {
        function m(b, a) {
            var d = [];
            $.F(k, function (a) {
                if (a.e) {
                    var e = D.M(b, a.id);
                    a.open !== e &&
                        (e ||
                            a.U ||
                            $.F(a, function (b) {
                                if (b.U) return d.push(a), !1;
                            }));
                }
            });
            if (0 === d.length) return new V().J().L();
            var e;
            for (e = d.length - 1; 0 <= e; e--) d[e].open = !1;
            var g = f.hc({ e: d, Ha: !0, Ga: !0 }, a, !0, !0);
            for (e = d.length - 1; 0 <= e; e--) d[e].open = !0;
            return g;
        }
        function g(c, a, f) {
            function g(a, c) {
                a.Bb(m);
                var f = b.D.m(a)
                    .ia({
                        duration: e.options.ed,
                        G: { Nb: { end: c ? 1 : 0, Q: X.Ce } },
                        ca: function () {
                            this.I = !0;
                            e.c.p('foamtree:dirty', !0);
                        },
                    })
                    .call(function () {
                        this.open = c;
                        a.hb = !1;
                    })
                    .kb(function () {
                        this.pc();
                        this.gd(m);
                        delete d[this.id];
                    })
                    .wa();
                return (d[a.id] = f);
            }
            function m(a, b) {
                b.opacity = 1 - a.Nb;
                b.ua = 1;
                b.va = 1;
                b.fa = 1;
                b.Ja = 1;
            }
            var p = [],
                r = [];
            $.F(k, function (a) {
                if (a.N && a.X) {
                    var b = D.M(c, a.id),
                        e = d[a.id];
                    if (e && e.Ib()) e.stop();
                    else if (a.open === b) return;
                    a.hb = b;
                    b || ((a.open = b), (a.Wd = !1));
                    r.push(a);
                    p.push(g(a, b));
                }
            });
            return 0 < p.length
                ? (e.c.p('openclose:changing'),
                  b.D.m({})
                      .$a(p)
                      .cb()
                      .O(function () {
                          l.xd(
                              a,
                              r,
                              'open',
                              function (a) {
                                  return a.open;
                              },
                              { indirect: f },
                              e.options.Of,
                              e.options.Nf,
                          );
                      }))
                : new V().J().L();
        }
        var d, b, f, k, l;
        e.c.j('api:initialized', function (b) {
            l = b;
        });
        e.c.j('model:loaded', function (b) {
            k = b;
            d = {};
        });
        e.c.j('timeline:initialized', function (c) {
            b = c;
        });
        e.c.j('expose:initialized', function (b) {
            f = b;
        });
        this.H = function () {
            e.c.p('openclose:initialized', this);
        };
        this.Mb = function (b, a, d) {
            if ('flattened' == e.options.Va) return new V().J().L();
            b = Ga(k, b, function (a) {
                return a.open || a.hb;
            });
            for (var f = new V(), l = 0; l < b.length; l++) b[l].hb = !0;
            0 < b.length && e.c.p('foamtree:attachChildren', b);
            var p = b.reduce(function (a, b) {
                a[b.id] = !0;
                return a;
            }, {});
            m(p, a).O(function () {
                g(p, a, d).O(f.J);
            });
            return f.L();
        };
    }
    function Ka(e) {
        function m(b, f) {
            var k = Ga(g, b, function (a) {
                return a.selected;
            });
            $.F(g, function (a) {
                !0 === a.selected &&
                    ((a.selected = !a.selected), (a.I = !a.I), (a.bb = !a.bb));
            });
            var l;
            for (l = k.length - 1; 0 <= l; l--) {
                var c = k[l];
                c.selected = !c.selected;
                c.I = !c.I;
                c.bb = !c.bb;
            }
            var a = [];
            $.F(g, function (b) {
                b.I && a.push(b);
            });
            0 < a.length && e.c.p('foamtree:dirty', !1);
            d.xd(
                f,
                a,
                'selected',
                function (a) {
                    return a.selected;
                },
                {},
                e.options.Qf,
                e.options.Pf,
            );
        }
        var g, d;
        e.c.j('api:initialized', function (b) {
            d = b;
        });
        e.c.j('model:loaded', function (b) {
            g = b;
        });
        this.H = function () {
            e.c.p('select:initialized', this);
        };
        this.select = function (b, d) {
            return m(b, d);
        };
    }
    function La(e) {
        function m(a) {
            return function (b) {
                a.call(this, {
                    x: b.x,
                    y: b.y,
                    scale: b.scale,
                    zd: b.delta,
                    ctrlKey: b.ctrlKey,
                    metaKey: b.metaKey,
                    altKey: b.altKey,
                    shiftKey: b.shiftKey,
                    yb: b.secondary,
                    touches: b.touches,
                });
            };
        }
        function g() {
            function a(b) {
                return function (a) {
                    a.x *= N / q.clientWidth;
                    a.y *= U / q.clientHeight;
                    return b(a);
                };
            }
            'external' !== n.kf &&
                ('hammerjs' === n.kf &&
                    D.M(window, 'Hammer') &&
                    (E.H(q),
                    E.m('tap', a(h.d), !0),
                    E.m('doubletap', a(h.k), !0),
                    E.m('hold', a(h.xa), !0),
                    E.m('touch', a(h.za), !1),
                    E.m('release', a(h.Aa), !1),
                    E.m('dragstart', a(h.Y), !0),
                    E.m('drag', a(h.A), !0),
                    E.m('dragend', a(h.S), !0),
                    E.m('transformstart', a(h.Wa), !0),
                    E.m('transform', a(h.Ia), !0),
                    E.m('transformend', a(h.eb), !0)),
                (F = new ta(q)),
                (T = new sa()),
                F.d(a(h.d)),
                F.k(a(h.k)),
                F.xa(a(h.xa)),
                F.Aa(a(h.za)),
                F.Pa(a(h.Aa)),
                F.Y(a(h.Y)),
                F.A(a(h.A)),
                F.S(a(h.S)),
                F.ya(a(h.ya)),
                F.Ia(a(h.ya)),
                F.za(a(h.Pa)),
                T.addEventListener('keyup', function (a) {
                    var b = !1,
                        c = void 0,
                        d = n.Uf({
                            keyCode: a.keyCode,
                            preventDefault: function () {
                                b = !0;
                            },
                            preventOriginalEventDefault: function () {
                                c = 'prevent';
                            },
                            allowOriginalEventDefault: function () {
                                c = 'allow';
                            },
                        });
                    'prevent' === c && a.preventDefault();
                    (b = b || 0 <= d.indexOf(!1)) ||
                        (27 === a.keyCode && e.c.p('interaction:reset'));
                }));
        }
        function d() {
            p.Jc(2)
                ? e.c.p('interaction:reset')
                : p.normalize(n.yc, X.oa(n.zc));
        }
        function b(a) {
            return function () {
                x.empty() || a.apply(this, arguments);
            };
        }
        function f(a, b, d) {
            var e = {},
                f = {};
            return function (g) {
                var h;
                switch (a) {
                    case 'click':
                        h = n.Af;
                        break;
                    case 'doubleclick':
                        h = n.Bf;
                        break;
                    case 'hold':
                        h = n.Hf;
                        break;
                    case 'hover':
                        h = n.If;
                        break;
                    case 'mousemove':
                        h = n.Kf;
                        break;
                    case 'mousewheel':
                        h = n.Mf;
                        break;
                    case 'mousedown':
                        h = n.Jf;
                        break;
                    case 'mouseup':
                        h = n.Lf;
                        break;
                    case 'dragstart':
                        h = n.Ef;
                        break;
                    case 'drag':
                        h = n.Cf;
                        break;
                    case 'dragend':
                        h = n.Df;
                        break;
                    case 'transformstart':
                        h = n.Tf;
                        break;
                    case 'transform':
                        h = n.Rf;
                        break;
                    case 'transformend':
                        h = n.Sf;
                }
                var k = !1,
                    m = !h.empty(),
                    r = p.absolute(g, e),
                    q = (b || m) && l(r),
                    s = (b || m) && c(r);
                m &&
                    ((m = q ? q.group : null),
                    (r = q ? q.Xb(r, f) : r),
                    (g.Ob = void 0),
                    (h = h({
                        type: a,
                        group: m,
                        topmostClosedGroup: m,
                        bottommostOpenGroup: s ? s.group : null,
                        x: g.x,
                        y: g.y,
                        xAbsolute: r.x,
                        yAbsolute: r.y,
                        scale: D.B(g.scale, 1),
                        secondary: g.yb,
                        touches: D.B(g.touches, 1),
                        delta: D.B(g.zd, 0),
                        ctrlKey: g.ctrlKey,
                        metaKey: g.metaKey,
                        altKey: g.altKey,
                        shiftKey: g.shiftKey,
                        preventDefault: function () {
                            k = !0;
                        },
                        preventOriginalEventDefault: function () {
                            g.Ob = 'prevent';
                        },
                        allowOriginalEventDefault: function () {
                            g.Ob = 'allow';
                        },
                    })),
                    (k = k || 0 <= h.indexOf(!1)));
                k || (d && d({ Gc: q, Xg: s }, g));
            };
        }
        function k(a) {
            function b(a, c) {
                var d = c.e;
                if (d) {
                    for (
                        var e = -Number.MAX_VALUE, f, g = 0;
                        g < d.length;
                        g++
                    ) {
                        var h = d[g];
                        !h.description &&
                            h.ea &&
                            I(h, a) &&
                            h.scale > e &&
                            ((f = h), (e = h.scale));
                    }
                    var k;
                    f && (k = b(a, f));
                    return k || f;
                }
            }
            return b(a, x);
        }
        function l(a, b) {
            var c;
            if ('flattened' == n.Va) c = k(a);
            else {
                c = b || 0;
                for (var d = t.length, e = void 0, f = 0; f < d; f++) {
                    var g = t[f];
                    g.scale > c &&
                        !1 === g.open &&
                        g.ea &&
                        I(g, a) &&
                        ((e = g), (c = g.scale));
                }
                c = e;
            }
            return c;
        }
        function c(a) {
            var b = void 0,
                c = 0;
            $.Mc(x, function (d) {
                !0 === d.open &&
                    d.ea &&
                    d.scale > c &&
                    I(d, a) &&
                    ((b = d), (c = d.scale));
            });
            return b;
        }
        var a = v.ni(),
            h = this,
            n = e.options,
            q,
            p,
            r,
            s,
            w,
            u,
            y,
            x,
            A = !1,
            B,
            K,
            C,
            H,
            Q,
            O,
            P,
            F,
            T,
            N,
            U;
        e.c.j('stage:initialized', function (a, b, c, d) {
            q = b;
            N = c;
            U = d;
            g();
        });
        e.c.j('stage:resized', function (a, b, c, d) {
            N = c;
            U = d;
        });
        e.c.j('stage:disposed', function () {
            F.mb();
            E.mb();
            T.d();
        });
        e.c.j('expose:initialized', function (a) {
            s = a;
        });
        e.c.j('zoom:initialized', function (a) {
            p = a;
        });
        e.c.j('openclose:initialized', function (a) {
            w = a;
        });
        e.c.j('select:initialized', function (a) {
            u = a;
        });
        e.c.j('titlebar:initialized', function (a) {
            y = a;
        });
        e.c.j('timeline:initialized', function (a) {
            r = a;
        });
        var t;
        e.c.j('model:loaded', function (a, b) {
            x = a;
            t = b;
        });
        e.c.j('model:childrenAttached', function (a) {
            t = a;
        });
        this.H = function () {};
        this.za = b(
            f('mousedown', !1, function () {
                p.yi();
            }),
        );
        this.Aa = b(f('mouseup', !1, void 0));
        this.d = b(
            f('click', !0, function (a, b) {
                if (!b.yb && !b.shiftKey) {
                    var c = a.Gc;
                    c &&
                        (c.Ra && n.ud
                            ? n.ud && (document.location.href = n.ud)
                            : u.select(
                                  {
                                      e: [c],
                                      Ha: !c.selected,
                                      Ga: b.metaKey || b.ctrlKey,
                                  },
                                  !0,
                              ));
                }
            }),
        );
        this.k = b(
            f('doubleclick', !0, function (a, b) {
                var c, d;
                if (b.yb || b.shiftKey) {
                    if ((c = a.Gc))
                        c.parent.U && (c = c.parent),
                            (d = {
                                e: c.parent !== x ? [c.parent] : [],
                                Ha: !0,
                                Ga: !1,
                            }),
                            u.select(d, !0),
                            s.hc(d, !0, !0, !1);
                } else if ((c = a.Gc)) (d = { e: [c], Ha: !0, Ga: !1 }), (c.hb = !0), e.c.p('foamtree:attachChildren', [c]), s.hc(d, !0, !0, !1);
                c &&
                    r.D.m({})
                        .gb(n.Xa / 2)
                        .call(function () {
                            w.Mb(
                                {
                                    e: $.Oc(x, function (a) {
                                        return a.Wd && !$.mi(c, a);
                                    }),
                                    Ha: !1,
                                    Ga: !0,
                                },
                                !0,
                                !0,
                            );
                            c.Wd = !0;
                            w.Mb(
                                { e: [c], Ha: !(b.yb || b.shiftKey), Ga: !0 },
                                !0,
                                !0,
                            );
                        })
                        .start();
            }),
        );
        this.xa = b(
            f('hold', !0, function (a, b) {
                var c = !(b.metaKey || b.ctrlKey || b.shiftKey) && !b.yb,
                    d;
                (d = c ? a.Gc : a.Xg) &&
                    d !== x &&
                    w.Mb({ e: [d], Ha: c, Ga: !0 }, !0, !1);
            }),
        );
        this.Y = b(
            f('dragstart', !1, function (a, b) {
                B = b.x;
                K = b.y;
                C = Date.now();
                A = !0;
            }),
        );
        this.A = b(
            f('drag', !1, function (a, b) {
                if (A) {
                    var c = Date.now();
                    O = Math.min(1, c - C);
                    C = c;
                    var c = b.x - B,
                        d = b.y - K;
                    p.wi(c, d);
                    H = c;
                    Q = d;
                    B = b.x;
                    K = b.y;
                }
            }),
        );
        this.S = b(
            f('dragend', !1, function () {
                if (A) {
                    A = !1;
                    var a = Math.sqrt(H * H + Q * Q) / O;
                    4 <= a ? p.xi(a, H, Q) : p.yf();
                }
            }),
        );
        this.Wa = b(
            f('transformstart', !1, function (a, b) {
                P = 1;
                B = b.x;
                K = b.y;
            }),
        );
        var z = 1,
            L = !1;
        this.Ia = b(
            f('transform', !1, function (a, b) {
                var c = b.scale - 0.01;
                p.Qg(b, c / P, b.x - B, b.y - K);
                P = c;
                B = b.x;
                K = b.y;
                z = P;
                L = L || 2 < b.touches;
            }),
        );
        this.eb = b(
            f('transformend', !1, function () {
                L && 0.8 > z ? e.c.p('interaction:reset') : d();
                L = !1;
            }),
        );
        this.Pa = b(
            f(
                'mousewheel',
                !1,
                (function () {
                    var b = D.bh(function () {
                        d();
                    }, 300);
                    return function (c, e) {
                        var f = n.Kj;
                        1 !== f &&
                            ((f = Math.pow(f, e.zd)),
                            a
                                ? (p.Rg(e, f), b())
                                : p.$b(e, f, n.yc, X.oa(n.zc)).O(d));
                    };
                })(),
            ),
        );
        this.ya = b(
            (function () {
                var a = void 0,
                    b = {},
                    c = !1,
                    d,
                    g = f('hover', !1, function () {
                        a && ((a.Gb = !1), (a.I = !0));
                        d && ((d.Gb = !0), (d.I = !0));
                        y.update(d);
                        e.c.p('foamtree:dirty', !1);
                    }),
                    h = f('mousemove', !1, void 0);
                return function (e) {
                    if ('out' === e.type) (d = void 0), (c = d !== a);
                    else if ((p.absolute(e, b), a && !a.open && I(a, b))) {
                        var f = l(b, a.scale);
                        f && f != a ? ((c = !0), (d = f)) : (c = !1);
                    } else (d = l(b)), (c = d !== a);
                    c && (g(e), (a = d), (c = !1));
                    a && h(e);
                };
            })(),
        );
        this.ib = {
            click: m(this.d),
            doubleclick: m(this.k),
            hold: m(this.xa),
            mouseup: m(this.Aa),
            mousedown: m(this.za),
            dragstart: m(this.Y),
            drag: m(this.A),
            dragend: m(this.S),
            transformstart: m(this.Wa),
            transform: m(this.Ia),
            transformend: m(this.eb),
            hover: m(this.ya),
            mousewheel: m(this.Pa),
        };
        var E = (function () {
                function a(b, c) {
                    return function (a) {
                        a = a.gesture;
                        var d = a.center,
                            d = ra.Le(q, d.pageX, d.pageY, {});
                        d.scale = a.scale;
                        d.yb = 1 < a.touches.length;
                        d.touches = a.touches.length;
                        b.call(q, d);
                        ((void 0 === d.Ob && c) || 'prevent' === d.Ob) &&
                            a.preventDefault();
                    };
                }
                var b,
                    c = {};
                return {
                    H: function (a) {
                        b = window.Hammer(a, {
                            doubletap_interval: 350,
                            hold_timeout: 400,
                            doubletap_distance: 10,
                        });
                    },
                    m: function (d, e, f) {
                        c[d] = e;
                        b.on(d, a(e, f));
                    },
                    mb: function () {
                        b &&
                            D.Fa(c, function (a, c) {
                                b.off(c, a);
                            });
                    },
                };
            })(),
            I = (function () {
                var a = {};
                return function (b, c) {
                    b.Xb(c, a);
                    return b.aa && M.Wa(b.aa, a);
                };
            })();
    }
    function Ma(e) {
        function m(b, d, e, g) {
            var c,
                a = 0,
                h = [];
            for (c = 0; c < d.length; c++) {
                var n = Math.sqrt(M.d(d[c], d[(c + 1) % d.length]));
                h.push(n);
                a += n;
            }
            for (c = 0; c < h.length; c++) h[c] /= a;
            b[0].x = e.x;
            b[0].y = e.y;
            var m = (n = a = 0);
            for (c = 1; c < b.length; c++) {
                for (
                    var p = b[c],
                        r = 0.95 * Math.pow(c / b.length, g),
                        a = a + 0.3819;
                    n < a;

                )
                    (n += h[m]), (m = (m + 1) % h.length);
                var s = (m - 1 + h.length) % h.length,
                    w = 1 - (n - a) / h[s],
                    u = d[s].x,
                    s = d[s].y,
                    y = d[m].x,
                    x = d[m].y,
                    u = (u - e.x) * r + e.x,
                    s = (s - e.y) * r + e.y,
                    y = (y - e.x) * r + e.x,
                    x = (x - e.y) * r + e.y;
                p.x = u * (1 - w) + y * w;
                p.y = s * (1 - w) + x * w;
            }
        }
        var g = {
            random: {
                Hb: function (b, d) {
                    for (var e = 0; e < b.length; e++) {
                        var g = b[e];
                        g.x = d.x + Math.random() * d.f;
                        g.y = d.y + Math.random() * d.i;
                    }
                },
                ac: 'box',
            },
            ordered: {
                Hb: function (b, e) {
                    var g = b.slice(0);
                    d.nc && g.sort(Na);
                    Ca.Zb(g, e, !1, d.fe);
                },
                ac: 'box',
            },
            squarified: {
                Hb: function (b, e) {
                    var g = b.slice(0);
                    d.nc && g.sort(Na);
                    Ca.ve(g, e, !1, d.fe);
                },
                ac: 'box',
            },
            fisheye: {
                Hb: function (b, e, g) {
                    b = b.slice(0);
                    d.nc && b.sort(Na);
                    m(b, e, g, 0.25);
                },
                ac: 'polygon',
            },
            blackhole: {
                Hb: function (b, e, g) {
                    b = b.slice(0);
                    d.nc && b.sort(Na).reverse();
                    m(b, e, g, 1);
                },
                ac: 'polygon',
            },
        };
        g.order = g.ordered;
        g.treemap = g.squarified;
        var d = e.options;
        this.d = function (b, e, k) {
            if (0 < b.length) {
                k =
                    g[
                        k.relaxationInitializer ||
                            k.initializer ||
                            d.jj ||
                            'random'
                    ];
                if ('box' === k.ac) {
                    var l = M.q(e, {});
                    k.Hb(b, l);
                    M.Cc(b, M.A(l), e);
                } else k.Hb(b, e, M.k(e, {}));
                for (l = b.length - 1; 0 <= l; l--) {
                    k = b[l];
                    if (k.description) {
                        b = M.Dc(e, d.Kc, d.dh);
                        k.x = b.x;
                        k.y = b.y;
                        break;
                    }
                    if (k.Ra) {
                        b = M.Dc(e, d.xe, d.Sg);
                        k.x = b.x;
                        k.y = b.y;
                        break;
                    }
                }
            }
        };
    }
    function Oa(e) {
        var m,
            g = e.options,
            d = new Pa(e, this),
            b = new Qa(e, this),
            f = { relaxed: d, ordered: b, squarified: b },
            k = f[e.options.Yc] || d;
        this.Dg = 5e-5;
        e.c.j('model:loaded', function (b) {
            m = b;
        });
        e.c.j('options:changed', function (b) {
            b.layout && D.M(f, g.Yc) && (k = f[g.Yc]);
        });
        this.step = function (b, c, a, d) {
            return k.step(b, c, a, d);
        };
        this.complete = function (b) {
            k.complete(b);
        };
        this.nf = function (b) {
            return b === m
                ? !0
                : 2 * Math.sqrt(b.K.ja / (Math.PI * b.e.length)) >=
                      Math.max(g.Ye, 5e-5);
        };
        this.Bd = function (b, c) {
            for (
                var a = Math.pow(g.Sa, b.R),
                    d = g.nb * a,
                    a = g.Dd * a,
                    e = b.e,
                    f = e.length - 1;
                0 <= f;
                f--
            ) {
                var m = e[f];
                k.ze(m, a);
                var r = m;
                r.aa = 0 < d ? za.eb(r.o, d) : r.o;
                r.aa && (M.q(r.aa, r.q), M.te(r.aa, r.K));
                m.e && c.push(m);
            }
        };
        this.sc = function (b) {
            k.sc(b);
        };
        this.Pb = function (b) {
            k.Pb(b);
        };
    }
    function Pa(e, m) {
        function g(a) {
            if (a.e) {
                a = a.e;
                for (var b = 0; b < a.length; b++) {
                    var c = a[b];
                    c.wc = c.tc * n.Sh;
                }
            }
        }
        function d(c, d) {
            m.nf(c) &&
                (c.u ||
                    ((c.u = za.eb(c.o, n.Dd * Math.pow(n.Sa, c.R - 1))),
                    c.u && 'flattened' == n.Va && 'stab' == n.fc && l(c)),
                c.u && (a.Pb(c), q.d(b(c), c.u, c.group), (c.N = !0), d(c)),
                g(c));
        }
        function b(a) {
            return 'stab' == n.fc && 0 < a.e.length && a.e[0].description
                ? a.e.slice(1)
                : a.e;
        }
        function f(a) {
            var c = b(a);
            Aa.S(c, a.u);
            Aa.Ac(c, a.u);
            return Ba.Eg(a) * Math.sqrt(h.K.ja / a.K.ja);
        }
        function k(a) {
            return a < n.dg || 1e-4 > a;
        }
        function l(a) {
            var b = n.ec / (1 + n.ec),
                c = M.q(a.u, {}),
                d = { x: c.x, y: 0 },
                e = c.y,
                f = c.i,
                g = n.Fe * Math.pow(n.Sa, a.R - 1),
                h = f * n.Ee,
                k = n.Kc;
            'bottom' == k || (0 <= k && 180 > k)
                ? ((k = Math.PI), (e += f), (f = -1))
                : ((k = 0), (f = 1));
            for (
                var l,
                    m = a.u,
                    p = k,
                    q = 0,
                    P = 1,
                    F = M.k(m, {}),
                    T = F.ja,
                    b = T * b,
                    N = 0;
                q < P && 20 > N++;

            ) {
                var U = (q + P) / 2;
                d.y = c.y + c.i * U;
                l = M.Yb(m, d, p);
                M.k(l[0], F);
                var t = F.ja - b;
                if (0.01 >= Math.abs(t) / T) break;
                else 0 < (0 == p ? 1 : -1) * t ? (P = U) : (q = U);
            }
            M.q(l[0], c);
            if (c.i < g || c.i > h)
                (d.y = c.i < g ? e + f * Math.min(g, h) : e + f * h),
                    (l = M.Yb(a.u, d, k));
            a.e[0].o = l[0];
            a.u = l[1];
        }
        function c(a) {
            a !== h &&
                2 * Math.sqrt(a.K.ja / (Math.PI * a.e.length)) <
                    Math.max(0.85 * n.Ye, m.Dg) &&
                ((a.N = !1), (a.Ba = !1), (a.Qa = !0), (a.u = null));
        }
        var a = this,
            h,
            n = e.options,
            q = new Ma(e),
            p = 0;
        e.c.j('model:loaded', function (a) {
            h = a;
            p = 0;
        });
        this.step = function (a, e, g, l) {
            function q(e) {
                e.N && e.Ba
                    ? c(e)
                    : e.Qa &&
                      e.o &&
                      d(e, function () {
                          var c = b(e);
                          Aa.S(c, e.u);
                          Aa.Ac(c, e.u);
                          a(e);
                      });
                if (!e.u || !e.N) return 0;
                var h;
                (e.parent && e.parent.Z) || e.Ka
                    ? ((h = f(e)), l && l(e), (e.Ka = !k(h) && !g), (e.Z = !0))
                    : (h = 0);
                m.Bd(e, B);
                return h;
            }
            function x(a, b, c) {
                p < a && (p = a);
                var d = n.dg;
                n.Vd(b ? 1 : 1 - (a - d) / (p - d || 1), b, c);
                b && (p = 0);
            }
            for (var A = 0, B = [h]; 0 < B.length; )
                A = Math.max(A, q(B.shift()));
            var K = k(A);
            e && x(A, K, g);
            return K;
        };
        this.complete = function (a) {
            for (var b = [h]; 0 < b.length; ) {
                var c = b.shift();
                !c.N && c.Qa && c.o && d(c, a);
                if (c.u) {
                    if ((c.parent && c.parent.Z) || c.Ka) {
                        for (
                            var e = 1e-4 > c.K.ja, g = 0;
                            !(k(f(c)) || (e && 32 < g++));

                        );
                        c.Z = !0;
                        c.Ka = !1;
                    }
                    m.Bd(c, b);
                }
            }
        };
        this.sc = function (a) {
            $.F(a, g);
        };
        this.ze = function (a, c) {
            if (a.N) {
                var d = a.u;
                d && (a.$d = d);
                a.u = za.eb(a.o, c);
                a.u && 'flattened' == n.Va && 'stab' == n.fc && l(a);
                d && !a.u && (a.Z = !0);
                a.u && a.$d && M.Cc(b(a), a.$d, a.u);
            }
        };
        this.Pb = function (a) {
            for (var c = b(a), d = a.ja, e, f = (e = 0); f < c.length; f++)
                e += c[f].T;
            a.Zj = e;
            for (a = 0; a < c.length; a++)
                (f = c[a]),
                    (f.rg = f.f),
                    (f.tc = (d / Math.PI) * (0 < e ? f.T / e : 1 / c.length));
        };
    }
    function Qa(e, m) {
        function g(a, c) {
            if (m.nf(a)) {
                if (!a.u || (a.parent && a.parent.Z)) {
                    var d = l.Dd * Math.pow(l.Sa, a.R - 1);
                    a.u = M.A(b(M.q(a.o, {}), d));
                }
                a.u && ((a.N = !0), c(a));
            } else
                (a.N = !1),
                    $.Ea(a, function (a) {
                        a.u = null;
                    });
        }
        function d(a) {
            function b(a) {
                function c() {
                    e.o = M.A(f);
                    e.x = f.x + f.f / 2;
                    e.y = f.y + f.i / 2;
                }
                var d = l.ec / (1 + l.ec),
                    e = a.e[0],
                    f = M.q(a.u, {}),
                    g = f.i,
                    d = Math.min(
                        Math.max(g * d, l.Fe * Math.pow(l.Sa, a.R - 1)),
                        g * l.Ee,
                    ),
                    h = l.Kc;
                'bottom' == h || (0 <= h && 180 > h)
                    ? ((f.i = g - d),
                      (a.u = M.A(f)),
                      (f.y += g - d),
                      (f.i = d),
                      c())
                    : ((f.i = d),
                      c(),
                      (f.y += d),
                      (f.i = g - d),
                      (a.u = M.A(f)));
            }
            var d;
            'stab' == l.fc && 0 < a.e.length && a.e[0].description
                ? ((d = a.e.slice(1)), b(a))
                : (d = a.e);
            l.nc && d.sort(Na);
            'floating' == l.fc &&
                f(d, l.Kc, function (a) {
                    return a.description;
                });
            f(d, l.xe, function (a) {
                return a.Ra;
            });
            var e = M.q(a.u, {});
            (c[l.Yc] || Ca.Zb)(d, e, !0, l.fe);
            a.Ka = !1;
            a.Z = !0;
            a.I = !0;
            a.Ma = !0;
        }
        function b(a, b) {
            var c = 2 * b;
            a.x += b;
            a.y += b;
            a.f -= c;
            a.i -= c;
            return a;
        }
        function f(a, b, c) {
            for (var d = 0; d < a.length; d++) {
                var e = a[d];
                if (c(e)) {
                    a.splice(d, 1);
                    'topleft' == b || (135 <= b && 315 > b)
                        ? a.unshift(e)
                        : a.push(e);
                    break;
                }
            }
        }
        var k,
            l = e.options,
            c = { squarified: Ca.ve, ordered: Ca.Zb };
        e.c.j('model:loaded', function (a) {
            k = a;
        });
        this.step = function (a, b, c) {
            this.complete(a);
            b && l.Vd(1, !0, c);
            return !0;
        };
        this.complete = function (a) {
            for (var b = [k]; 0 < b.length; ) {
                var c = b.shift();
                (!c.N || (c.parent && c.parent.Z)) && c.Qa && c.o && g(c, a);
                c.u && (((c.parent && c.parent.Z) || c.Ka) && d(c), m.Bd(c, b));
            }
        };
        this.Pb = this.sc = this.ze = D.sa;
    }
    function Ra(e, m) {
        function g(a, b) {
            var c = a.K.Qb,
                d = c / 15,
                e = (0.5 * c) / 15,
                c = c / 5,
                f = a.K.x,
                g = a.K.y;
            b.fillRect(f - e, g - e, d, d);
            b.fillRect(f - e - c, g - e, d, d);
            b.fillRect(f - e + c, g - e, d, d);
        }
        function d(a, b, c, d) {
            null === a && c.clearRect(0, 0, H, Q);
            var e,
                f = Array(W.length);
            for (e = W.length - 1; 0 <= e; e--) f[e] = W[e].pa(c, d);
            for (e = W.length - 1; 0 <= e; e--) f[e] && W[e].W(c, d);
            T.Lc([c, C], function (d) {
                var e;
                if (null !== a) {
                    c.save();
                    c.globalCompositeOperation = 'destination-out';
                    c.fillStyle = c.strokeStyle = 'rgba(255, 255, 255, 1)';
                    for (e = a.length - 1; 0 <= e; e--) {
                        var g = a[e],
                            h = g.o;
                        h &&
                            (c.save(),
                            c.beginPath(),
                            g.Vb(c),
                            ia.oe(c, h),
                            c.fill(),
                            (g = t.nb * Math.pow(t.Sa, g.R - 1)),
                            0 < g && ((c.lineWidth = g / 2), c.stroke()),
                            c.restore());
                    }
                    c.restore();
                }
                d = d.scale;
                if (0 !== b.length) {
                    e = {};
                    for (h = W.length - 1; 0 <= h; h--) W[h].Og(e);
                    for (g = Z.length - 1; 0 <= g; g--)
                        if (((h = Z[g]), e[h.id]))
                            for (var k = h.ee, h = 0; h < b.length; h++) {
                                var l = b[h];
                                !l.parent || (l.parent.Ba && l.parent.N)
                                    ? k(l, d)
                                    : l.ba.clear();
                            }
                }
                for (e = W.length - 1; 0 <= e; e--)
                    (g = W[e]), f[e] && g.he(b, c, d);
            });
            for (e = W.length - 1; 0 <= e; e--) f[e] && W[e].Ca(c);
            t.td &&
                ((c.canvas.style.opacity = 0.99),
                setTimeout(function () {
                    c.canvas.style.opacity = 1;
                }, 1));
        }
        function b(a) {
            s === u
                ? a < 0.9 * q && ((s = w), (x = A), c())
                : a >= q && ((s = u), (x = B), c());
        }
        function f() {
            function a(b, c, d) {
                b.Eb = Math.floor(1e3 * b.scale) - d * c;
                0 < b.opacity && !b.open && c++;
                var e = b.e;
                if (e)
                    for (var f = e.length - 1; 0 <= f; f--)
                        b.$ && a(e[f], c, d);
            }
            var c = null,
                d = null,
                e = null;
            T.Lc([], function (f) {
                b(f.scale);
                var g = !1;
                $.F(z, function (a) {
                    a.$ && ((g = a.Qd() || g), a.pc(), (a.Ta = P.d(a) || a.Ta));
                });
                g && (z.I = !0);
                var h = 'onSurfaceDirty' === t.ph;
                $.Ad(z, function (a) {
                    a.parent &&
                        a.parent.Z &&
                        (a.ba.clear(),
                        (a.Ta = !0),
                        h || ((a.Hc = !0), a.cc.clear()));
                    h && ((a.Hc = !0), a.cc.clear());
                });
                var l = f.scale * f.scale;
                $.Ad(z, function (a) {
                    if (a.N) {
                        for (var b = a.e, c = 0; c < b.length; c++)
                            if (5 < b[c].K.ja * l) {
                                a.X = !0;
                                return;
                            }
                        a.X = !1;
                    }
                });
                n(f);
                e = [];
                $.Nc(z, function (a) {
                    if (a.parent.X && a.ea && a.$) {
                        e.push(a);
                        for (
                            var b = a.parent;
                            b !== z && (b.open || 0 === b.opacity);

                        )
                            b = b.parent;
                        b !== z &&
                            0.02 > Math.abs(b.scale - a.scale) &&
                            (a.scale = Math.min(a.scale, b.scale));
                    }
                });
                a(z, 0, 'flattened' == t.Va ? -1 : 1);
                e.sort(function (a, b) {
                    return a.Eb - b.Eb;
                });
                if (k()) (c = e), (d = null);
                else {
                    var m = {},
                        p = {},
                        q = 'none' != t.Fd && t.nb < t.ob / 2,
                        r = t.nb < t.Tc / 2 + t.Ed * t.$e.a;
                    $.F(z, function (a) {
                        if (
                            a.$ &&
                            !a.description &&
                            (a.Z || a.I || (a.$c && a.parent.X && a.Ta))
                        ) {
                            var b,
                                c,
                                d = [a],
                                e = a.C || a.parent.e;
                            if (q)
                                for (b = 0; b < e.length; b++)
                                    (c = e[b]) && d.push(c);
                            else if (r)
                                if (!a.selected && a.bb) {
                                    c = !0;
                                    for (b = 0; b < e.length; b++)
                                        e[b] ? d.push(e[b]) : (c = !1);
                                    !c && 1 < a.R && d.push(a.parent);
                                } else
                                    for (b = 0; b < e.length; b++)
                                        (c = e[b]) && c.selected && d.push(c);
                            var f;
                            for (b = a.parent; b != z; )
                                b.selected && (f = b), (b = b.parent);
                            f && d.push(f);
                            for (b = 0; b < d.length; b++) {
                                f = d[b];
                                for (a = f.parent; a && a !== z; )
                                    0 < a.opacity && (f = a), (a = a.parent);
                                p[f.id] = !0;
                                $.Ea(f, function (a) {
                                    m[a.id] = !0;
                                });
                            }
                        }
                    });
                    c = e.filter(function (a) {
                        return m[a.id];
                    });
                    d = c.filter(function (a) {
                        return p[a.id];
                    });
                }
            });
            (function () {
                var a = !1;
                t.cg &&
                    $.F(z, function (b) {
                        if (b.$ && 0 !== b.ra.a && 1 !== b.ra.a)
                            return (a = !0), !1;
                    });
                a
                    ? ($.Mc(z, function (a) {
                          if (a.$ && (a.opacity !== a.dd || a.Ma)) {
                              var b = a.e;
                              if (b) {
                                  for (var c = 0, d = b.length - 1; 0 <= d; d--)
                                      c = Math.max(c, b[d].Zc);
                                  a.Zc = c + a.opacity * a.ra.a;
                              } else a.Zc = a.opacity * a.ra.a;
                          }
                      }),
                      $.F(z, function (a) {
                          if (a.$ && (a.opacity !== a.dd || a.Ma)) {
                              for (
                                  var b = a.Zc, c = a;
                                  (c = c.parent) && c !== z;

                              )
                                  b += c.opacity * c.ra.a * t.ag;
                              a.vd =
                                  0 < b ? 1 - Math.pow(1 - a.ra.a, 1 / b) : 0;
                              a.dd = a.opacity;
                          }
                      }))
                    : $.F(z, function (a) {
                          a.$ && ((a.vd = 1), (a.dd = -1));
                      });
            })();
            return { xg: c, wg: d, ea: e };
        }
        function k() {
            var a = z.Z || z.I || 'none' == t.hf;
            if (!a && !z.empty()) {
                var b = z.e[0].scale;
                $.F(z, function (c) {
                    if (c.$ && c.ea && c.scale !== b) return (a = !0), !1;
                });
            }
            !a &&
                0 < t.Ue &&
                1 != t.Ya &&
                $.F(z, function (b) {
                    if (b.$ && 0 < b.ka) return (a = !0), !1;
                });
            'accurate' == t.hf &&
                ((a =
                    (a = a || 0 === t.nb) ||
                    ('none' != t.Fd && t.nb < t.ob / 2)),
                !a &&
                    t.nb < t.Tc / 2 + t.Ed * t.$e.a &&
                    $.F(z, function (b) {
                        if (
                            b.$ &&
                            ((b.selected && !b.bb) || (!b.selected && b.bb))
                        )
                            return (a = !0), !1;
                    }));
            return a;
        }
        function l() {
            if (t.n !== t.zb) return !0;
            var a =
                'polygonPlainFill polygonPlainStroke polygonGradientFill polygonGradientStroke labelPlainFill contentDecoration'.split(
                    ' ',
                );
            $.F(z, function (b) {
                if (b.$ && b.U) return a.push('polygonExposureShadow'), !1;
            });
            for (var b = a.length - 1; 0 <= b; b--) {
                var c = a[b];
                if (!!E[c] !== !!J[c]) return !0;
            }
            return !1;
        }
        function c() {
            function a(c, d, e, f, g) {
                function h(a, b, c, d, e) {
                    a[d] &&
                        ((b -= c * p[d]),
                        (a[d] = !1),
                        e && ((b += c * p[e]), (a[e] = !0)));
                    return b;
                }
                c = D.extend({}, c);
                switch (e) {
                    case 'never':
                        c.labelPlainFill = !1;
                        break;
                    case 'always':
                    case 'auto':
                        c.labelPlainFill = !0;
                }
                if (t.Rc)
                    switch (f) {
                        case 'never':
                            c.contentDecoration = !1;
                            break;
                        case 'always':
                        case 'auto':
                            c.contentDecoration = !0;
                    }
                else c.contentDecoration = !1;
                var k = 0;
                D.Fa(c, function (a, b) {
                    a &&
                        (k +=
                            d *
                            p[
                                'contentDecoration' === b ? 'labelPlainFill' : b
                            ]);
                });
                c.polygonExposureShadow = b;
                k += 2 * p.polygonExposureShadow;
                if (
                    k <= g ||
                    (k = h(c, k, 2, 'polygonExposureShadow')) <= g ||
                    (k = h(
                        c,
                        k,
                        d,
                        'polygonGradientFill',
                        'polygonPlainFill',
                    )) <= g ||
                    (k = h(c, k, d, 'polygonGradientStroke')) <= g ||
                    (k = h(c, k, d, 'polygonPlainStroke')) <= g ||
                    ('auto' === f && (k = h(c, k, d, 'contentDecoration')) <= g)
                )
                    return c;
                'auto' === e && (k = h(c, k, d, 'labelPlainFill'));
                return c;
            }
            var b = s === w,
                c = 0,
                d = 0;
            $.Ke(z, function (a) {
                var b = 1;
                $.F(a, function () {
                    b++;
                });
                c += b;
                d = Math.max(d, b);
            });
            var e = {};
            switch (t.yh) {
                case 'plain':
                    e.polygonPlainFill = !0;
                    break;
                case 'gradient':
                    (e.polygonPlainFill = !b), (e.polygonGradientFill = b);
            }
            switch (t.Fd) {
                case 'plain':
                    e.polygonPlainStroke = !0;
                    break;
                case 'gradient':
                    (e.polygonPlainStroke = !b), (e.polygonGradientStroke = b);
            }
            E = a(e, c, t.Ij, t.Gj, t.Hj);
            J = a(e, 2 * d, 'always', 'always', t.ih);
            I = a(e, c, 'always', 'always', t.hh);
        }
        function a(a) {
            return function (b, c) {
                return b === s ? !0 === E[a] : !0 === (c ? J : I)[a];
            };
        }
        function h(a, b) {
            return function (c, d) {
                return a(c, d) && b(c, d);
            };
        }
        function n(a) {
            z.ea = !0;
            $.Ad(z, function (b) {
                if (b.$ && b.X && b.Ba && b.N && (z.I || b.Z || b.pe)) {
                    b.pe = !1;
                    var c = b.e,
                        d = { x: 0, y: 0, f: 0, i: 0 },
                        e = !!b.u;
                    if (1 < H / a.f) {
                        var f;
                        for (f = c.length - 1; 0 <= f; f--) c[f].ea = !1;
                        if (b.ea && e)
                            for (f = c.length - 1; 0 <= f; f--)
                                if (
                                    ((b = c[f]),
                                    1 !== b.scale &&
                                        (b.Xb(a, d),
                                        (d.f = a.f / b.scale),
                                        (d.i = a.i / b.scale)),
                                    !1 === b.ea && b.o)
                                ) {
                                    var e = b.o,
                                        g = e.length;
                                    if (M.Wa(b.o, 1 === b.scale ? a : d))
                                        b.ea = !0;
                                    else
                                        for (var h = 0; h < g; h++)
                                            if (
                                                M.Mg(
                                                    e[h],
                                                    e[(h + 1) % g],
                                                    1 === b.scale ? a : d,
                                                )
                                            ) {
                                                b.ea = !0;
                                                b.C &&
                                                    (b = b.C[h]) &&
                                                    (c[b.index].ea = !0);
                                                break;
                                            }
                                }
                    } else for (f = 0; f < c.length; f++) c[f].ea = e;
                }
            });
        }
        var q = v.qf() ? 50 : 1e4,
            p,
            r,
            s,
            w,
            u,
            y,
            x,
            A,
            B,
            K,
            C,
            H,
            Q,
            O,
            P = new Sa(e),
            F = new Ta(e),
            T,
            N,
            U,
            t = e.options,
            z,
            L,
            E,
            I,
            J;
        e.c.j('stage:initialized', function (a, b, c, d) {
            O = a;
            H = c;
            Q = d;
            r = O.qc('wireframe', t.zb, !1);
            w = r.getContext('2d');
            u = new ha(w);
            y = O.qc('hifi', t.n, !1);
            A = y.getContext('2d');
            B = new ha(A);
            s = w;
            x = A;
            w.n = t.zb;
            u.n = t.zb;
            A.n = t.n;
            B.n = t.n;
            K = O.qc('tmp', Math.max(t.n, t.zb), !0);
            C = K.getContext('2d');
            C.n = 1;
            [w, A, C].forEach(function (a) {
                a.scale(a.n, a.n);
            });
        });
        e.c.j('stage:resized', function (a, b, c, d) {
            H = c;
            Q = d;
            [w, A, C].forEach(function (a) {
                a.scale(a.n, a.n);
            });
        });
        e.c.j('model:loaded', function (a) {
            function b(a) {
                var c = 0;
                if (!a.empty()) {
                    for (var d = a.e, e = d.length - 1; 0 <= e; e--)
                        c = Math.max(c, b(d[e]));
                    c += 1;
                }
                return (a.og = c);
            }
            z = a;
            L = !0;
            b(z);
            c();
            e.c.p('render:renderers:resolved', E, J, I);
        });
        var R =
                'groupFillType groupStrokeType wireframeDrawMaxDuration wireframeLabelDrawing wireframeContentDecorationDrawing finalCompleteDrawMaxDuration finalIncrementalDrawMaxDuration groupContentDecorator'.split(
                    ' ',
                ),
            Y = [
                'groupLabelLightColor',
                'groupLabelDarkColor',
                'groupLabelColorThreshold',
                'groupUnexposureLabelColorThreshold',
            ];
        e.c.j('options:changed', function (a) {
            function b(a, c, d, e) {
                O.ij(a, d);
                c.n = d;
                e && c.scale(d, d);
            }
            a.dataObject ||
                (D.pb(a, R) && c(),
                D.pb(a, Y) &&
                    $.F(z, function (a) {
                        a.Cd = -1;
                    }));
            var d = D.M(a, 'pixelRatio');
            a = D.M(a, 'wireframePixelRatio');
            if (d || a)
                d && b(y, x, t.n, !0),
                    a && b(r, s, t.zb, !0),
                    b(K, C, Math.max(t.n, t.zb), !1);
        });
        e.c.j('zoom:initialized', function (a) {
            T = a;
        });
        e.c.j('timeline:initialized', function (a) {
            N = a;
        });
        e.c.j('api:initialized', function (a) {
            U = a;
        });
        var Z = [
                {
                    id: 'offsetPolygon',
                    ee: function (a) {
                        if (
                            (a.selected ||
                                (0 < a.opacity && !1 === a.open) ||
                                !a.X) &&
                            a.ba.Na()
                        ) {
                            var b = a.ba;
                            b.clear();
                            if (a.aa) {
                                var c = a.aa,
                                    d = t.kh;
                                0 < d
                                    ? ((d = Math.min(
                                          1,
                                          d * Math.pow(1 - t.lh * d, a.og),
                                      )),
                                      ia.uj(b, c, a.parent.K.Qb / 32, d))
                                    : ia.oe(b, c);
                            }
                            a.Yd = !0;
                        }
                    },
                },
                {
                    id: 'label',
                    ee: function (a) {
                        a.Ta && a.$c && P.k(a);
                    },
                },
                {
                    id: 'custom',
                    ee: function (a, b) {
                        if (
                            a.aa &&
                            ((0 < a.opacity &&
                                (!1 === a.open || !0 === a.selected)) ||
                                !a.X) &&
                            a.Hc &&
                            e.options.Rc
                        ) {
                            var c = {};
                            U.rd(c, a);
                            U.sd(c, a);
                            U.qd(c, a, !0);
                            c.context = a.cc;
                            c.polygonContext = a.ba;
                            c.labelContext = a.Wc;
                            c.shapeDirty = a.Yd;
                            c.viewportScale = b;
                            var d = {
                                groupLabelDrawn: !0,
                                groupPolygonDrawn: !0,
                            };
                            e.options.oh(e.Xd, c, d);
                            d.groupLabelDrawn || (a.rf = !1);
                            d.groupPolygonDrawn || (a.Zd = !1);
                            a.Yd = !1;
                            a.Hc = !1;
                        }
                    },
                },
            ].reverse(),
            W = [
                new (function (a) {
                    var b = Array(a.length);
                    this.he = function (c, d, e) {
                        if (0 !== c.length) {
                            var f,
                                g,
                                h = [],
                                k = c[0].Eb;
                            for (f = 0; f < c.length; f++)
                                (g = c[f]),
                                    g.Eb !== k && (h.push(f), (k = g.Eb));
                            h.push(f);
                            for (var l = (k = 0); l < h.length; l++) {
                                for (
                                    var m = h[l], n = a.length - 1;
                                    0 <= n;
                                    n--
                                )
                                    if (b[n]) {
                                        var p = a[n];
                                        d.save();
                                        for (f = k; f < m; f++)
                                            (g = c[f]),
                                                d.save(),
                                                g.Vb(d),
                                                p.xb.call(p, g, d, e),
                                                d.restore();
                                        p.jb.call(p, d, e);
                                        d.restore();
                                    }
                                k = m;
                            }
                        }
                    };
                    this.pa = function (c, d) {
                        for (var e = !1, f = a.length - 1; 0 <= f; f--)
                            (b[f] = a[f].pa(c, d)), (e |= b[f]);
                        return e;
                    };
                    this.W = function (c, d) {
                        for (var e = a.length - 1; 0 <= e; e--)
                            if (b[e]) {
                                var f = a[e];
                                f.W.call(f, c, d);
                            }
                    };
                    this.Ca = function (c) {
                        for (var d = a.length - 1; 0 <= d; d--)
                            if (b[d]) {
                                var e = a[d];
                                e.Ca.call(e, c);
                            }
                    };
                    this.Og = function (c) {
                        for (var d = a.length - 1; 0 <= d; d--) {
                            var e = a[d];
                            if (b[d])
                                for (var f = e.ab.length - 1; 0 <= f; f--)
                                    c[e.ab[f]] = !0;
                        }
                    };
                })(
                    [
                        {
                            ab: ['offsetPolygon'],
                            pa: a('polygonExposureShadow'),
                            W: function (a) {
                                C.save();
                                C.scale(a.n, a.n);
                            },
                            Ca: function () {
                                C.restore();
                            },
                            d: function () {},
                            jb: function (a) {
                                this.ng &&
                                    ((this.ng = !1),
                                    a.save(),
                                    a.setTransform(1, 0, 0, 1, 0, 0),
                                    a.drawImage(
                                        K,
                                        0,
                                        0,
                                        a.canvas.width,
                                        a.canvas.height,
                                        0,
                                        0,
                                        a.canvas.width,
                                        a.canvas.height,
                                    ),
                                    a.restore(),
                                    C.save(),
                                    C.setTransform(1, 0, 0, 1, 0, 0),
                                    C.clearRect(0, 0, K.width, K.height),
                                    C.restore());
                            },
                            xb: function (a, b, c) {
                                if (!((a.open && a.X) || a.ba.Na())) {
                                    var d =
                                        t.Ue *
                                        a.opacity *
                                        a.ka *
                                        ('flattened' == t.Va
                                            ? 1 - a.parent.ka
                                            : (1 - a.Nb) * a.parent.Nb) *
                                        (1.1 <= t.Ya ? 1 : (t.Ya - 1) / 0.1);
                                    0 < d &&
                                        (C.save(),
                                        C.beginPath(),
                                        a.Vb(C),
                                        a.ba.Ua(C),
                                        (C.shadowBlur = c * b.n * d),
                                        (C.shadowColor = t.qh),
                                        (C.fillStyle = 'rgba(0, 0, 0, 1)'),
                                        (C.globalCompositeOperation =
                                            'source-over'),
                                        (C.globalAlpha = a.opacity),
                                        C.fill(),
                                        (C.shadowBlur = 0),
                                        (C.shadowColor = 'transparent'),
                                        (C.globalCompositeOperation =
                                            'destination-out'),
                                        C.fill(),
                                        C.restore(),
                                        (this.ng = !0));
                                }
                            },
                        },
                        {
                            ab: ['offsetPolygon'],
                            pa: function () {
                                return !0;
                            },
                            W: (function () {
                                function b(a) {
                                    var d = a.ra,
                                        e = a.Gb,
                                        f = a.selected,
                                        g = c(
                                            d.l * a.ua +
                                                (e ? t.Dh : 0) +
                                                (f ? t.Uh : 0),
                                        ),
                                        h = c(
                                            d.s * a.va +
                                                (e ? t.Eh : 0) +
                                                (f ? t.Vh : 0),
                                        );
                                    a = a.Te;
                                    a.h =
                                        (d.h +
                                            (e ? t.Ch : 0) +
                                            (f ? t.Th : 0)) %
                                        360;
                                    a.s = h;
                                    a.l = g;
                                    return a;
                                }
                                function c(a) {
                                    return 100 < a ? 100 : 0 > a ? 0 : a;
                                }
                                var d = [
                                        {
                                            type: 'fill',
                                            pa: a('polygonPlainFill'),
                                            kd: function (a, c) {
                                                c.fillStyle = S.Bc(b(a));
                                            },
                                        },
                                        {
                                            type: 'fill',
                                            pa: a('polygonGradientFill'),
                                            kd: function (a, d) {
                                                var e = a.K.Qb,
                                                    f = b(a),
                                                    e = d.createRadialGradient(
                                                        a.x,
                                                        a.y,
                                                        0,
                                                        a.x,
                                                        a.y,
                                                        e * t.uh,
                                                    );
                                                e.addColorStop(
                                                    0,
                                                    S.Y(
                                                        (f.h + t.rh) % 360,
                                                        c(f.s + t.th),
                                                        c(f.l + t.sh),
                                                    ),
                                                );
                                                e.addColorStop(
                                                    1,
                                                    S.Y(
                                                        (f.h + t.vh) % 360,
                                                        c(f.s + t.xh),
                                                        c(f.l + t.wh),
                                                    ),
                                                );
                                                a.ba.Ua(d);
                                                d.fillStyle = e;
                                            },
                                        },
                                        {
                                            type: 'stroke',
                                            pa: h(
                                                a('polygonPlainStroke'),
                                                function () {
                                                    return 0 < t.ob;
                                                },
                                            ),
                                            kd: function (a, b) {
                                                var d = a.ra,
                                                    e = a.Gb,
                                                    f = a.selected;
                                                b.strokeStyle = S.Y(
                                                    (d.h +
                                                        t.df +
                                                        (e ? t.Ve : 0) +
                                                        (f ? t.af : 0)) %
                                                        360,
                                                    c(
                                                        d.s * a.va +
                                                            t.ff +
                                                            (e ? t.Xe : 0) +
                                                            (f ? t.cf : 0),
                                                    ),
                                                    c(
                                                        d.l * a.ua +
                                                            t.ef +
                                                            (e ? t.We : 0) +
                                                            (f ? t.bf : 0),
                                                    ),
                                                );
                                                b.lineWidth =
                                                    t.ob *
                                                    Math.pow(t.Sa, a.R - 1);
                                            },
                                        },
                                        {
                                            type: 'stroke',
                                            pa: h(
                                                a('polygonGradientStroke'),
                                                function () {
                                                    return 0 < t.ob;
                                                },
                                            ),
                                            kd: function (a, b) {
                                                var d = a.K.Qb * t.ai,
                                                    e = a.ra,
                                                    f = (Math.PI * t.Xh) / 180,
                                                    d = b.createLinearGradient(
                                                        a.x + d * Math.cos(f),
                                                        a.y + d * Math.sin(f),
                                                        a.x +
                                                            d *
                                                                Math.cos(
                                                                    f + Math.PI,
                                                                ),
                                                        a.y +
                                                            d *
                                                                Math.sin(
                                                                    f + Math.PI,
                                                                ),
                                                    ),
                                                    g = a.Gb,
                                                    h = a.selected,
                                                    f =
                                                        (e.h +
                                                            t.df +
                                                            (g ? t.Ve : 0) +
                                                            (h ? t.af : 0)) %
                                                        360,
                                                    k = c(
                                                        e.s * a.va +
                                                            t.ff +
                                                            (g ? t.Xe : 0) +
                                                            (h ? t.cf : 0),
                                                    ),
                                                    e = c(
                                                        e.l * a.ua +
                                                            t.ef +
                                                            (g ? t.We : 0) +
                                                            (h ? t.bf : 0),
                                                    );
                                                d.addColorStop(
                                                    0,
                                                    S.Y(
                                                        (f + t.Yh) % 360,
                                                        c(k + t.$h),
                                                        c(e + t.Zh),
                                                    ),
                                                );
                                                d.addColorStop(
                                                    1,
                                                    S.Y(
                                                        (f + t.bi) % 360,
                                                        c(k + t.di),
                                                        c(e + t.ci),
                                                    ),
                                                );
                                                b.strokeStyle = d;
                                                b.lineWidth =
                                                    t.ob *
                                                    Math.pow(t.Sa, a.R - 1);
                                            },
                                        },
                                    ],
                                    e = Array(d.length);
                                return function (a, b) {
                                    for (var c = d.length - 1; 0 <= c; c--)
                                        e[c] = d[c].pa(a, b);
                                    this.yj = d;
                                    this.Yg = e;
                                };
                            })(),
                            Ca: function () {},
                            d: function () {},
                            jb: function () {},
                            xb: function (a, b) {
                                if (
                                    a.Zd &&
                                    !(
                                        ((0 === a.opacity || a.open) && a.X) ||
                                        a.ba.Na() ||
                                        (!t.Ge && a.description)
                                    )
                                ) {
                                    var c = this.yj,
                                        d = this.Yg;
                                    b.beginPath();
                                    a.ba.Ua(b);
                                    for (
                                        var e = !1, f = !1, g = c.length - 1;
                                        0 <= g;
                                        g--
                                    ) {
                                        var h = c[g];
                                        if (d[g])
                                            switch ((h.kd(a, b), h.type)) {
                                                case 'fill':
                                                    e = !0;
                                                    break;
                                                case 'stroke':
                                                    f = !0;
                                            }
                                    }
                                    c = (a.X ? a.opacity : 1) * a.ra.a;
                                    d = !a.empty();
                                    g = t.cg ? a.vd : 1;
                                    e &&
                                        ((e =
                                            d && a.X && a.N && a.e[0].$
                                                ? 1 -
                                                  (a.e.reduce(function (a, b) {
                                                      return a + b.ta * b.be;
                                                  }, 0) /
                                                      a.e.length) *
                                                      (1 - t.ag)
                                                : 1),
                                        (b.globalAlpha = c * e * g),
                                        Ua(b));
                                    f &&
                                        ((b.globalAlpha =
                                            c * (d ? t.zi : 1) * g),
                                        b.closePath(),
                                        Va(b),
                                        b.stroke());
                                }
                            },
                        },
                        {
                            ab: ['offsetPolygon'],
                            pa: function () {
                                return 0 < t.Tc;
                            },
                            W: function () {},
                            Ca: function () {},
                            d: function () {},
                            jb: function () {},
                            xb: function (a, b, c) {
                                if (a.Zd && a.selected && !a.ba.Na()) {
                                    b.globalAlpha = a.Ja;
                                    b.beginPath();
                                    var d = Math.pow(t.Sa, a.R - 1);
                                    b.lineWidth = t.Tc * d;
                                    b.strokeStyle = t.Wh;
                                    var e = t.Ed;
                                    0 < e &&
                                        ((b.shadowBlur = e * d * c * b.n),
                                        (b.shadowColor = t.Ze));
                                    a.ba.Ua(b);
                                    b.closePath();
                                    b.stroke();
                                }
                            },
                        },
                        {
                            ab: [],
                            pa: function () {
                                return t.La;
                            },
                            W: function () {},
                            Ca: function () {},
                            d: function () {},
                            jb: function () {},
                            xb: function (a, b) {
                                function c(d, e, f) {
                                    var g = M.ue(a.aa, a.K, d / e),
                                        h = t.Tg,
                                        g = Math.min(
                                            Math.min(0.9 * g, h * a.q.i) / e,
                                            (h * a.q.f) / d,
                                        );
                                    b.save();
                                    b.translate(a.x, a.y);
                                    b.globalAlpha = a.opacity * a.fa;
                                    b.scale(g, g);
                                    b.translate(-d / 2, -e / 2);
                                    f(b);
                                    b.restore();
                                }
                                a.Ra &&
                                    !a.ba.Na() &&
                                    t.La &&
                                    0 < t.La.width &&
                                    0 < t.La.height &&
                                    c(t.La.width, t.La.height, function (a) {
                                        a.drawImage(t.La, 0, 0);
                                    });
                            },
                        },
                        {
                            ab: [],
                            pa: (function (a, b) {
                                return function (c, d) {
                                    return a(c, d) || b(c, d);
                                };
                            })(
                                a('labelPlainFill'),
                                h(a('contentDecoration'), function () {
                                    return t.Rc;
                                }),
                            ),
                            W: function () {},
                            Ca: function () {},
                            d: function () {},
                            jb: function () {},
                            xb: function (a, b, c) {
                                ((0 < a.opacity && 0 < a.fa && !a.open) ||
                                    !a.X) &&
                                    !a.ba.Na() &&
                                    ((a.Xc =
                                        a.qa &&
                                        a.qa.la &&
                                        t.n * a.qa.fontSize * a.scale * c >=
                                            t.Qh),
                                    !t.Ge && a.description
                                        ? (a.sb = a.parent.sb)
                                        : 'auto' === a.Kd
                                        ? ((b = a.Te),
                                          (c = b.h + (b.s << 9) + (b.l << 16)),
                                          a.Cd !== c &&
                                              ((a.sb =
                                                  S.Cg(b) >
                                                  (0 > a.ka ? t.ei : t.Fh)
                                                      ? t.Gh
                                                      : t.Ph),
                                              (a.Cd = c)))
                                        : (a.sb = a.Kd));
                            },
                        },
                        {
                            ab: ['custom'],
                            pa: h(a('contentDecoration'), function () {
                                return t.Rc;
                            }),
                            W: function () {},
                            Ca: function () {},
                            d: function () {},
                            jb: function () {},
                            xb: function (a, b) {
                                !(
                                    (0 < a.opacity && 0 < a.fa && !a.open) ||
                                    !a.X
                                ) ||
                                    a.cc.Na() ||
                                    a.ba.Na() ||
                                    (a.Xc || void 0 === a.qa
                                        ? ((b.globalAlpha =
                                              a.fa *
                                              (a.X ? a.opacity : 1) *
                                              (a.empty() ? 1 : t.bg)),
                                          (b.fillStyle = a.sb),
                                          (b.strokeStyle = a.sb),
                                          a.cc.Ua(b))
                                        : g(a, b));
                            },
                        },
                        {
                            ab: ['label'],
                            pa: a('labelPlainFill'),
                            W: function () {},
                            Ca: function () {},
                            d: function () {},
                            jb: function () {},
                            xb: function (a, b, c) {
                                a.rf &&
                                    a.$c &&
                                    ((0 < a.opacity && 0 < a.fa && !a.open) ||
                                        !a.X) &&
                                    !a.ba.Na() &&
                                    a.qa &&
                                    ((b.fillStyle = a.sb),
                                    (b.globalAlpha =
                                        a.fa *
                                        (a.X ? a.opacity : 1) *
                                        (a.empty() ? 1 : t.bg)),
                                    a.Xc ? Wa(a, b, c) : g(a, b));
                            },
                        },
                    ].reverse(),
                ),
            ];
        this.H = function () {
            p = ua.ki(
                function () {
                    return ja.fh();
                },
                'CarrotSearchFoamTree',
                12096e5,
            )(Ya());
            F.H();
        };
        this.clear = function () {
            s.clearRect(0, 0, H, Q);
            x.clearRect(0, 0, H, Q);
        };
        var ea = !1,
            ba = void 0;
        this.k = function (a) {
            ea ? (ba = a) : a();
        };
        this.he = (function () {
            function a() {
                window.clearTimeout(b);
                ea = !0;
                b = setTimeout(function () {
                    ea = !1;
                    if (l()) {
                        var a = !k();
                        d(null, c.ea, x, a);
                        D.defer(function () {
                            ca.vj();
                            ba && (ba(), (ba = void 0));
                        });
                    }
                }, Math.max(t.Jj, 3 * m.sg.Nd, 3 * m.sg.Md));
            }
            var b, c;
            return function (b) {
                Za(F);
                c = f();
                var e = null !== c.wg,
                    g = 0 < O.mc('hifi'),
                    h = g && (e || !b);
                b = e || L || !b;
                L = !1;
                g && !h && ca.wj();
                d(c.wg, c.xg, h ? x : s, b);
                $.Ea(z, function (a) {
                    a.Z = !1;
                    a.I = !1;
                    a.bb = !1;
                });
                h || a();
                t.Xf(e);
            };
        })();
        this.d = function (a) {
            a = a || {};
            Za(F);
            z.I = !0;
            var b = f(),
                c = t.n;
            try {
                var e = D.B(a.pixelRatio, t.n);
                t.n = e;
                var g = O.qc('export', e, !0),
                    h = g.getContext('2d');
                s === u && (h = new ha(h));
                h.scale(e, e);
                var k = D.M(a, 'backgroundColor');
                k &&
                    (h.save(),
                    (h.fillStyle = a.backgroundColor),
                    h.fillRect(0, 0, H, Q),
                    h.restore());
                d(k ? [] : null, b.xg, h, !0);
            } finally {
                t.n = c;
            }
            return g.toDataURL(D.B(a.format, 'image/png'), D.B(a.quality, 0.8));
        };
        var ca = (function () {
            function a(b, d, e, f) {
                function g(a, b, c, d) {
                    return N.D.m({ opacity: O.mc(a) })
                        .ia({
                            duration: c,
                            G: { opacity: { end: b, Q: d } },
                            ca: function () {
                                O.mc(a, this.opacity);
                            },
                        })
                        .wa();
                }
                var h = D.Id(O.mc(b), 1),
                    k = D.Id(O.mc(e), 0);
                if (!h || !k) {
                    for (var l = c.length - 1; 0 <= l; l--) c[l].stop();
                    c = [];
                    h || c.push(g(b, 1, d, X.Tb));
                    k || c.push(g(e, 0, f, X.pg));
                    return N.D.m({}).$a(c).start();
                }
            }
            var b,
                c = [];
            return {
                wj: function () {
                    t.td
                        ? 1 !== r.style.opacity &&
                          ((r.style.visibility = 'visible'),
                          (y.style.visibility = 'hidden'),
                          (r.style.opacity = 1),
                          (y.style.opacity = 0))
                        : (b && b.Ib()) ||
                          (b = a('wireframe', t.Pe, 'hifi', t.Pe));
                },
                vj: function () {
                    t.td
                        ? ((y.style.visibility = 'visible'),
                          (r.style.visibility = 'hidden'),
                          (r.style.opacity = 0),
                          (y.style.opacity = 1))
                        : a('hifi', t.zg, 'wireframe', t.zg);
                },
            };
        })();
        Za = function (a) {
            a.apply();
        };
        Ua = function (a) {
            a.fill();
        };
        Va = function (a) {
            a.stroke();
        };
        return this;
    }
    var Ua, Va, Za;
    function Sa(e) {
        function m(a) {
            return d.Oh
                ? ((c.fontFamily = b.fontFamily),
                  (c.fontStyle = b.fontStyle),
                  (c.fontVariant = b.fontVariant),
                  (c.fontWeight = b.fontWeight),
                  (c.lineHeight = b.lineHeight),
                  (c.horizontalPadding = b.qb),
                  (c.verticalPadding = b.fb),
                  (c.maxTotalTextHeight = b.ub),
                  (c.maxFontSize = b.tb),
                  k.Fc(d.Nh, a, c),
                  (f.fontFamily = c.fontFamily),
                  (f.fontStyle = c.fontStyle),
                  (f.fontVariant = c.fontVariant),
                  (f.fontWeight = c.fontWeight),
                  (f.lineHeight = c.lineHeight),
                  (f.qb = c.horizontalPadding),
                  (f.fb = c.verticalPadding),
                  (f.ub = c.maxTotalTextHeight),
                  (f.tb = c.maxFontSize),
                  f)
                : b;
        }
        function g(a) {
            'undefined' !== typeof a.groupLabelFontFamily &&
                (b.fontFamily = a.groupLabelFontFamily);
            'undefined' !== typeof a.groupLabelFontStyle &&
                (b.fontStyle = a.groupLabelFontStyle);
            'undefined' !== typeof a.groupLabelFontVariant &&
                (b.fontVariant = a.groupLabelFontVariant);
            'undefined' !== typeof a.groupLabelFontWeight &&
                (b.fontWeight = a.groupLabelFontWeight);
            'undefined' !== typeof a.groupLabelLineHeight &&
                (b.lineHeight = a.groupLabelLineHeight);
            'undefined' !== typeof a.groupLabelHorizontalPadding &&
                (b.qb = a.groupLabelHorizontalPadding);
            'undefined' !== typeof a.groupLabelVerticalPadding &&
                (b.fb = a.groupLabelVerticalPadding);
            'undefined' !== typeof a.groupLabelMaxTotalHeight &&
                (b.ub = a.groupLabelMaxTotalHeight);
            'undefined' !== typeof a.groupLabelMaxFontSize &&
                (b.tb = a.groupLabelMaxFontSize);
        }
        var d = e.options,
            b = {},
            f = {},
            k,
            l = { groupLabel: '' },
            c = {};
        e.c.j('api:initialized', function (a) {
            k = a;
        });
        e.c.j('options:changed', g);
        g(e.Xd);
        this.d = function (a) {
            if (!a.aa) return !1;
            var b = a.group.label;
            d.Ih && ((l.labelText = b), k.Fc(d.Hh, a, l), (b = l.labelText));
            a.sf = b;
            return a.Ld != b;
        };
        this.k = function (a) {
            var b = a.sf;
            a.Ld = b;
            a.Wc.clear();
            a.qa = void 0;
            !a.aa ||
                D.mf(b) ||
                ('flattened' == d.Va && !a.empty() && a.N) ||
                (a.qa = G.Ae(
                    m(a),
                    a.Wc,
                    b,
                    a.aa,
                    a.q,
                    a.K,
                    !1,
                    !1,
                    a.oi,
                    a.K.ja,
                    d.Rh,
                    a.Ta,
                ));
            a.Ta = !1;
        };
        Wa = this.A = function (a, b) {
            a.Wc.Ua(b);
        };
    }
    var Wa;
    function Ta(e) {
        function m(a, b) {
            var c = a.e,
                d = c.length,
                e,
                f,
                g = l.K.Qb;
            for (e = 0; e < d; e++)
                (f = c[e]),
                    (f.Fb =
                        ((180 * (Math.atan2(f.x - a.x, f.y - a.y) + b)) /
                            Math.PI +
                            180) /
                        360),
                    (f.Qc = Math.min(1, Math.sqrt(M.d(f, a)) / g));
        }
        function g(a, b) {
            var c = a.e,
                d = c.length;
            if (1 === d || (2 === d && c[0].description)) c[0].Fb = 0.5;
            else {
                var e,
                    f,
                    g = 0,
                    k = Number.MAX_VALUE,
                    l = Math.sin(b),
                    m = Math.cos(b);
                for (e = 0; e < d; e++) {
                    f = c[e];
                    var x = f.x * l + f.y * m;
                    g < x && (g = x);
                    k > x && (k = x);
                    f.Fb = x;
                    f.Qc = 1;
                }
                for (e = 0; e < d; e++)
                    (f = c[e]), (f.Fb = (f.Fb - k) / (g - k));
            }
        }
        function d(a, b, c, d) {
            b = b[d];
            return b + (c[d] - b) * a;
        }
        var b = { radial: m, linear: g },
            f = e.options,
            k,
            l,
            c = { groupColor: null, labelColor: null };
        e.c.j('model:loaded', function (a) {
            l = a;
        });
        e.c.j('api:initialized', function (a) {
            k = a;
        });
        this.H = function () {};
        this.apply = function () {
            function a(b) {
                if (b.N && b.Ba) {
                    var g = b.e,
                        l,
                        m;
                    if (b.Z || b.Ma || w) {
                        0 === b.R
                            ? n(b, (f.Yi * Math.PI) / 180)
                            : q(b, (f.bj * Math.PI) / 180);
                        for (l = g.length - 1; 0 <= l; l--) {
                            m = g[l];
                            m.Ma = !0;
                            var C = m.Fb,
                                H,
                                Q,
                                O,
                                P,
                                F = m.Se;
                            0 === b.R
                                ? ((H = d(C, p, r, 'h')),
                                  (Q = (y + (1 - y) * m.Qc) * d(C, p, r, 's')),
                                  (O =
                                      (1 +
                                          (0 > m.ka ? u * (m.ka + 1) : u) *
                                              (1 - m.Qc)) *
                                      d(C, p, r, 'l')),
                                  (P = d(C, p, r, 'a')))
                                : ((O = b.ra),
                                  (H = O.h),
                                  (Q = O.s),
                                  (O = e(O.l, C, f.cj, f.dj)),
                                  (P = b.Se.a));
                            F.h = H;
                            F.s = Q;
                            F.l = O;
                            F.a = P;
                            H = m.ra;
                            m.Ra
                                ? ((H.h = 0),
                                  (H.s = 0),
                                  (H.l = 'light' == f.Ug ? 90 : 10),
                                  (H.a = 1))
                                : ((H.h = F.h),
                                  (H.s = F.s),
                                  (H.l = F.l),
                                  (H.a = F.a));
                            w &&
                                ((c.groupColor = H),
                                (c.labelColor = 'auto'),
                                k.Fc(s, m, c, function (a) {
                                    a.ratio = C;
                                }),
                                (m.ra = S.Aa(c.groupColor)),
                                (m.ra.a = D.M(c.groupColor, 'a')
                                    ? c.groupColor.a
                                    : 1),
                                'auto' !== c.labelColor &&
                                    (m.Kd = S.Ng(c.labelColor)));
                        }
                        b.Ma = !1;
                    }
                    for (l = g.length - 1; 0 <= l; l--) a(g[l]);
                }
            }
            function e(a, b, c, d) {
                var f = 0 > a + c * d ? 0 : 100 < a + c * d ? 100 : a + c * d;
                return (
                    f +
                    b *
                        ((0 > a - c * (1 - d)
                            ? 0
                            : 100 < a - c * (1 - d)
                            ? 100
                            : a - c * (1 - d)) -
                            f)
                );
            }
            var n = b[f.Xi] || m,
                q = g,
                p = f.gj,
                r = f.$i,
                s = f.mh,
                w = f.nh,
                u = f.aj,
                y = f.ej;
            a(l);
        };
        return this;
    }
    function Fa() {
        this.wc =
            this.se =
            this.tc =
            this.rg =
            this.f =
            this.yg =
            this.T =
            this.y =
            this.x =
            this.id =
                0;
        this.o = this.parent = this.e = null;
        this.q = { x: 0, y: 0, f: 0, i: 0 };
        this.C = null;
        this.Ld = this.sf = void 0;
        this.nd = !1;
        this.Qc = this.Fb = 0;
        this.Se = { h: 0, s: 0, l: 0, a: 0, model: 'hsla' };
        this.ra = { h: 0, s: 0, l: 0, a: 0, model: 'hsla' };
        this.Te = { h: 0, s: 0, l: 0, model: 'hsl' };
        this.Cd = -1;
        this.Kd = 'auto';
        this.sb = '#000';
        this.og = this.R = this.Hd = this.index = 0;
        this.Ra = !1;
        this.ja = this.xf = 0;
        this.ea = !1;
        this.aa = null;
        this.K = { x: 0, y: 0, ja: 0, Qb: 0 };
        this.$d = this.u = null;
        this.$c =
            this.$ =
            this.bb =
            this.Hc =
            this.pe =
            this.Yd =
            this.Ta =
            this.Ma =
            this.I =
            this.Z =
            this.Ka =
            this.Ba =
            this.N =
            this.Qa =
                !1;
        this.va = this.ua = this.Ja = this.fa = this.opacity = this.scale = 1;
        this.ta = 0;
        this.be = 1;
        this.Nb = this.ka = this.Jb = 0;
        this.description =
            this.selected =
            this.Gb =
            this.Wd =
            this.open =
            this.U =
                !1;
        this.Eb = 0;
        this.rf = this.Zd = this.X = !0;
        this.qa = void 0;
        this.Xc = !1;
        this.Wc = new ga();
        this.ba = new ga();
        this.cc = new ga();
        this.oi = G.Bi();
        this.Zc = 0;
        this.vd = 1;
        this.dd = -1;
        this.empty = function () {
            return !this.e || 0 === this.e.length;
        };
        var e = [];
        this.Ec = function (b) {
            e.push(b);
        };
        this.hd = function (b) {
            D.eg(e, b);
        };
        var m = { scale: 1 };
        this.Qd = function () {
            var b = !1;
            this.scale = 1;
            for (var d = 0; d < e.length; d++)
                (b = e[d].tf(this, m) || b), (this.scale *= m.scale);
            return b;
        };
        this.Vb = function (b) {
            for (var d = 0; d < e.length; d++) e[d].Vb(this, b);
        };
        this.Wb = function (b, d) {
            d.x = b.x;
            d.y = b.y;
            for (var g = 0; g < e.length; g++) e[g].Wb(this, d, d);
            return d;
        };
        this.Xb = function (b, d) {
            d.x = b.x;
            d.y = b.y;
            for (var g = 0; g < e.length; g++) e[g].Xb(this, d, d);
            return d;
        };
        var g = [];
        this.Bb = function (b) {
            g.push(b);
        };
        this.gd = function (b) {
            D.eg(g, b);
        };
        var d = { opacity: 1, va: 1, ua: 1, fa: 1, Ja: 1 };
        this.pc = function () {
            if (0 !== g.length) {
                this.Ja = this.fa = this.ua = this.va = this.opacity = 1;
                for (var b = g.length - 1; 0 <= b; b--)
                    (0, g[b])(this, d),
                        (this.opacity *= d.opacity),
                        (this.ua *= d.ua),
                        (this.va *= d.va),
                        (this.fa *= d.fa),
                        (this.Ja *= d.Ja);
            }
        };
    }
    function Na(e, m) {
        return m.T > e.T ? 1 : m.T < e.T ? -1 : e.index - m.index;
    }
    function $a(e) {
        var m = this,
            g,
            d,
            b,
            f,
            k = e.options,
            l,
            c;
        e.c.j('stage:initialized', function (a, c, l, q) {
            b = l;
            f = q;
            g = a.qc('titlebar', k.n, !1);
            d = g.getContext('2d');
            d.n = k.n;
            d.scale(d.n, d.n);
            e.c.p('titlebar:initialized', m);
        });
        e.c.j('stage:resized', function (a, c, e, g) {
            b = e;
            f = g;
            d.scale(d.n, d.n);
        });
        e.c.j('zoom:initialized', function (a) {
            c = a;
        });
        e.c.j('api:initialized', function (a) {
            l = a;
        });
        e.c.j('model:loaded', function () {
            d.clearRect(0, 0, b, f);
        });
        this.update = function (a) {
            d.clearRect(0, 0, b, f);
            if (a) {
                !a.empty() && a.e[0].description && (a = a.e[0]);
                var e = k.Dj,
                    g = k.Cj,
                    m = Math.min(f / 2, k.qe + 2 * e),
                    p = m - 2 * e,
                    r = b - 2 * g;
                if (!(0 >= p || 0 >= r)) {
                    var s = a.Xc ? a.qa.fontSize * a.scale * c.scale() : 0,
                        w,
                        u = {
                            titleBarText: a.Ld,
                            titleBarTextColor: k.vg,
                            titleBarBackgroundColor: k.ug,
                            titleBarMaxFontSize: k.qe,
                            titleBarShown: s < k.ui,
                        };
                    l.Fc(k.zj, a, u, function (a) {
                        a.titleBarWidth = r;
                        a.titleBarHeight = p;
                        a.labelFontSize = s;
                        a.viewportScale = c.scale();
                    });
                    (w = u.titleBarText) &&
                        0 !== w.length &&
                        u.titleBarShown &&
                        ((a = c.pd(a.Wb(a, {}), {}).y > f / 2),
                        (e = { x: g, y: a ? e : f - m + e, f: r, i: p }),
                        (g = M.A(e)),
                        (d.fillStyle = k.ug),
                        d.fillRect(0, a ? 0 : f - m, b, m),
                        (d.fillStyle = k.vg),
                        G.Oe(
                            {
                                fontFamily: k.Aj || k.Jh,
                                fontStyle: k.Wj || k.Kh,
                                fontWeight: k.Yj || k.Mh,
                                fontVariant: k.Xj || k.Lh,
                                tb: k.qe,
                                ad: k.Bj,
                                qb: 0,
                                fb: 0,
                                ub: 1,
                            },
                            d,
                            w,
                            g,
                            e,
                            { x: e.x + e.f / 2, y: e.y + e.i / 2 },
                            !0,
                            !0,
                        ).la || d.clearRect(0, 0, b, f));
                }
            }
        };
    }
    function ab(e) {
        function m(a, b, d) {
            u = !0;
            h && h.stop();
            q && q.stop();
            return k(c.reset(a), b, d).O(function () {
                u = !1;
            });
        }
        function g(a) {
            c.update(a);
            s.I = !0;
            e.c.p('foamtree:dirty', !0);
        }
        function d(a, b) {
            return c.d(
                (0 !== c.k() ? 0.35 : 1) * a,
                (0 !== c.A() ? 0.35 : 1) * b,
            );
        }
        function b() {
            if (1 === a.Rb) {
                var b = Math.round(1e4 * c.k()) / 1e4;
                0 !== b &&
                    ((n.ce = b),
                    (h = w.D.vc(n)
                        .ia({
                            duration: 500,
                            G: { x: { start: b, end: 0, Q: X.Tb } },
                            ca: function () {
                                c.d(n.x - n.ce, 0);
                                g(1);
                                n.ce = n.x;
                            },
                        })
                        .start()));
            }
        }
        function f() {
            if (1 === a.Rb) {
                var b = Math.round(1e4 * c.A()) / 1e4;
                0 !== b &&
                    ((p.de = b),
                    (q = w.D.vc(p)
                        .ia({
                            duration: 500,
                            G: { y: { start: b, end: 0, Q: X.Tb } },
                            ca: function () {
                                c.d(0, p.y - p.de);
                                g(1);
                                p.de = p.y;
                            },
                        })
                        .start()));
            }
        }
        function k(b, c, d) {
            return b
                ? w.D.vc(a)
                      .ia({
                          duration: void 0 === c ? 700 : c,
                          G: { Rb: { start: 0, end: 1, Q: d || X.qg } },
                          ca: function () {
                              g(a.Rb);
                          },
                      })
                      .cb()
                : new V().J().L();
        }
        function l(a) {
            return function () {
                return u ? new V().J().L() : a.apply(this, arguments);
            };
        }
        var c = new oa(e),
            a = { Rb: 1 },
            h,
            n = { He: 0, x: 0, ce: 0 },
            q,
            p = { Ie: 0, y: 0, de: 0 },
            r = this,
            s,
            w,
            u = !1;
        e.c.j('model:loaded', function (a) {
            s = a;
            c.reset(!1);
            c.update(1);
        });
        e.c.j('timeline:initialized', function (a) {
            w = a;
        });
        this.H = function () {
            e.c.p('zoom:initialized', this);
        };
        this.reset = function (a, b) {
            c.Sb(1);
            return m(!0, a, b);
        };
        this.normalize = l(function (a, b) {
            c.Jc(1) ? m(!1, a, b) : r.yf();
        });
        this.yf = function () {
            b();
            f();
        };
        this.k = l(function (a, b, c, d) {
            return r.uc(a.q, b, c, d);
        });
        this.$b = l(function (a, b, d, e) {
            return k(c.$b(a, b), d, e);
        });
        this.uc = l(function (a, b, d, e) {
            return k(c.uc(a, b), d, e);
        });
        this.Ej = l(function (a, b) {
            c.uc(a, b) && g(1);
        });
        this.wi = l(function (b, c) {
            1 === a.Rb && d(b, c) && g(1);
        });
        this.Rg = l(function (a, b) {
            c.$b(a, b) && g(1);
        });
        this.Qg = l(function (a, b, e, f) {
            a = 0 | c.$b(a, b);
            (a |= d(e, f)) && g(1);
        });
        this.xi = l(function (e, k, l) {
            1 === a.Rb &&
                ((h = w.D.vc(n)
                    .ia({
                        duration: e / 0.03,
                        G: { He: { start: k, end: 0, Q: X.Tb } },
                        ca: function () {
                            c.d(n.He, 0) && g(1);
                            b();
                        },
                    })
                    .start()),
                (q = w.D.vc(p)
                    .ia({
                        duration: e / 0.03,
                        G: { Ie: { start: l, end: 0, Q: X.Tb } },
                        ca: function () {
                            d(0, p.Ie) && g(1);
                            f();
                        },
                    })
                    .start()));
        });
        this.yi = function () {
            h && 0 === c.k() && h.stop();
            q && 0 === c.A() && q.stop();
        };
        this.Lc = function (a, b) {
            c.Lc(a, b);
        };
        this.Sb = function (a) {
            return c.Sb(a);
        };
        this.Jc = function (a) {
            return c.Jc(a);
        };
        this.Ud = function () {
            return c.Ud();
        };
        this.absolute = function (a, b) {
            return c.absolute(a, b);
        };
        this.pd = function (a, b) {
            return c.pd(a, b);
        };
        this.scale = function () {
            return c.scale();
        };
        this.d = function (a) {
            return c.S(a);
        };
        this.content = function (a, b, d, e) {
            c.content(a, b, d, e);
        };
    }
    function bb(e, m, g) {
        function d(a) {
            var b = [];
            $.F(q, function (c) {
                a(c) && b.push(c.group);
            });
            return { groups: b };
        }
        function b(a, b) {
            var c = n.options,
                d = c.nj,
                e = c.mj,
                c = c.ie,
                f = 0 < d + e ? c : 0,
                g = [];
            Ba.Ia(a, Ba.xa(a, n.options.ke), function (a, c, h) {
                c = 'groups' === n.options.je ? h : c;
                a.e &&
                    ((a = r.D.m(a)
                        .gb(f * (e + d * c))
                        .call(b)
                        .wa()),
                    g.push(a));
            });
            return r.D.m({}).$a(g).cb();
        }
        function f(a) {
            Y ||
                ((Y = !0),
                p.d(
                    function () {
                        Y = !1;
                        n.c.p('repaint:before');
                        B.he(this.Pg);
                    },
                    { Pg: a },
                ));
        }
        function k(b) {
            function c(a, b) {
                var f = a.$;
                a.$ = b <= d;
                a.$c = b <= e;
                a.$ !== f &&
                    $.Je(a, function (a) {
                        a.pe = !0;
                    });
                a.open || a.hb || b++;
                if ((f = a.e)) for (var g = 0; g < f.length; g++) c(f[g], b);
            }
            var d = n.options.uf,
                e = Math.min(n.options.uf, n.options.ri);
            if (b)
                for (var f = 0; f < b.length; f++) {
                    var g = b[f];
                    c(g, a(g));
                }
            else c(q, 0);
        }
        function l(a, b) {
            var d = [],
                e = c(a, b);
            e.vi && n.c.p('model:childrenAttached', $.Oc(q));
            e.hj &&
                A.complete(function (a) {
                    J.rb(a);
                    d.push(a);
                });
            for (var f = (e = 0); f < d.length; f++) {
                var g = d[f];
                g.e && (e += g.e.length);
                g.Ba = !0;
                H.d(g);
            }
            return e;
        }
        function c(a, b) {
            function c(a, b) {
                var k = !a.Ra && b - (a.hb ? 1 : 0) < d;
                f = f || k;
                a.Qa = a.Qa || k;
                a.open || a.hb || b++;
                var l = a.e;
                !l && k && ((e = x.S(a) || e), (l = a.e), h && (a.Ta = !0));
                if (l) for (k = 0; k < l.length; k++) g.push(l[k], b);
            }
            var d = b || n.options.si,
                e = !1,
                f = !1,
                g,
                h = 'flattened' === m.Va;
            for (
                g = a
                    ? a.reduce(function (a, b) {
                          a.push(b, 1);
                          return a;
                      }, [])
                    : [q, 1];
                0 < g.length;

            )
                c(g.shift(), g.shift());
            return { vi: e, hj: f };
        }
        function a(a) {
            for (var b = 0; a.parent; ) a.open || a.hb || b++, (a = a.parent);
            return b;
        }
        var h = this,
            n = { c: new wa(), options: m, Xd: g },
            q,
            p = new da(),
            r = new xa(p),
            s = aa.create(),
            w = new ka(n),
            u = new ab(n),
            y = new Da(n),
            x = new Ea(n.options),
            A = new Oa(n),
            B = new Ra(n, p),
            K = new La(n);
        new $a(n);
        var C = new Ha(n),
            H = new Ia(n),
            Q = new Ja(n),
            O = new Ka(n);
        n.c.j('stage:initialized', function (a, b, c, d) {
            t.jf(c, d);
        });
        n.c.j('stage:resized', function (a, b, c, d) {
            t.lj(a, b, c, d);
        });
        n.c.j('foamtree:attachChildren', l);
        n.c.j('openclose:changing', k);
        n.c.j('interaction:reset', function () {
            R(!0);
        });
        n.c.j('foamtree:dirty', f);
        this.H = function () {
            n.c.p('timeline:initialized', r);
            q = x.H();
            w.H(e);
            y.H();
            B.H();
            K.H();
            C.H();
            H.H();
            u.H();
            Q.H();
            O.H();
        };
        this.mb = function () {
            r.d();
            I.stop();
            p.k();
            w.mb();
        };
        var P =
                'groupLabelFontFamily groupLabelFontStyle groupLabelFontVariant groupLabelFontWeight groupLabelLineHeight groupLabelHorizontalPadding groupLabelVerticalPadding groupLabelDottingThreshold groupLabelMaxTotalHeight groupLabelMinFontSize groupLabelMaxFontSize groupLabelDecorator'.split(
                    ' ',
                ),
            F =
                'rainbowColorDistribution rainbowLightnessDistribution rainbowColorDistributionAngle rainbowLightnessDistributionAngle rainbowColorModelStartPoint rainbowLightnessCorrection rainbowSaturationCorrection rainbowStartColor rainbowEndColor rainbowHueShift rainbowHueShiftCenter rainbowSaturationShift rainbowSaturationShiftCenter rainbowLightnessShift rainbowLightnessShiftCenter attributionTheme'.split(
                    ' ',
                ),
            T = !1,
            N = [
                'groupBorderRadius',
                'groupBorderRadiusCorrection',
                'groupBorderWidth',
                'groupInsetWidth',
                'groupBorderWidthScaling',
            ],
            U = ['maxGroupLevelsDrawn', 'maxGroupLabelLevelsDrawn'];
        this.Zb = function (a) {
            n.c.p('options:changed', a);
            D.pb(a, P) &&
                $.F(q, function (a) {
                    a.Ta = !0;
                });
            D.pb(a, F) && (q.Ma = !0);
            D.pb(a, N) && (T = !0);
            D.pb(a, U) && (k(), l());
        };
        this.reload = function () {
            z.reload();
        };
        this.Ac = function (a, b) {
            D.defer(function () {
                if (T) t.pi(a), (T = !1);
                else {
                    if (b)
                        for (var c = x.k(b), d = c.length - 1; 0 <= d; d--)
                            c[d].I = !0;
                    else q.I = !0;
                    f(a);
                }
            });
        };
        this.Y = function () {
            w.k();
        };
        this.update = function () {
            x.update();
            t.Fj();
        };
        this.reset = function () {
            return R(!1);
        };
        this.S = B.d;
        this.Ia = (function () {
            var a = {};
            return function (b, c) {
                var d = x.d(b);
                return d ? y.qd(a, d, c) : null;
            };
        })();
        this.Aa = (function () {
            var a = { x: 0, y: 0 },
                b = { x: 0, y: 0 };
            return function (c, d) {
                var e = x.d(c);
                return e
                    ? ((a.x = d.x),
                      (a.y = d.y),
                      e.Wb(a, a),
                      u.pd(a, a),
                      (b.x = a.x),
                      (b.y = a.y),
                      b)
                    : null;
            };
        })();
        this.xa = (function () {
            var a = {};
            return function (b) {
                return (b = x.d(b)) ? y.sd(a, b) : null;
            };
        })();
        this.Yb = (function () {
            var a = {};
            return function (b) {
                return (b = x.d(b)) ? y.rd(a, b) : null;
            };
        })();
        this.ya = (function () {
            var a = {};
            return function () {
                return u.d(a);
            };
        })();
        this.Bc = function () {
            this.A({
                groups: d(function (a) {
                    return a.group.selected;
                }),
                newState: !0,
                keepPrevious: !1,
            });
            this.k({
                groups: d(function (a) {
                    return a.group.open;
                }),
                newState: !0,
                keepPrevious: !1,
            });
            this.d({
                groups: d(function (a) {
                    return a.group.exposed;
                }),
                newState: !0,
                keepPrevious: !1,
            });
        };
        this.Pa = function () {
            return d(function (a) {
                return a.U;
            });
        };
        this.d = function (a) {
            return z.submit(function () {
                return C.hc(x.A(a, 'exposed', !1), !1, !0, !1);
            });
        };
        this.eb = function () {
            return d(function (a) {
                return a.open;
            });
        };
        this.k = function (a) {
            return z.submit(function () {
                return Q.Mb(x.A(a, 'open', !0), !1, !1);
            });
        };
        this.Wa = function () {
            return d(function (a) {
                return a.selected;
            });
        };
        this.A = function (a) {
            return z.submit(function () {
                O.select(x.A(a, 'selected', !0), !1);
                return new V().J().L();
            });
        };
        this.Dc = function (a) {
            return (a = x.d(a))
                ? a === q
                    ? u.reset(m.yc, X.oa(m.zc))
                    : u.k(a, m.Sc, m.yc, X.oa(m.zc))
                : new V().J().L();
        };
        this.za = function (a, b) {
            var c = x.k(a);
            if (c) {
                var d = l(c, b);
                k(c);
                return d;
            }
            return 0;
        };
        this.ib = function (a) {
            return K.ib[a];
        };
        this.Cc = function () {
            var a = fa;
            return {
                frames: a.frames,
                totalTime: a.totalTime,
                lastFrameTime: a.Md,
                lastInterFrameTime: a.Nd,
                fps: a.Re,
            };
        };
        var t = (function () {
                function a(e, f) {
                    var g = e || c,
                        h = f || d;
                    c = g;
                    d = h;
                    var k = m.dc && m.dc.boundary;
                    k && 2 < k.length
                        ? (q.o = k.map(function (a) {
                              return { x: g * a.x, y: h * a.y };
                          }))
                        : (q.o = [
                              { x: 0, y: 0 },
                              { x: g, y: 0 },
                              { x: g, y: h },
                              { x: 0, y: h },
                          ]);
                    b();
                }
                function b() {
                    q.Z = !0;
                    q.u = q.o;
                    q.q = M.q(q.o, q.q);
                    q.K = q;
                    M.te(q.o, q.K);
                }
                var c, d;
                return {
                    jf: a,
                    lj: function (b, c, d, e) {
                        J.stop();
                        var g = d / b,
                            h = e / c;
                        $.Ke(q, function (a) {
                            a.x = a.x * g + ((Math.random() - 0.5) * d) / 1e3;
                            a.y = a.y * h + ((Math.random() - 0.5) * e) / 1e3;
                        });
                        a(d, e);
                        q.Ka = !0;
                        A.step(J.rb, !0, !1, function (a) {
                            var b = a.e;
                            if (b) {
                                A.Pb(a);
                                for (var c = b.length - 1; 0 <= c; c--) {
                                    var d = b[c];
                                    d.f = d.tc;
                                }
                                a.Ka = !0;
                            }
                        })
                            ? f(!1)
                            : (A.sc(q),
                              n.options.ge
                                  ? (f(!1), I.fg(), I.jd())
                                  : (A.complete(J.rb), (q.Ma = !0), f(!1)));
                    },
                    pi: function (a) {
                        var c = !1;
                        q.empty() ||
                            (b(), I.Ib() || ((c = A.step(J.rb, !1, !1)), f(a)));
                        return c;
                    },
                    Fj: function () {
                        $.Ea(q, function (a) {
                            a.empty() || A.Pb(a);
                        });
                        A.sc(q);
                        n.options.ge
                            ? (I.fg(),
                              $.Ea(q, function (a) {
                                  a.empty() || J.gf(a);
                              }))
                            : ($.Ea(q, function (a) {
                                  a.empty() || J.rb(q);
                              }),
                              A.complete(J.rb),
                              (q.Ma = !0),
                              f(!1));
                    },
                };
            })(),
            z = (function () {
                function a() {
                    0 === m.ae && u.reset(0);
                    n.options.Wf(m.dc);
                    t.jf();
                    x.Y(m.dc);
                    c();
                    k();
                    n.c.p('model:loaded', q, $.Oc(q));
                    var b;
                    q.empty() ||
                        ((q.open = !0),
                        (q.Qa = !0),
                        m.ge ? (b = I.jd()) : (I.Ai(), (b = f())),
                        d(),
                        0 < m.ie ? (B.clear(), w.d(1)) : (b = pa([b, e(1)])));
                    n.options.Vf(m.dc);
                    b &&
                        (n.options.Zf(),
                        b.O(function () {
                            B.k(function () {
                                p.d(n.options.Yf);
                            });
                        }));
                }
                function d() {
                    var a = m.Xa,
                        b = m.ed;
                    m.Xa = 0;
                    m.ed = 0;
                    h.Bc();
                    m.Xa = a;
                    m.ed = b;
                }
                function e(a, b) {
                    return 0 === m.Ne || b
                        ? (w.d(a), new V().J().L())
                        : r.D.m({ opacity: w.d() })
                              .re(2)
                              .ia({
                                  duration: m.Ne,
                                  G: { opacity: { end: a, Q: X.oa(m.gh) } },
                                  ca: function () {
                                      w.d(this.opacity);
                                  },
                              })
                              .cb();
                }
                function f() {
                    $.Ea(q, function (a) {
                        a.Ba = !1;
                    });
                    var a = new V(),
                        c = new qa(a.J);
                    c.d();
                    q.Ba = !0;
                    H.d(q).O(c.k);
                    b(q, function Xa() {
                        this.N &&
                            this.o &&
                            ((this.Z = this.Ba = !0),
                            c.d(),
                            H.d(this).O(c.k),
                            c.d(),
                            b(this, Xa).O(c.k));
                    });
                    return a.L();
                }
                function g() {
                    for (var a = 0; a < s.length; a++) {
                        var b = s[a],
                            c = b.action();
                        D.M(c, 'then') ? c.O(b.De.J) : b.De.J();
                    }
                    s = [];
                }
                var l = !1,
                    s = [];
                return {
                    reload: function () {
                        l ||
                            (q.empty()
                                ? a()
                                : (J.stop(),
                                  r.d(),
                                  I.stop(),
                                  (l = !0),
                                  pa(0 < m.ae ? [H.k(), R(!1)] : [e(0)]).O(
                                      function () {
                                          e(0, !0);
                                          l = !1;
                                          a();
                                          D.defer(g);
                                      },
                                  )));
                    },
                    submit: function (a) {
                        if (l) {
                            var b = new V();
                            s.push({ action: a, De: b });
                            return b.L();
                        }
                        return a();
                    },
                };
            })(),
            L,
            E = new qa(function () {
                L.J();
            }),
            I = (function () {
                function a() {
                    e ||
                        (E.A() && (L = new V()),
                        E.d(),
                        c(),
                        (e = !0),
                        p.repeat(d));
                    return L.L();
                }
                function c() {
                    g = s.now();
                }
                function d() {
                    var c = s.now() - g > m.kj,
                        c =
                            A.step(
                                function (c) {
                                    c.Ba = !0;
                                    J.gf(c);
                                    E.d();
                                    H.d(c).O(E.k);
                                    E.d();
                                    b(c, function () {
                                        this.Qa = !0;
                                        a();
                                    }).O(E.k);
                                },
                                !0,
                                c,
                            ) || c;
                    f(!0);
                    c && ((e = !1), E.k());
                    return c;
                }
                var e = !1,
                    g;
                return {
                    Ai: function () {
                        A.complete(J.rb);
                    },
                    jd: a,
                    fg: c,
                    Ib: function () {
                        return !E.A();
                    },
                    stop: function () {
                        p.cancel(d);
                        e = !1;
                        E.clear();
                    },
                };
            })(),
            J = (function () {
                function a(b) {
                    var c = !b.empty();
                    b.Ba = !0;
                    if (c) {
                        for (var d = b.e, e = d.length - 1; 0 <= e; e--) {
                            var f = d[e];
                            f.f = f.tc;
                        }
                        b.Ka = !0;
                    }
                    return c;
                }
                var b = [];
                return {
                    gf: function (c) {
                        var d = n.options,
                            e = d.Ah;
                        0 < e
                            ? Ba.Ia(
                                  c,
                                  Ba.xa(c, n.options.ke),
                                  function (a, c, f) {
                                      c = 'groups' === n.options.je ? f : c;
                                      E.d();
                                      b.push(
                                          r.D.m(a)
                                              .gb(c * d.zh * e)
                                              .ia({
                                                  duration: e,
                                                  G: {
                                                      f: {
                                                          start: a.rg,
                                                          end: a.tc,
                                                          Q: X.oa(d.Bh),
                                                      },
                                                  },
                                                  ca: function () {
                                                      this.f = Math.max(
                                                          0,
                                                          this.f,
                                                      );
                                                      this.parent.Ka = !0;
                                                      I.jd();
                                                  },
                                              })
                                              .kb(E.k)
                                              .start(),
                                      );
                                  },
                              )
                            : a(c) && I.jd();
                    },
                    rb: a,
                    stop: function () {
                        for (var a = b.length - 1; 0 <= a; a--) b[a].stop();
                        b = [];
                    },
                };
            })(),
            R = (function () {
                var a = !1;
                return function (b) {
                    if (a) return new V().J().L();
                    a = !0;
                    var c = [];
                    c.push(u.reset(m.yc, X.oa(m.zc)));
                    var d = new V();
                    C.hc({ e: [], Ha: !1, Ga: !1 }, b, !1, !0).O(function () {
                        Q.Mb({ e: [], Ha: !1, Ga: !1 }, b, !1).O(d.J);
                    });
                    c.push(d.L());
                    return pa(c).O(function () {
                        a = !1;
                        b && m.$f();
                    });
                };
            })(),
            Y = !1;
    }
    function Ya() {
        return {
            version: '3.4.6',
            build: 'bugfix/3.4.x/b3d3e9ff',
            brandingAllowed: !0,
        };
    }
    v.Gd(
        function () {
            window.CarrotSearchFoamTree = function (e) {
                function m(a, b) {
                    if (!l || l.exists(a))
                        switch (a) {
                            case 'selection':
                                return h.Wa();
                            case 'open':
                                return h.eb();
                            case 'exposure':
                                return h.Pa();
                            case 'state':
                                return h.xa.apply(this, b);
                            case 'geometry':
                                return h.Ia.apply(this, b);
                            case 'hierarchy':
                                return h.Yb.apply(this, b);
                            case 'containerCoordinates':
                                return h.Aa.apply(this, b);
                            case 'imageData':
                                return h.S.apply(this, b);
                            case 'viewport':
                                return h.ya();
                            case 'times':
                                return h.Cc();
                            case 'onModelChanged':
                            case 'onRedraw':
                            case 'onRolloutStart':
                            case 'onRolloutComplete':
                            case 'onRelaxationStep':
                            case 'onGroupHover':
                            case 'onGroupOpenOrCloseChanging':
                            case 'onGroupExposureChanging':
                            case 'onGroupSelectionChanging':
                            case 'onGroupSelectionChanged':
                            case 'onGroupClick':
                            case 'onGroupDoubleClick':
                            case 'onGroupHold':
                                var d = c[a];
                                return Array.isArray(d) ? d : [d];
                            default:
                                return c[a];
                        }
                }
                function g(d) {
                    function e(a, b) {
                        return D.M(f, a) ? (b(f[a]), delete f[a], 1) : 0;
                    }
                    var f;
                    if (0 === arguments.length) return 0;
                    1 === arguments.length
                        ? (f = D.extend({}, arguments[0]))
                        : 2 === arguments.length &&
                          ((f = {}), (f[arguments[0]] = arguments[1]));
                    l && l.validate(f, a.qi);
                    var g = 0;
                    h &&
                        ((g += e('selection', h.A)),
                        (g += e('open', h.k)),
                        (g += e('exposure', h.d)));
                    var k = {};
                    D.Fa(f, function (a, b) {
                        if (c[b] !== a || D.lc(a)) (k[b] = a), g++;
                        c[b] = a;
                    });
                    0 < g && b(k);
                    return g;
                }
                function d(a, d) {
                    var e = 'on' + a.charAt(0).toUpperCase() + a.slice(1),
                        f = c[e];
                    c[e] = d(Array.isArray(f) ? f : [f]);
                    f = {};
                    f[e] = c[e];
                    b(f);
                }
                function b(b) {
                    (function () {
                        function d(a, e) {
                            return D.M(b, a) || void 0 === e
                                ? va.m(c[a], k)
                                : e;
                        }
                        a.qi = c.logging;
                        a.dc = c.dataObject;
                        a.n = c.pixelRatio;
                        a.zb = c.wireframePixelRatio;
                        a.Va = c.stacking;
                        a.fc = c.descriptionGroupType;
                        a.Kc = c.descriptionGroupPosition;
                        a.dh = c.descriptionGroupDistanceFromCenter;
                        a.ec = c.descriptionGroupSize;
                        a.Fe = c.descriptionGroupMinHeight;
                        a.Ee = c.descriptionGroupMaxHeight;
                        a.Ge = c.descriptionGroupPolygonDrawn;
                        a.Yc = c.layout;
                        a.nc = c.layoutByWeightOrder;
                        a.xj = c.showZeroWeightGroups;
                        a.Ye = c.groupMinDiameter;
                        a.fe = c.rectangleAspectRatioPreference;
                        a.jj = c.initializer || c.relaxationInitializer;
                        a.kj = c.relaxationMaxDuration;
                        a.ge = c.relaxationVisible;
                        a.dg = c.relaxationQualityThreshold;
                        a.Sh = c.groupResizingBudget;
                        a.Ah = c.groupGrowingDuration;
                        a.zh = c.groupGrowingDrag;
                        a.Bh = c.groupGrowingEasing;
                        a.kh = c.groupBorderRadius;
                        a.nb = c.groupBorderWidth;
                        a.Sa = c.groupBorderWidthScaling;
                        a.Dd = c.groupInsetWidth;
                        a.lh = c.groupBorderRadiusCorrection;
                        a.ob = c.groupStrokeWidth;
                        a.Tc = c.groupSelectionOutlineWidth;
                        a.Wh = c.groupSelectionOutlineColor;
                        a.Ed = c.groupSelectionOutlineShadowSize;
                        a.Ze = c.groupSelectionOutlineShadowColor;
                        a.Th = c.groupSelectionFillHueShift;
                        a.Vh = c.groupSelectionFillSaturationShift;
                        a.Uh = c.groupSelectionFillLightnessShift;
                        a.af = c.groupSelectionStrokeHueShift;
                        a.cf = c.groupSelectionStrokeSaturationShift;
                        a.bf = c.groupSelectionStrokeLightnessShift;
                        a.yh = c.groupFillType;
                        a.uh = c.groupFillGradientRadius;
                        a.rh = c.groupFillGradientCenterHueShift;
                        a.th = c.groupFillGradientCenterSaturationShift;
                        a.sh = c.groupFillGradientCenterLightnessShift;
                        a.vh = c.groupFillGradientRimHueShift;
                        a.xh = c.groupFillGradientRimSaturationShift;
                        a.wh = c.groupFillGradientRimLightnessShift;
                        a.Fd = c.groupStrokeType;
                        a.ob = c.groupStrokeWidth;
                        a.df = c.groupStrokePlainHueShift;
                        a.ff = c.groupStrokePlainSaturationShift;
                        a.ef = c.groupStrokePlainLightnessShift;
                        a.ai = c.groupStrokeGradientRadius;
                        a.Xh = c.groupStrokeGradientAngle;
                        a.bi = c.groupStrokeGradientUpperHueShift;
                        a.di = c.groupStrokeGradientUpperSaturationShift;
                        a.ci = c.groupStrokeGradientUpperLightnessShift;
                        a.Yh = c.groupStrokeGradientLowerHueShift;
                        a.$h = c.groupStrokeGradientLowerSaturationShift;
                        a.Zh = c.groupStrokeGradientLowerLightnessShift;
                        a.Ch = c.groupHoverFillHueShift;
                        a.Eh = c.groupHoverFillSaturationShift;
                        a.Dh = c.groupHoverFillLightnessShift;
                        a.Ve = c.groupHoverStrokeHueShift;
                        a.Xe = c.groupHoverStrokeSaturationShift;
                        a.We = c.groupHoverStrokeLightnessShift;
                        a.Ya = c.groupExposureScale;
                        a.qh = c.groupExposureShadowColor;
                        a.Ue = c.groupExposureShadowSize;
                        a.Sc = c.groupExposureZoomMargin;
                        a.fi = c.groupUnexposureLightnessShift;
                        a.gi = c.groupUnexposureSaturationShift;
                        a.ei = c.groupUnexposureLabelColorThreshold;
                        a.Xa = c.exposeDuration;
                        a.ic = c.exposeEasing;
                        a.ed = c.openCloseDuration;
                        a.mh = va.m(c.groupColorDecorator, k);
                        a.nh = c.groupColorDecorator !== D.sa;
                        a.Hh = va.m(c.groupLabelDecorator, k);
                        a.Ih = c.groupLabelDecorator !== D.sa;
                        a.Nh = va.m(c.groupLabelLayoutDecorator, k);
                        a.Oh = c.groupLabelLayoutDecorator !== D.sa;
                        a.oh = va.m(c.groupContentDecorator, k);
                        a.Rc = c.groupContentDecorator !== D.sa;
                        a.ph = c.groupContentDecoratorTriggering;
                        a.fj = c.rainbowStartColor;
                        a.Zi = c.rainbowEndColor;
                        a.Xi = c.rainbowColorDistribution;
                        a.Yi = c.rainbowColorDistributionAngle;
                        a.bj = c.rainbowLightnessDistributionAngle;
                        a.cj = c.rainbowLightnessShift;
                        a.dj = c.rainbowLightnessShiftCenter;
                        a.ej = c.rainbowSaturationCorrection;
                        a.aj = c.rainbowLightnessCorrection;
                        a.ag = c.parentFillOpacity;
                        a.zi = c.parentStrokeOpacity;
                        a.bg = c.parentLabelOpacity;
                        a.cg = c.parentOpacityBalancing;
                        a.Rh = c.groupLabelUpdateThreshold;
                        a.Jh = c.groupLabelFontFamily;
                        a.Kh = c.groupLabelFontStyle;
                        a.Lh = c.groupLabelFontVariant;
                        a.Mh = c.groupLabelFontWeight;
                        a.Qh = c.groupLabelMinFontSize;
                        a.Oj = c.groupLabelMaxFontSize;
                        a.Nj = c.groupLabelLineHeight;
                        a.Mj = c.groupLabelHorizontalPadding;
                        a.Qj = c.groupLabelVerticalPadding;
                        a.Pj = c.groupLabelMaxTotalHeight;
                        a.Gh = c.groupLabelDarkColor;
                        a.Ph = c.groupLabelLightColor;
                        a.Fh = c.groupLabelColorThreshold;
                        a.Hj = c.wireframeDrawMaxDuration;
                        a.Ij = c.wireframeLabelDrawing;
                        a.Gj = c.wireframeContentDecorationDrawing;
                        a.zg = c.wireframeToFinalFadeDuration;
                        a.Jj = c.wireframeToFinalFadeDelay;
                        a.hh = c.finalCompleteDrawMaxDuration;
                        a.ih = c.finalIncrementalDrawMaxDuration;
                        a.Pe = c.finalToWireframeFadeDuration;
                        a.td = c.androidStockBrowserWorkaround;
                        a.hf = c.incrementalDraw;
                        a.ti = c.maxGroups;
                        a.si = c.maxGroupLevelsAttached;
                        a.uf = c.maxGroupLevelsDrawn;
                        a.ri = c.maxGroupLabelLevelsDrawn;
                        a.ke = c.rolloutStartPoint;
                        a.je = c.rolloutMethod;
                        a.oj = c.rolloutEasing;
                        a.ie = c.rolloutDuration;
                        a.ig = c.rolloutScalingStrength;
                        a.kg = c.rolloutTranslationXStrength;
                        a.lg = c.rolloutTranslationYStrength;
                        a.hg = c.rolloutRotationStrength;
                        a.jg = c.rolloutTransformationCenter;
                        a.sj = c.rolloutPolygonDrag;
                        a.tj = c.rolloutPolygonDuration;
                        a.pj = c.rolloutLabelDelay;
                        a.qj = c.rolloutLabelDrag;
                        a.rj = c.rolloutLabelDuration;
                        a.nj = c.rolloutChildGroupsDrag;
                        a.mj = c.rolloutChildGroupsDelay;
                        a.Qi = c.pullbackStartPoint;
                        a.Ki = c.pullbackMethod;
                        a.Gi = c.pullbackEasing;
                        a.Tj = c.pullbackType;
                        a.ae = c.pullbackDuration;
                        a.Pi = c.pullbackScalingStrength;
                        a.Si = c.pullbackTranslationXStrength;
                        a.Ti = c.pullbackTranslationYStrength;
                        a.Oi = c.pullbackRotationStrength;
                        a.Ri = c.pullbackTransformationCenter;
                        a.Li = c.pullbackPolygonDelay;
                        a.Mi = c.pullbackPolygonDrag;
                        a.Ni = c.pullbackPolygonDuration;
                        a.Hi = c.pullbackLabelDelay;
                        a.Ii = c.pullbackLabelDrag;
                        a.Ji = c.pullbackLabelDuration;
                        a.Di = c.pullbackChildGroupsDelay;
                        a.Ei = c.pullbackChildGroupsDrag;
                        a.Fi = c.pullbackChildGroupsDuration;
                        a.Ne = c.fadeDuration;
                        a.gh = c.fadeEasing;
                        a.Kj = c.zoomMouseWheelFactor;
                        a.yc = c.zoomMouseWheelDuration;
                        a.zc = c.zoomMouseWheelEasing;
                        a.ui = c.maxLabelSizeForTitleBar;
                        a.Aj = c.titleBarFontFamily;
                        a.ug = c.titleBarBackgroundColor;
                        a.vg = c.titleBarTextColor;
                        a.Bj = c.titleBarMinFontSize;
                        a.qe = c.titleBarMaxFontSize;
                        a.Cj = c.titleBarTextPaddingLeftRight;
                        a.Dj = c.titleBarTextPaddingTopBottom;
                        a.zj = c.titleBarDecorator;
                        a.ye = c.attributionText;
                        a.Db = c.attributionLogo;
                        a.Tg = c.attributionLogoScale;
                        a.ud = c.attributionUrl;
                        a.xe = c.attributionPosition;
                        a.Sg = c.attributionDistanceFromCenter;
                        a.Vg = c.attributionWeight;
                        a.Ug = c.attributionTheme;
                        a.kf = c.interactionHandler;
                        a.Wf = d('onModelChanging', a.Wf);
                        a.Vf = d('onModelChanged', a.Vf);
                        a.Xf = d('onRedraw', a.Xf);
                        a.Zf = d('onRolloutStart', a.Zf);
                        a.Yf = d('onRolloutComplete', a.Yf);
                        a.Vd = d('onRelaxationStep', a.Vd);
                        a.$f = d('onViewReset', a.$f);
                        a.Of = d('onGroupOpenOrCloseChanging', a.Of);
                        a.Nf = d('onGroupOpenOrCloseChanged', a.Nf);
                        a.Gf = d('onGroupExposureChanging', a.Gf);
                        a.Ff = d('onGroupExposureChanged', a.Ff);
                        a.Qf = d('onGroupSelectionChanging', a.Qf);
                        a.Pf = d('onGroupSelectionChanged', a.Pf);
                        a.If = d('onGroupHover', a.If);
                        a.Kf = d('onGroupMouseMove', a.Kf);
                        a.Af = d('onGroupClick', a.Af);
                        a.Bf = d('onGroupDoubleClick', a.Bf);
                        a.Hf = d('onGroupHold', a.Hf);
                        a.Mf = d('onGroupMouseWheel', a.Mf);
                        a.Lf = d('onGroupMouseUp', a.Lf);
                        a.Jf = d('onGroupMouseDown', a.Jf);
                        a.Ef = d('onGroupDragStart', a.Ef);
                        a.Cf = d('onGroupDrag', a.Cf);
                        a.Df = d('onGroupDragEnd', a.Df);
                        a.Tf = d('onGroupTransformStart', a.Tf);
                        a.Rf = d('onGroupTransform', a.Rf);
                        a.Sf = d('onGroupTransformEnd', a.Sf);
                        a.Uf = d('onKeyUp', a.Uf);
                    })();
                    a.gj = S.Aa(a.fj);
                    a.$i = S.Aa(a.Zi);
                    a.$e = S.Aa(a.Ze);
                    a.Db
                        ? D.M(b, 'attributionLogo') &&
                          (a.Db instanceof Image
                              ? (a.La = a.Db)
                              : ((a.La = new Image()), (a.La.src = a.Db)))
                        : (a.La = null);
                    h && (h.Zb(b), D.M(b, 'dataObject') && h.reload());
                }
                function f(a) {
                    return function () {
                        return a.apply(this, arguments).jh(k);
                    };
                }
                var k = this,
                    l = window.CarrotSearchFoamTree.asserts,
                    c = D.extend({}, window.CarrotSearchFoamTree.defaults),
                    a = {};
                g(e);
                (e = c.element || document.getElementById(c.id)) ||
                    la.Pa('Element to embed FoamTree in not found.');
                c.element = e;
                var h = new bb(e, a, c);
                h.H();
                var n = {
                    get: function (a) {
                        return 0 === arguments.length
                            ? D.extend({}, c)
                            : m(
                                  arguments[0],
                                  Array.prototype.slice.call(arguments, 1),
                              );
                    },
                    set: g,
                    on: function (a, b) {
                        d(a, function (a) {
                            a.push(b);
                            return a;
                        });
                    },
                    off: function (a, b) {
                        d(a, function (a) {
                            return a.filter(function (a) {
                                return a !== b;
                            });
                        });
                    },
                    resize: h.Y,
                    redraw: h.Ac,
                    update: h.update,
                    attach: h.za,
                    select: f(h.A),
                    expose: f(h.d),
                    open: f(h.k),
                    reset: f(h.reset),
                    zoom: f(h.Dc),
                    trigger: function (a, b) {
                        var c = h.ib(a);
                        c && c(b);
                    },
                    dispose: function () {
                        function a() {
                            throw 'FoamTree instance disposed';
                        }
                        h.mb();
                        D.Fa(n, function (b, c) {
                            'dispose' !== c && (k[c] = a);
                        });
                    },
                };
                D.Fa(n, function (a, b) {
                    k[b] = a;
                });
                h.reload();
            };
            window['CarrotSearchFoamTree.asserts'] &&
                ((window.CarrotSearchFoamTree.asserts =
                    window['CarrotSearchFoamTree.asserts']),
                delete window['CarrotSearchFoamTree.asserts']);
            window.CarrotSearchFoamTree.supported = !0;
            window.CarrotSearchFoamTree.version = Ya;
            window.CarrotSearchFoamTree.defaults = Object.freeze({
                id: void 0,
                element: void 0,
                logging: !1,
                dataObject: void 0,
                pixelRatio: 1,
                wireframePixelRatio: 1,
                layout: 'relaxed',
                layoutByWeightOrder: !0,
                showZeroWeightGroups: !0,
                groupMinDiameter: 10,
                rectangleAspectRatioPreference: -1,
                relaxationInitializer: 'fisheye',
                relaxationMaxDuration: 3e3,
                relaxationVisible: !1,
                relaxationQualityThreshold: 1,
                stacking: 'hierarchical',
                descriptionGroupType: 'stab',
                descriptionGroupPosition: 225,
                descriptionGroupDistanceFromCenter: 1,
                descriptionGroupSize: 0.125,
                descriptionGroupMinHeight: 35,
                descriptionGroupMaxHeight: 0.5,
                descriptionGroupPolygonDrawn: !1,
                maxGroups: 5e4,
                maxGroupLevelsAttached: 4,
                maxGroupLevelsDrawn: 4,
                maxGroupLabelLevelsDrawn: 3,
                groupGrowingDuration: 0,
                groupGrowingEasing: 'bounce',
                groupGrowingDrag: 0,
                groupResizingBudget: 2,
                groupBorderRadius: 0.15,
                groupBorderWidth: 4,
                groupBorderWidthScaling: 0.6,
                groupInsetWidth: 6,
                groupBorderRadiusCorrection: 1,
                groupSelectionOutlineWidth: 5,
                groupSelectionOutlineColor: '#222',
                groupSelectionOutlineShadowSize: 0,
                groupSelectionOutlineShadowColor: '#fff',
                groupSelectionFillHueShift: 0,
                groupSelectionFillSaturationShift: 0,
                groupSelectionFillLightnessShift: 0,
                groupSelectionStrokeHueShift: 0,
                groupSelectionStrokeSaturationShift: 0,
                groupSelectionStrokeLightnessShift: -10,
                groupFillType: 'gradient',
                groupFillGradientRadius: 1,
                groupFillGradientCenterHueShift: 0,
                groupFillGradientCenterSaturationShift: 0,
                groupFillGradientCenterLightnessShift: 20,
                groupFillGradientRimHueShift: 0,
                groupFillGradientRimSaturationShift: 0,
                groupFillGradientRimLightnessShift: -5,
                groupStrokeType: 'plain',
                groupStrokeWidth: 1.5,
                groupStrokePlainHueShift: 0,
                groupStrokePlainSaturationShift: 0,
                groupStrokePlainLightnessShift: -10,
                groupStrokeGradientRadius: 1,
                groupStrokeGradientAngle: 45,
                groupStrokeGradientUpperHueShift: 0,
                groupStrokeGradientUpperSaturationShift: 0,
                groupStrokeGradientUpperLightnessShift: 20,
                groupStrokeGradientLowerHueShift: 0,
                groupStrokeGradientLowerSaturationShift: 0,
                groupStrokeGradientLowerLightnessShift: -20,
                groupHoverFillHueShift: 0,
                groupHoverFillSaturationShift: 0,
                groupHoverFillLightnessShift: 20,
                groupHoverStrokeHueShift: 0,
                groupHoverStrokeSaturationShift: 0,
                groupHoverStrokeLightnessShift: -10,
                groupExposureScale: 1.15,
                groupExposureShadowColor: 'rgba(0, 0, 0, 0.5)',
                groupExposureShadowSize: 50,
                groupExposureZoomMargin: 0.1,
                groupUnexposureLightnessShift: 65,
                groupUnexposureSaturationShift: -65,
                groupUnexposureLabelColorThreshold: 0.35,
                exposeDuration: 700,
                exposeEasing: 'squareInOut',
                groupColorDecorator: D.sa,
                groupLabelDecorator: D.sa,
                groupLabelLayoutDecorator: D.sa,
                groupContentDecorator: D.sa,
                groupContentDecoratorTriggering: 'onLayoutDirty',
                openCloseDuration: 500,
                rainbowColorDistribution: 'radial',
                rainbowColorDistributionAngle: -45,
                rainbowLightnessDistributionAngle: 45,
                rainbowSaturationCorrection: 0.1,
                rainbowLightnessCorrection: 0.4,
                rainbowStartColor: 'hsla(0, 100%, 55%, 1)',
                rainbowEndColor: 'hsla(359, 100%, 55%, 1)',
                rainbowLightnessShift: 30,
                rainbowLightnessShiftCenter: 0.4,
                parentFillOpacity: 0.7,
                parentStrokeOpacity: 1,
                parentLabelOpacity: 1,
                parentOpacityBalancing: !0,
                wireframeDrawMaxDuration: 15,
                wireframeLabelDrawing: 'auto',
                wireframeContentDecorationDrawing: 'auto',
                wireframeToFinalFadeDuration: 500,
                wireframeToFinalFadeDelay: 300,
                finalCompleteDrawMaxDuration: 80,
                finalIncrementalDrawMaxDuration: 100,
                finalToWireframeFadeDuration: 200,
                androidStockBrowserWorkaround: v.lf(),
                incrementalDraw: 'fast',
                groupLabelFontFamily: 'sans-serif',
                groupLabelFontStyle: 'normal',
                groupLabelFontWeight: 'normal',
                groupLabelFontVariant: 'normal',
                groupLabelLineHeight: 1.05,
                groupLabelHorizontalPadding: 1,
                groupLabelVerticalPadding: 1,
                groupLabelMinFontSize: 6,
                groupLabelMaxFontSize: 160,
                groupLabelMaxTotalHeight: 0.9,
                groupLabelUpdateThreshold: 0.05,
                groupLabelDarkColor: '#000',
                groupLabelLightColor: '#fff',
                groupLabelColorThreshold: 0.35,
                rolloutStartPoint: 'center',
                rolloutEasing: 'squareOut',
                rolloutMethod: 'groups',
                rolloutDuration: 2e3,
                rolloutScalingStrength: -0.7,
                rolloutTranslationXStrength: 0,
                rolloutTranslationYStrength: 0,
                rolloutRotationStrength: -0.7,
                rolloutTransformationCenter: 0.7,
                rolloutPolygonDrag: 0.1,
                rolloutPolygonDuration: 0.5,
                rolloutLabelDelay: 0.8,
                rolloutLabelDrag: 0.1,
                rolloutLabelDuration: 0.5,
                rolloutChildGroupsDrag: 0.1,
                rolloutChildGroupsDelay: 0.2,
                pullbackStartPoint: 'center',
                pullbackEasing: 'squareIn',
                pullbackMethod: 'groups',
                pullbackDuration: 1500,
                pullbackScalingStrength: -0.7,
                pullbackTranslationXStrength: 0,
                pullbackTranslationYStrength: 0,
                pullbackRotationStrength: -0.7,
                pullbackTransformationCenter: 0.7,
                pullbackPolygonDelay: 0.3,
                pullbackPolygonDrag: 0.1,
                pullbackPolygonDuration: 0.8,
                pullbackLabelDelay: 0,
                pullbackLabelDrag: 0.1,
                pullbackLabelDuration: 0.3,
                pullbackChildGroupsDelay: 0.1,
                pullbackChildGroupsDrag: 0.1,
                pullbackChildGroupsDuration: 0.3,
                fadeDuration: 700,
                fadeEasing: 'cubicInOut',
                zoomMouseWheelFactor: 1.5,
                zoomMouseWheelDuration: 500,
                zoomMouseWheelEasing: 'squareOut',
                maxLabelSizeForTitleBar: 8,
                titleBarFontFamily: null,
                titleBarFontStyle: 'normal',
                titleBarFontWeight: 'normal',
                titleBarFontVariant: 'normal',
                titleBarBackgroundColor: 'rgba(0, 0, 0, 0.5)',
                titleBarTextColor: 'rgba(255, 255, 255, 1)',
                titleBarMinFontSize: 10,
                titleBarMaxFontSize: 40,
                titleBarTextPaddingLeftRight: 20,
                titleBarTextPaddingTopBottom: 15,
                titleBarDecorator: D.sa,
                attributionText: null,
                attributionLogo: null,
                attributionLogoScale: 0.5,
                attributionUrl: null,
                attributionPosition: 'bottom-right',
                attributionDistanceFromCenter: 1,
                attributionWeight: 0.025,
                attributionTheme: 'light',
                interactionHandler: v.ji() ? 'hammerjs' : 'builtin',
                onModelChanging: [],
                onModelChanged: [],
                onRedraw: [],
                onRolloutStart: [],
                onRolloutComplete: [],
                onRelaxationStep: [],
                onViewReset: [],
                onGroupOpenOrCloseChanging: [],
                onGroupOpenOrCloseChanged: [],
                onGroupExposureChanging: [],
                onGroupExposureChanged: [],
                onGroupSelectionChanging: [],
                onGroupSelectionChanged: [],
                onGroupHover: [],
                onGroupMouseMove: [],
                onGroupClick: [],
                onGroupDoubleClick: [],
                onGroupHold: [],
                onGroupMouseWheel: [],
                onGroupMouseUp: [],
                onGroupMouseDown: [],
                onGroupDragStart: [],
                onGroupDrag: [],
                onGroupDragEnd: [],
                onGroupTransformStart: [],
                onGroupTransform: [],
                onGroupTransformEnd: [],
                onKeyUp: [],
                selection: null,
                open: null,
                exposure: null,
                imageData: null,
                hierarchy: null,
                geometry: null,
                containerCoordinates: null,
                state: null,
                viewport: null,
                times: null,
            });
            window.CarrotSearchFoamTree.geometry = Object.freeze(
                (function () {
                    return {
                        rectangleInPolygon: function (e, m, g, d, b, f, k) {
                            b = D.B(b, 1);
                            f = D.B(f, 0.5);
                            k = D.B(k, 0.5);
                            e = M.ue(e, { x: m, y: g }, d, f, k) * b;
                            return {
                                x: m - e * d * f,
                                y: g - e * k,
                                w: e * d,
                                h: e,
                            };
                        },
                        circleInPolygon: function (e, m, g) {
                            return M.Fg(e, { x: m, y: g });
                        },
                        stabPolygon: function (e, m, g, d) {
                            return M.Yb(e, { x: m, y: g }, d);
                        },
                        polygonCentroid: function (e) {
                            e = M.k(e, {});
                            return { x: e.x, y: e.y, area: e.ja };
                        },
                        boundingBox: function (e) {
                            for (
                                var m = e[0].x,
                                    g = e[0].y,
                                    d = e[0].x,
                                    b = e[0].y,
                                    f = 1;
                                f < e.length;
                                f++
                            ) {
                                var k = e[f];
                                k.x < m && (m = k.x);
                                k.y < g && (g = k.y);
                                k.x > d && (d = k.x);
                                k.y > b && (b = k.y);
                            }
                            return { x: m, y: g, w: d - m, h: b - g };
                        },
                    };
                })(),
            );
        },
        function () {
            window.CarrotSearchFoamTree = function () {
                window.console.error(
                    'FoamTree is not supported on this browser.',
                );
            };
            window.CarrotSearchFoamTree.Vj = !1;
        },
    );
})();
